<!-- eslint-disable import/no-unresolved -->
<!-- eslint-disable import/no-unresolved -->
<template>
    <main
        class="pt-2 rounded-md darky"
        :class="!showNoteForm ? 'py-4 h-full' : 'h-screen '"
    >
        <div
            :class="
                !showNoteForm
                    ? 'grid grid-cols-8 gap-1 w-full max-w-full'
                    : 'flex justify-end'
            "
        >
            <div
                v-if="!showNoteForm"
                :class="canAccessNotes ? 'col-span-6' : 'col-span-8'"
            >
                <back-to @back="back()" />
                <div id="create-client" class="xs:p-0 mx-auto md:w-full">
                    <div class="">
                        <form
                            @keydown.enter.prevent
                            @submit.prevent="
                                $route.params.id
                                    ? updateClient()
                                    : createClient()
                            "
                        >
                            <div>
                                <div class="rounded-md p-3">
                                    <div
                                        :key="refresh"
                                        class="grid grid-cols-6 gap-4"
                                    >
                                        <div
                                            class="col-span-3 rounded-md border-2 p-4"
                                        >
                                            <div class="px-3">
                                                <div class="mb-2">
                                                    <span
                                                        class="text-3xl flex items-start mb-5"
                                                    >
                                                        <span class="text-lg">
                                                            {{
                                                                $t(
                                                                    'client_information',
                                                                )
                                                            }}
                                                            <hr />
                                                        </span>
                                                    </span>
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="name"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t('name') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        id="name"
                                                        v-model="formData.Name"
                                                        @blur="
                                                            v$.formData.Name
                                                                .$touch;
                                                        "
                                                        autocomplete="organization"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.formData
                                                        .Name.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="idexternal"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t('reference') +
                                                            ' ' +
                                                            $t('client')
                                                        }}</label
                                                    >
                                                    <input
                                                        autocomplete="off"
                                                        id="idexternal"
                                                        type="text"
                                                        v-model="
                                                            formData.IdExternal
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                    <!-- @blur="v$.formData.Reference.$touch" -->
                                                </div>

                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="autocomplete"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >{{ $t('address') }} 1 *
                                                    </label>
                                                    <input
                                                        v-model="
                                                            formData.Address1
                                                        "
                                                        id="autocomplete"
                                                        @blur="
                                                            v$.formData.Address1.$touch()
                                                        "
                                                        autocomplete="address-level1"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.formData
                                                        .Address1.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="mainaddress2"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t('address')
                                                        }}
                                                        2</label
                                                    >
                                                    <input
                                                        id="mainaddress2"
                                                        autocomplete="address-level2"
                                                        type="text"
                                                        v-model="
                                                            formData.Address2
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="postal_code"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t('postal_code') +
                                                            ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        id="postal_code"
                                                        autocomplete="postal-code"
                                                        v-model="
                                                            formData.PostalCode
                                                        "
                                                        @blur="
                                                            v$.formData.PostalCode.$touch()
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.formData
                                                        .PostalCode.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="locality"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t('city') + ' *'
                                                        }}</label
                                                    >
                                                    <input
                                                        type="text"
                                                        v-model="formData.City"
                                                        id="locality"
                                                        autocomplete="off"
                                                        @blur="
                                                            v$.formData.City
                                                                .$touch;
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.formData
                                                        .City.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <div
                                                        for="clienttype"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >
                                                        Type
                                                    </div>
                                                    <Multiselect
                                                        id="clienttype"
                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                        v-model="formData.Type"
                                                        :options="typeOptions"
                                                        :searchable="false"
                                                        :allow-empty="false"
                                                        :can-deselect="false"
                                                        :can-clear="false"
                                                        label="name"
                                                        track-by="name"
                                                    ></Multiselect>
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="rcs"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >RCS</label
                                                    >
                                                    <input
                                                        id="rcs"
                                                        type="text"
                                                        autocomplete="off"
                                                        v-model="formData.RCS"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="vat"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >TVA</label
                                                    >
                                                    <input
                                                        id="vat"
                                                        type="text"
                                                        aautocomplete="off"
                                                        v-model="
                                                            formData.VATIntra
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="siret"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >Siret</label
                                                    >

                                                    <input
                                                        id="siret"
                                                        type="text"
                                                        autocomplete="off"
                                                        v-model="formData.Siret"
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <!-- <div
                                                class="mb-3 items-center flex flex-row"
                                            >
                                                <label
                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >{{ $t('billing') }}
                                                </label>
                                                <Multiselect
                                                    class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                    v-model="
                                                        formData.ClientType
                                                    "
                                                    :options="clientTypeOptions"
                                                    :searchable="false"
                                                    :allow-empty="false"
                                                    :can-deselect="false"
                                                    :can-clear="false"
                                                    label="name"
                                                    track-by="name"
                                                ></Multiselect>
                                            </div> -->
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <label
                                                        for="mainemail"
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                        >{{
                                                            $t(
                                                                'email_address',
                                                            ) +
                                                            (createUser === true
                                                                ? ' *'
                                                                : '')
                                                        }}
                                                    </label>
                                                    <input
                                                        id="mainemail"
                                                        type="email"
                                                        autocomplete="email"
                                                        @focus="
                                                            userAlreadyExists = false
                                                        "
                                                        @blur="
                                                            v$.formData.Email.$touch() &&
                                                            contactForm.Email ===
                                                                ''
                                                                ? (contactForm.Email =
                                                                      formData.Email)
                                                                : ''
                                                        "
                                                        v-model="formData.Email"
                                                        :class="
                                                            userAlreadyExists
                                                                ? 'border-red-500 text-red-500'
                                                                : ''
                                                        "
                                                        class="border rounded px-3 py-2 mt-1 w-full"
                                                    />
                                                </div>
                                                <div
                                                    class="text-xs italic mt-1 mb-2"
                                                    v-for="error of v$.formData
                                                        .Email.$errors"
                                                    :key="error.$uid"
                                                >
                                                    <div class="error-msg">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                                <div class="mb-2">
                                                    <span
                                                        class="text-3xl flex items-start mb-5"
                                                    >
                                                        <span class="text-lg">
                                                            {{
                                                                $t('contracts')
                                                            }}
                                                            <hr />
                                                        </span>
                                                    </span>
                                                </div>
                                                <div
                                                    class="flex items-center justify-center"
                                                >
                                                    <button
                                                        v-if="
                                                            currentClient &&
                                                            currentClient
                                                                .contracts
                                                                .length
                                                        "
                                                        type="button"
                                                        @click.prevent="
                                                            showContractList =
                                                                !showContractList
                                                        "
                                                        class="themed-button text-xs ml-2 focus:outline-none flex justify-center items-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                    >
                                                        <fa-icon
                                                            icon="file-signature"
                                                            class="mr-2"
                                                        ></fa-icon>
                                                        {{
                                                            showContractList ===
                                                            false
                                                                ? $t(
                                                                      'see_contracts',
                                                                  )
                                                                : $t(
                                                                      'hide_contracts',
                                                                  )
                                                        }}
                                                    </button>

                                                    <button
                                                        type="button"
                                                        :disabled="creating"
                                                        @click.prevent="
                                                            setContractForm(
                                                                null,
                                                                currentClient
                                                                    ? currentClient.id
                                                                    : null,
                                                            )
                                                        "
                                                        class="themed-button text-xs ml-2 focus:outline-none flex justify-center items-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                    >
                                                        <fa-icon
                                                            icon="plus"
                                                            class="mr-2"
                                                        ></fa-icon>

                                                        {{ $t('add_contract') }}
                                                    </button>
                                                </div>
                                                <span
                                                    v-if="
                                                        showContractList ===
                                                        true
                                                    "
                                                    class="h-32 overflow-y-auto"
                                                >
                                                    <hr
                                                        class="theme-color mt-2"
                                                    />
                                                    <ul
                                                        v-for="(
                                                            contract, ind
                                                        ) in currentClient.contracts"
                                                        :key="ind"
                                                    >
                                                        <li
                                                            class="flex text-xs p-2 justify-start items-center"
                                                        >
                                                            <fa-icon
                                                                icon="file-signature"
                                                                class="mr-2"
                                                            ></fa-icon>
                                                            {{
                                                                `${
                                                                    contract.Name
                                                                } • ${$t(
                                                                    'signature_date',
                                                                )} : ${contract.SignatureDate.substr(
                                                                    0,
                                                                    10,
                                                                )}  `
                                                            }}
                                                            {{
                                                                contract.EndDate !==
                                                                    null &&
                                                                contract.EndDate !==
                                                                    '0000-00-00'
                                                                    ? ` • ${$t(
                                                                          'end_date',
                                                                      )} : ${contract.EndDate.substr(
                                                                          0,
                                                                          10,
                                                                      )}`
                                                                    : ''
                                                            }}
                                                            {{
                                                                contract.Duration !==
                                                                'openEnded'
                                                                    ? ` • ${$t(
                                                                          'duration',
                                                                      )} : ${
                                                                          contract.Duration
                                                                      } ${$tc(
                                                                          'month',
                                                                          +contract.Duration >
                                                                              1
                                                                              ? 2
                                                                              : 1,
                                                                      )}`
                                                                    : ` • ${$t(
                                                                          'duration',
                                                                      )} : ${$t(
                                                                          'open_ended',
                                                                      )}`
                                                            }}
                                                            {{
                                                                ` • ${$t(
                                                                    'auto_renew',
                                                                )} : ${$t(
                                                                    contract.AutomaticRenewal,
                                                                )}`
                                                            }}
                                                            {{
                                                                contract.NoticePeriod !==
                                                                'none'
                                                                    ? ` • ${$t(
                                                                          'notice',
                                                                      )} : ${
                                                                          contract.NoticePeriod
                                                                      } ${$tc(
                                                                          'week',
                                                                          +contract.NoticePeriod >
                                                                              1
                                                                              ? 2
                                                                              : 1,
                                                                      )}`
                                                                    : ` • ${$t(
                                                                          'notice',
                                                                      )} : ${$t(
                                                                          contract.NoticePeriod,
                                                                      )}`
                                                            }}

                                                            <button
                                                                v-if="
                                                                    currentClient
                                                                        .contracts
                                                                        .length
                                                                "
                                                                type="button"
                                                                @click.prevent="
                                                                    setContractForm(
                                                                        contract.Id,
                                                                    )
                                                                "
                                                                class="themed-button text-xs ml-2 focus:outline-none flex justify-center items-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                            >
                                                                <fa-icon
                                                                    icon="pen"
                                                                ></fa-icon>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-span-3 rounded-md border-2 p-4"
                                        >
                                            <div class="px-3">
                                                <div class="mb-3">
                                                    <div class="mb-2">
                                                        <span
                                                            class="text-3xl flex items-start mb-5"
                                                        >
                                                            <span
                                                                class="text-lg"
                                                            >
                                                                {{
                                                                    $tc(
                                                                        'universe',
                                                                        2,
                                                                    )
                                                                }}
                                                                <hr />
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <!-- TODO: add Checkboxes -->
                                                    <Multiselect
                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                        v-model="
                                                            formData.IdUniverse
                                                        "
                                                        :searchable="true"
                                                        :options="
                                                            universeOptions
                                                        "
                                                        :allow-empty="false"
                                                        :can-deselect="false"
                                                        :can-clear="false"
                                                        label="name"
                                                        track-by="name"
                                                    ></Multiselect>
                                                </div>
                                                <div
                                                    class="mb-3"
                                                    :key="refreshMS"
                                                >
                                                    <div class="mb-2">
                                                        <span
                                                            class="text-3xl flex items-start mb-5"
                                                        >
                                                            <span
                                                                class="text-lg"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'default_package',
                                                                    )
                                                                }}
                                                                <hr />
                                                            </span>
                                                        </span>
                                                    </div>

                                                    <Multiselect
                                                        v-if="
                                                            containerCreation ===
                                                            false
                                                        "
                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                        v-model="
                                                            formData.IdDefaultContainer
                                                        "
                                                        :disabled="
                                                            !updateDefaultPackageAccess
                                                        "
                                                        :searchable="true"
                                                        :options="
                                                            containerOptions
                                                        "
                                                        :allow-empty="false"
                                                        :can-deselect="false"
                                                        :can-clear="false"
                                                        label="name"
                                                        track-by="name"
                                                    ></Multiselect>
                                                    <button
                                                        v-if="
                                                            containerOptions.length ===
                                                                0 ||
                                                            (containerCreation ===
                                                                false &&
                                                                updateDefaultPackageAccess)
                                                        "
                                                        @click.stop="
                                                            setContainerCreation
                                                        "
                                                        class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                                    >
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            >{{
                                                                $t(
                                                                    'create_container',
                                                                )
                                                            }}</span
                                                        >
                                                    </button>
                                                    <div
                                                        v-if="
                                                            containerOptions.length ===
                                                                0 ||
                                                            (containerCreation ===
                                                                true &&
                                                                updateDefaultPackageAccess)
                                                        "
                                                    >
                                                        <div
                                                            class="mb-3 items-center flex flex-row"
                                                        >
                                                            <label
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                                >{{
                                                                    $t(
                                                                        'package_name',
                                                                    ) + ' *'
                                                                }}</label
                                                            >
                                                            <input
                                                                v-model="
                                                                    containerForm.Name
                                                                "
                                                                autocomplete="name"
                                                                @blur="
                                                                    v$
                                                                        .containerForm
                                                                        .Name
                                                                        .$touch;
                                                                "
                                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                            />
                                                        </div>
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .containerForm
                                                                .Name.$errors"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="mb-3 items-center flex flex-row"
                                                        >
                                                            <label
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                                >{{
                                                                    $t(
                                                                        'description',
                                                                    )
                                                                }}</label
                                                            >
                                                            <textarea
                                                                v-model="
                                                                    containerForm.Description
                                                                "
                                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                            />
                                                        </div>

                                                        <div
                                                            class="grid grid-cols-4 gap-1 mt-4"
                                                        >
                                                            <div
                                                                class="col-span-1"
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >{{
                                                                        $t(
                                                                            'width',
                                                                        ) + ' *'
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="number"
                                                                    step="0.01"
                                                                    min="0"
                                                                    v-model="
                                                                        containerForm.Width
                                                                    "
                                                                    @blur="
                                                                        v$
                                                                            .containerForm
                                                                            .Width
                                                                            .$touch;
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-28"
                                                                />
                                                                <div
                                                                    class="text-xs italic mt-1 mb-2"
                                                                    v-for="error of v$
                                                                        .containerForm
                                                                        .Width
                                                                        .$errors"
                                                                    :key="
                                                                        error.$uid
                                                                    "
                                                                >
                                                                    <div
                                                                        class="error-msg"
                                                                    >
                                                                        {{
                                                                            error.$message
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-span-1"
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >{{
                                                                        $t(
                                                                            'length',
                                                                        ) + ' *'
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="number"
                                                                    min="0"
                                                                    step="0.01"
                                                                    v-model="
                                                                        containerForm.Length
                                                                    "
                                                                    @blur="
                                                                        v$
                                                                            .containerForm
                                                                            .Length
                                                                            .$touch;
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-28"
                                                                />
                                                                <div
                                                                    class="text-xs italic mt-1 mb-2"
                                                                    v-for="error of v$
                                                                        .containerForm
                                                                        .Length
                                                                        .$errors"
                                                                    :key="
                                                                        error.$uid
                                                                    "
                                                                >
                                                                    <div
                                                                        class="error-msg"
                                                                    >
                                                                        {{
                                                                            error.$message
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-span-1"
                                                            >
                                                                <label
                                                                    class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >{{
                                                                        $t(
                                                                            'height',
                                                                        ) + ' *'
                                                                    }}</label
                                                                >

                                                                <input
                                                                    type="number"
                                                                    min="0"
                                                                    step="0.01"
                                                                    v-model="
                                                                        packageHeight
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-28"
                                                                />
                                                            </div>
                                                            <div
                                                                class="col-span-1 items-center"
                                                            >
                                                                <label
                                                                    class="font-semibold whitespace-nowrap basis-1/4 text-gray-600 pb-1 mr-2"
                                                                    >{{
                                                                        $t(
                                                                            'unit_weight',
                                                                        ) + ' *'
                                                                    }}</label
                                                                >
                                                                <input
                                                                    type="number"
                                                                    min="0"
                                                                    step="0.01"
                                                                    @blur="
                                                                        v$
                                                                            .containerForm
                                                                            .Weight
                                                                            .$touch;
                                                                    "
                                                                    v-model="
                                                                        containerForm.Weight
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-28"
                                                                />
                                                                <div
                                                                    class="text-xs italic mt-1 mb-2"
                                                                    v-for="error of v$
                                                                        .containerForm
                                                                        .Weight
                                                                        .$errors"
                                                                    :key="
                                                                        error.$uid
                                                                    "
                                                                >
                                                                    <div
                                                                        class="error-msg"
                                                                    >
                                                                        {{
                                                                            error.$message
                                                                        }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="flex items-center justify-center my-4"
                                                        >
                                                            <input
                                                                id="container-for-all-checkbox"
                                                                type="checkbox"
                                                                v-model="
                                                                    containerForAll
                                                                "
                                                                class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                            />
                                                            <label
                                                                for="container-for-all-checkbox"
                                                                class="ml-2 text-sm font-medium theme-color"
                                                                >{{
                                                                    $t(
                                                                        'create_container_for_all',
                                                                    )
                                                                }}</label
                                                            >
                                                        </div>
                                                        <div
                                                            class="flex items-center justify-center my-4"
                                                        >
                                                            <input
                                                                id="set-as-default-checkbox"
                                                                type="checkbox"
                                                                v-model="
                                                                    isDefault
                                                                "
                                                                class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                                :class="'cursor-pointer'"
                                                            />
                                                            <label
                                                                for="set-as-default-checkbox"
                                                                class="ml-2 text-sm font-medium theme-color"
                                                                >{{
                                                                    $t(
                                                                        'set_as_default_for_client',
                                                                    )
                                                                }}</label
                                                            >
                                                        </div>
                                                        <button
                                                            v-if="
                                                                containerCreation ===
                                                                    true &&
                                                                updateDefaultPackageAccess
                                                            "
                                                            @click.stop="
                                                                unsetContainerCreation
                                                            "
                                                            class="transition duration-200 mx-5 my-2 px-5 cursor-pointer font-normal text-sm rounded text-gray-500"
                                                        >
                                                            <span
                                                                class="inline-block ml-1 font-bold"
                                                                >{{
                                                                    $t(
                                                                        'back_to_predefined_containers',
                                                                    )
                                                                }}</span
                                                            >
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="mb-2">
                                                    <span
                                                        class="text-3xl flex items-start mb-5"
                                                    >
                                                        <span class="text-lg">
                                                            {{
                                                                $t(
                                                                    'billing_info',
                                                                )
                                                            }}
                                                            <hr />
                                                        </span>
                                                    </span>
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <div
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >
                                                        {{ $t('billing_type') }}
                                                    </div>
                                                    <Multiselect
                                                        id="billingtype"
                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                        v-model="
                                                            formData.ClientType
                                                        "
                                                        :options="
                                                            clientTypeOptions
                                                        "
                                                        :searchable="false"
                                                        :allow-empty="false"
                                                        :can-deselect="false"
                                                        :can-clear="false"
                                                        label="name"
                                                        track-by="name"
                                                    ></Multiselect>
                                                </div>
                                                <div
                                                    class="mb-3 items-center flex flex-row"
                                                >
                                                    <div
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                    >
                                                        {{ $t('payment_type') }}
                                                    </div>
                                                    <Multiselect
                                                        id="payment"
                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                        v-model="
                                                            formData.PaymentType
                                                        "
                                                        :options="
                                                            paymentTypeOptions
                                                        "
                                                        :searchable="false"
                                                        :allow-empty="false"
                                                        :can-deselect="false"
                                                        :can-clear="false"
                                                        label="name"
                                                        track-by="name"
                                                    ></Multiselect>
                                                </div>

                                                <div
                                                    class="flex my-2 items-center justify-center"
                                                >
                                                    <input
                                                        id="vat-checkbox"
                                                        type="checkbox"
                                                        v-model="
                                                            formData.WithVAT
                                                        "
                                                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                    />
                                                    <label
                                                        for="vat-checkbox"
                                                        class="ml-2 font-medium text-base"
                                                        >{{
                                                            $t('with_vat')
                                                        }}</label
                                                    >
                                                </div>
                                                <div
                                                    v-if="
                                                        formData.PaymentType ===
                                                        'web'
                                                    "
                                                    class="text-xs italic mt-1 mb-2"
                                                >
                                                    {{ $t('web_payment_info') }}
                                                </div>
                                                <div class="mt-2">
                                                    <span
                                                        class="flex items-start mb-5"
                                                    >
                                                        <span class="">
                                                            {{
                                                                $t(
                                                                    'billing_address',
                                                                )
                                                            }}
                                                            <hr />
                                                        </span>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="
                                                        $route.name ===
                                                            'new_client' ||
                                                        ($route.name ===
                                                            'update_client' &&
                                                            currentClient &&
                                                            !currentClient.id_address_facturation)
                                                    "
                                                    class="flex my-2 items-center justify-center"
                                                >
                                                    <input
                                                        id="billing-same-as-checkbox"
                                                        type="checkbox"
                                                        v-model="
                                                            billingSameAsClient
                                                        "
                                                        @change="
                                                            setBillingAddress()
                                                        "
                                                        class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                    />
                                                    <label
                                                        for="billing-same-as-checkbox"
                                                        class="ml-2 font-medium text-base"
                                                        >{{
                                                            $t(
                                                                'billing_addr_same_as_client',
                                                            )
                                                        }}</label
                                                    >
                                                </div>
                                                <div class="px-3 mt-5">
                                                    <div
                                                        v-if="
                                                            $route.params.id &&
                                                            billingAddress.Id
                                                        "
                                                        class="mb-3 mt-3items-center flex flex-row"
                                                    >
                                                        <Multiselect
                                                            class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                            v-model="
                                                                billingAddress.Id
                                                            "
                                                            :key="refreshMS"
                                                            :options="
                                                                addressOptions
                                                            "
                                                            :loading="
                                                                addressOptions.length ===
                                                                    0 &&
                                                                noContactForClient ===
                                                                    false
                                                            "
                                                            label="name"
                                                            track-by="name"
                                                            :searchable="true"
                                                            :placeholder="
                                                                $t(
                                                                    'can_select_address',
                                                                )
                                                            "
                                                            :allow-empty="false"
                                                            :can-clear="false"
                                                            @select="
                                                                setBillingAddress(
                                                                    billingAddress.Id,
                                                                )
                                                            "
                                                        ></Multiselect>
                                                    </div>
                                                </div>
                                                <span
                                                    v-if="
                                                        billingSameAsClient ===
                                                            false ||
                                                        $route.name !==
                                                            'new_client'
                                                    "
                                                >
                                                    <!-- @blur="
                                                v$.billingAddress
                                                    .AddressName.$touch()
                                            "
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$
                                                    .billingAddress.AddressName
                                                    .$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div> -->
                                                    <span
                                                        v-if="billingAddress.Id"
                                                        :key="refreshMS"
                                                    >
                                                        <p
                                                            class="text-sm flex mb-2 justify-center font-normal"
                                                        >
                                                            {{
                                                                `${
                                                                    billingAddress.Address1
                                                                } ${
                                                                    billingAddress.Address2 !==
                                                                        'null' &&
                                                                    billingAddress.Address2 !==
                                                                        null &&
                                                                    billingAddress.Address2 !==
                                                                        ''
                                                                        ? billingAddress.Address2
                                                                        : ''
                                                                } 
                                                            ${
                                                                billingAddress.PostalCode
                                                            } ${
                                                                billingAddress.City
                                                            } `
                                                            }}
                                                        </p>
                                                        <span
                                                            class="flex items-center justify-center"
                                                        >
                                                            <button
                                                                v-if="
                                                                    canAccess(
                                                                        'updateAddress',
                                                                    )
                                                                "
                                                                type="button"
                                                                @click="
                                                                    $emit(
                                                                        'goToUpdateAddress',
                                                                        billingAddress.Id,
                                                                    )
                                                                "
                                                                class="themed-button text-xs focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                            >
                                                                <span
                                                                    class="inline-block ml-1 font-bold"
                                                                    ><fa-icon
                                                                        icon="pen"
                                                                        class="mr-2"
                                                                    ></fa-icon
                                                                    >{{
                                                                        $t(
                                                                            'address_update',
                                                                        )
                                                                    }}</span
                                                                >
                                                            </button>
                                                            <button
                                                                v-if="
                                                                    canAccess(
                                                                        'createAddress',
                                                                    )
                                                                "
                                                                type="button"
                                                                @click="
                                                                    setBillingAddress(
                                                                        null,
                                                                        true,
                                                                    )
                                                                "
                                                                class="themed-button text-xs ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                            >
                                                                <span
                                                                    class="inline-block ml-1 font-bold"
                                                                    ><fa-icon
                                                                        icon="plus"
                                                                        class="mr-2"
                                                                    ></fa-icon
                                                                    >{{
                                                                        $t(
                                                                            'new_address_generic',
                                                                        )
                                                                    }}</span
                                                                >
                                                            </button></span
                                                        >
                                                    </span>
                                                    <span
                                                        v-else-if="
                                                            !billingAddress.Id &&
                                                            !billingSameAsClient
                                                        "
                                                    >
                                                        <div
                                                            class="mb-3 items-center flex flex-row"
                                                        >
                                                            <label
                                                                for="autocomplete-billing"
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                                >{{
                                                                    $t(
                                                                        'address',
                                                                    )
                                                                }}
                                                                1 *
                                                            </label>
                                                            <input
                                                                v-model="
                                                                    billingAddress.Address1
                                                                "
                                                                @blur="
                                                                    v$.billingAddress.Address1.$touch()
                                                                "
                                                                autocomplete="address-level1"
                                                                id="autocomplete-billing"
                                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                            />
                                                        </div>

                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .billingAddress
                                                                .Address1
                                                                .$errors"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="mb-3 items-center flex flex-row"
                                                        >
                                                            <label
                                                                for="billingaddress2"
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                                >{{
                                                                    $t(
                                                                        'address',
                                                                    )
                                                                }}
                                                                2</label
                                                            >
                                                            <input
                                                                autocomplete="address-level2"
                                                                id="billingaddress2"
                                                                type="text"
                                                                v-model="
                                                                    billingAddress.Address2
                                                                "
                                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                            />
                                                        </div>
                                                        <div
                                                            class="mb-3 items-center flex flex-row"
                                                        >
                                                            <label
                                                                for="billingpostal_code"
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                                >{{
                                                                    $t(
                                                                        'postal_code',
                                                                    ) + ' *'
                                                                }}</label
                                                            >
                                                            <input
                                                                id="billingpostal_code"
                                                                autocomplete="postal-code"
                                                                v-model="
                                                                    billingAddress.PostalCode
                                                                "
                                                                @blur="
                                                                    v$.billingAddress.PostalCode.$touch()
                                                                "
                                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                            />
                                                        </div>

                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .billingAddress
                                                                .PostalCode
                                                                .$errors"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="mb-3 items-center flex flex-row"
                                                        >
                                                            <label
                                                                for="billinglocality"
                                                                class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2 block"
                                                                >{{
                                                                    $t('city') +
                                                                    ' *'
                                                                }}</label
                                                            >

                                                            <input
                                                                autocomplete="off"
                                                                id="billinglocality"
                                                                type="text"
                                                                v-model="
                                                                    billingAddress.City
                                                                "
                                                                class="border rounded px-3 py-2 mt-1 w-full"
                                                            />
                                                        </div>
                                                        <span
                                                            class="flex items-center justify-center"
                                                        >
                                                            <button
                                                                type="button"
                                                                v-if="
                                                                    $route.name !==
                                                                        'new_client' &&
                                                                    billingAddress.Id
                                                                "
                                                                @click="
                                                                    setBillingAddress(
                                                                        currentClient
                                                                            .billing_address
                                                                            .Id,
                                                                    )
                                                                "
                                                                class="themed-button text-xs focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                            >
                                                                <span
                                                                    class="inline-block ml-1 font-bold"
                                                                    ><fa-icon
                                                                        icon="undo"
                                                                        class="mr-2"
                                                                    ></fa-icon
                                                                    >{{
                                                                        $t(
                                                                            'back_to_predefined_addresses',
                                                                        )
                                                                    }}</span
                                                                >
                                                            </button>
                                                        </span>
                                                    </span>
                                                </span>
                                                <div class="mt-2">
                                                    <span
                                                        class="flex items-start mb-5"
                                                    >
                                                        <span class="">
                                                            {{
                                                                $t(
                                                                    'billing_contact',
                                                                )
                                                            }}
                                                            <hr />
                                                        </span>
                                                    </span>
                                                </div>

                                                <div
                                                    v-if="
                                                        contacts !== null &&
                                                        ((currentClient &&
                                                            currentClient.id_user_facturation) ||
                                                            contactForm.Id)
                                                    "
                                                    class="mb-3 items-center px-3"
                                                >
                                                    <div
                                                        class="font-semibold basis-1/4 text-gray-600 pb-1 mr-2"
                                                    >
                                                        {{ $t('contact') }}
                                                    </div>
                                                    <Multiselect
                                                        id="contact"
                                                        class="h-10 rounded px-3 py-2 mt-1 w-full darky"
                                                        v-model="contactForm.Id"
                                                        :options="
                                                            contactOptions
                                                        "
                                                        :loading="
                                                            contactOptions.length ===
                                                            0
                                                        "
                                                        label="name"
                                                        :key="refreshMS"
                                                        track-by="name"
                                                        :searchable="true"
                                                        :placeholder="
                                                            $t(
                                                                'select_contact_info',
                                                            )
                                                        "
                                                        :allow-empty="false"
                                                        :can-clear="true"
                                                        @select="
                                                            setBillingContact(
                                                                contactForm.Id,
                                                            )
                                                        "
                                                    ></Multiselect>
                                                    <!-- <div
                                        class="text-xs italic mt-1 mb-2"
                                        v-for="error of v$.formData
                                            .IdUserContact.$errors"
                                        :key="error.$uid"
                                    >
                                        <div class="error-msg">
                                            {{ error.$message }}
                                        </div>
                                    </div> -->
                                                </div>
                                                <div
                                                    v-if="contactForm.Id"
                                                    class="px-3"
                                                >
                                                    <ul>
                                                        <li class="mb-2">
                                                            {{
                                                                ` ${contactForm.Email}`
                                                            }}
                                                        </li>
                                                        <li
                                                            v-if="
                                                                contactForm.Phone
                                                            "
                                                            class="mb-2"
                                                        >
                                                            {{
                                                                ` ${contactForm.Phone}`
                                                            }}
                                                        </li>
                                                        <li
                                                            v-if="
                                                                contactForm.MobilePhone
                                                            "
                                                            class="mb-2"
                                                        >
                                                            {{
                                                                ` ${contactForm.MobilePhone}`
                                                            }}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div
                                                    v-if="
                                                        noContactForClient ===
                                                        true
                                                    "
                                                    class="rounded-md border-1 p-4"
                                                >
                                                    {{
                                                        $t(
                                                            'no_contact_found_for_client',
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    v-if="!contactForm.Id"
                                                    :key="refresh"
                                                >
                                                    <div
                                                        class="grid grid-cols-6 gap-2"
                                                    >
                                                        <div class="col-span-3">
                                                            <div
                                                                class="mb-3 items-center"
                                                            >
                                                                <label
                                                                    for="contactfirstname"
                                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'firstname',
                                                                        ) + ' *'
                                                                    }}</label
                                                                >
                                                                <input
                                                                    id="contactfirstname"
                                                                    v-model="
                                                                        contactForm.FirstName
                                                                    "
                                                                    @blur="
                                                                        v$.contactForm.FirstName.$touch()
                                                                    "
                                                                    autocomplete="name"
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .contactForm
                                                                    .FirstName
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-3">
                                                            <div
                                                                class="mb-3 items-center"
                                                            >
                                                                <label
                                                                    for="contactlastname"
                                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'lastname',
                                                                        ) + ' *'
                                                                    }}
                                                                </label>
                                                                <input
                                                                    id="contactlastname"
                                                                    autocomplete="name"
                                                                    v-model="
                                                                        contactForm.LastName
                                                                    "
                                                                    @blur="
                                                                        v$.contactForm.LastName.$touch()
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .contactForm
                                                                    .LastName
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="grid grid-cols-6 gap-2"
                                                    >
                                                        <div class="col-span-3">
                                                            <div
                                                                class="mb-3 items-center"
                                                            >
                                                                <label
                                                                    for="contactphone"
                                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'phone',
                                                                        )
                                                                    }}</label
                                                                >
                                                                <input
                                                                    id="contactphone"
                                                                    v-model="
                                                                        contactForm.Phone
                                                                    "
                                                                    @blur="
                                                                        v$.contactForm.Phone.$touch()
                                                                    "
                                                                    autocomplete="tel"
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .contactForm
                                                                    .Phone
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-3">
                                                            <div
                                                                class="mb-3 items-center"
                                                            >
                                                                <label
                                                                    for="contactmobilephone"
                                                                    class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                                    >{{
                                                                        $t(
                                                                            'mobile',
                                                                        )
                                                                    }}
                                                                </label>
                                                                <input
                                                                    id="contactmobilephone"
                                                                    autocomplete="tel"
                                                                    v-model="
                                                                        contactForm.MobilePhone
                                                                    "
                                                                    @blur="
                                                                        v$.contactForm.MobilePhone.$touch()
                                                                    "
                                                                    class="border rounded px-3 py-2 mt-1 w-full"
                                                                />
                                                            </div>
                                                            <div
                                                                class="text-xs italic mt-1 mb-2"
                                                                v-for="error of v$
                                                                    .contactForm
                                                                    .MobilePhone
                                                                    .$errors"
                                                                :key="
                                                                    error.$uid
                                                                "
                                                            >
                                                                <div
                                                                    class="error-msg"
                                                                >
                                                                    {{
                                                                        error.$message
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            $route.name ===
                                                                'new_client' ||
                                                            ($route.name ===
                                                                'update_client' &&
                                                                !contactForm.Id)
                                                        "
                                                        class="flex my-2 items-center justify-center"
                                                    >
                                                        <input
                                                            id="contact-same-as-checkbox"
                                                            type="checkbox"
                                                            v-model="
                                                                contactSameAsClient
                                                            "
                                                            @change="
                                                                setBillingContact()
                                                            "
                                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                                        />
                                                        <label
                                                            for="contact-same-as-checkbox"
                                                            class="ml-2 font-medium text-base"
                                                            >{{
                                                                $t(
                                                                    'email_same_as_client',
                                                                )
                                                            }}</label
                                                        >
                                                    </div>
                                                    <div
                                                        v-if="
                                                            createNewContact ===
                                                                true ||
                                                            contactSameAsClient ===
                                                                false
                                                        "
                                                        class="mb-3 items-center"
                                                    >
                                                        <label
                                                            for="contactEmail"
                                                            class="font-semibold basis-1/4 text-gray-600 text-sm pb-1 mr-2 block"
                                                            >{{
                                                                $t(
                                                                    'email_address',
                                                                ) + ' *'
                                                            }}
                                                        </label>
                                                        <input
                                                            id="contactEmail"
                                                            autocomplete="email"
                                                            v-model="
                                                                contactForm.Email
                                                            "
                                                            @blur="
                                                                v$.contactForm.Email.$touch()
                                                            "
                                                            class="border rounded px-3 py-2 mt-1 w-full"
                                                        />
                                                        <div
                                                            class="text-xs italic mt-1 mb-2"
                                                            v-for="error of v$
                                                                .contactForm
                                                                .Email.$errors"
                                                            :key="error.$uid"
                                                        >
                                                            <div
                                                                class="error-msg"
                                                            >
                                                                {{
                                                                    error.$message
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="mb-3 items-center"
                                                    >
                                                        <div class="px-3">
                                                            <ul>
                                                                <li
                                                                    class="mb-2"
                                                                >
                                                                    {{
                                                                        ` ${formData.Email}`
                                                                    }}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span
                                                    v-if="
                                                        createNewContact &&
                                                        $route.name !==
                                                            'new_client'
                                                    "
                                                    class="flex items-center justify-center"
                                                >
                                                    <button
                                                        type="button"
                                                        v-if="
                                                            $route.name !==
                                                                'new_client' &&
                                                            currentClient &&
                                                            currentClient.id_user_facturation
                                                        "
                                                        @click="
                                                            setBillingContact(
                                                                contactForm.Id,
                                                            )
                                                        "
                                                        class="themed-button text-xs focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                    >
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            ><fa-icon
                                                                icon="undo"
                                                                class="mr-2"
                                                            ></fa-icon
                                                            >{{
                                                                $t(
                                                                    'back_to_predefined_contacts',
                                                                )
                                                            }}</span
                                                        >
                                                    </button>
                                                </span>
                                                <span
                                                    v-else-if="
                                                        $route.name !==
                                                            'new_client' &&
                                                        createNewContact ===
                                                            false
                                                    "
                                                    class="flex items-center justify-center"
                                                >
                                                    <button
                                                        v-if="
                                                            canAccess(
                                                                'updateContact',
                                                            ) && contactForm.Id
                                                        "
                                                        type="button"
                                                        @click="
                                                            $emit(
                                                                'updateContact',
                                                                {
                                                                    contact:
                                                                        contactForm.Id,
                                                                    client: currentClient.id,
                                                                },
                                                            )
                                                        "
                                                        class="themed-button text-xs focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                    >
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            ><fa-icon
                                                                icon="pen"
                                                                class="mr-2"
                                                            ></fa-icon
                                                            >{{
                                                                $t(
                                                                    'contact_update',
                                                                )
                                                            }}</span
                                                        >
                                                    </button>
                                                    <button
                                                        v-if="
                                                            canAccess(
                                                                'createContact',
                                                            ) &&
                                                            currentClient &&
                                                            currentClient.id_user_facturation
                                                        "
                                                        type="button"
                                                        @click="
                                                            setBillingContact(
                                                                null,
                                                                true,
                                                            )
                                                        "
                                                        class="themed-button ml-2 text-xs focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                                    >
                                                        <span
                                                            class="inline-block ml-1 font-bold"
                                                            ><fa-icon
                                                                icon="plus"
                                                                class="mr-2"
                                                            ></fa-icon
                                                            >{{
                                                                $t(
                                                                    'new_contact',
                                                                )
                                                            }}</span
                                                        >
                                                    </button></span
                                                >
                                                <!-- @blur="
                                                v$.billingAddress.City
                                                    .$touch()
                                            "
                                            <div
                                                class="text-xs italic mt-1 mb-2"
                                                v-for="error of v$
                                                    .billingAddress.City
                                                    .$errors"
                                                :key="error.$uid"
                                            >
                                                <div class="error-msg">
                                                    {{ error.$message }}
                                                </div>
                                            </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        class="flex mt-1 italic text-xs items-center justify-center"
                                    >
                                        {{ $t('required_fields') }}
                                    </span>
                                </div>
                                <div class="p-1">
                                    <!-- <span v-if="!$route.params.id">
                                    <div
                                        class="flex items-center justify-center"
                                    >
                                        <input
                                            id="create-user-checkbox"
                                            type="checkbox"
                                            v-model="createUser"
                                            class="themed-checkbox w-4 h-4 bg-gray-100 rounded border-gray-300"
                                        />
                                        <label
                                            for="create-user-checkbox"
                                            class="ml-2 font-medium text-sm"
                                            >{{
                                                $t('create_user_account')
                                            }}</label
                                        >
                                    </div>
                                    <div class="text-xs mb-4 mt-2 italic">
                                        {{ $t('create_user_rp_info') }}
                                    </div>
                                </span> -->
                                    <div class="flex justify-center">
                                        <button
                                            type="button"
                                            @click="back()"
                                            class="text-base focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-gray-500 bg-gray-400 text-gray-700 duration-200 ease-in-out transition"
                                        >
                                            {{ $t('cancel') }}
                                        </button>
                                        <button
                                            type="submit"
                                            :disabled="creating"
                                            class="themed-button text-base ml-2 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer text-white duration-200 ease-in-out transition"
                                        >
                                            <span v-if="creating">
                                                <fa-icon
                                                    icon="spinner"
                                                    spin-pulse
                                                ></fa-icon
                                            ></span>
                                            <span v-else>
                                                {{ $t('save') }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div v-else class="mt-10">
                <new-Note
                    :key="refresh"
                    :disableForm="disableForm"
                    @closeNoteForm="openNoteForm(null, true)"
                    @newFileUploaded="refreshNoteFiles($event)"
                ></new-Note>
            </div>
            <div
                v-if="canAccessNotes"
                class="col-span-2 mt-10 flex justify-end"
            >
                <side-container-note
                    :key="refresh"
                    @saveClient="createClient(true)"
                    @openNoteForm="openNoteForm($event)"
                ></side-container-note>
            </div>

            <contract-modal
                v-if="showContractModal === true"
                :clientOptions="clientOptions"
                :formData="contractFormData"
                :update="isUpdate"
                @resetContractForm="resetContractForm()"
            ></contract-modal>
        </div>
    </main>
</template>
<script>
import BackTo from '@/components/elements/BackTo.vue'
import googleScriptLoader from '@/mixins/googleScript'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useUniverseStore } from '@/stores/universeStore'
import { useUserStore } from '@/stores/userStore'
import Multiselect from '@vueform/multiselect'
import useVuelidate from '@vuelidate/core'
import {
    email,
    helpers,
    minValue,
    required,
    requiredIf,
} from '@vuelidate/validators'
import { mapActions, mapState, mapStores } from 'pinia'

import ContractModal from '@/components/elements/ContractModal.vue'
import { useContactClientHistoryStore } from '@/stores/contactClientHistoryStore'
import { useContractStore } from '@/stores/contractStore'
import NewNote from './generated/CreateOrUpdateNote.vue'
import SideContainerNote from './generated/SideContainerNote.vue'

const isZipCode = helpers.regex(
    /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B)) *([0-9]{3})?$/,
)

const isPhoneNumber = helpers.regex(
    /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
)

export default {
    name: 'CreateOrUpdate',
    components: {
        Multiselect,
        BackTo,
        SideContainerNote,
        NewNote,
        ContractModal,
    },
    mixins: [googleScriptLoader],

    setup() {
        return { v$: useVuelidate() }
    },
    mounted() {
        this.containerOptions = []

        window.scrollTo(0, 0)
        if (
            this.userStore.current.type === 'admin' ||
            this.userStore.current.type === 'superadmin'
        ) {
            const universeSearch = {
                sortDirection: 'ASC',
                sortField: 'Name',
                perPage: 10000,
                page: 1,
                query: [],
                filter: [],
            }
            this.searchUniverses(universeSearch).then((res) => {
                this.universes.forEach((universe) => {
                    this.universeOptions.push({
                        name: universe.Name,
                        value: universe.Id,
                        realName: universe.Name,
                    })
                })
                this.formData.IdUniverse = this.universeOptions.find(
                    (universe) => universe.realName === 'Courses',
                ).value
            })
        } else {
            this.userStore.userUniverses.forEach((universe) => {
                this.universeOptions.push({
                    name: universe.name,
                    value: universe.value,
                    dbField: 'IdUniverse',
                    realName: universe.name,
                })
            })
            this.formData.IdUniverse = this.universeOptions.find(
                (universe) => universe.realName === 'Courses',
            ).value
        }
        if (this.$route.params.id) {
            // LE CURRENT CLIENT N4EST PLUS La?
            this.currentClientInterval = setInterval(() => {
                if (this.currentClient) {
                    clearInterval(this.currentClientInterval)
                    // ! default package not editable for operator for now
                    this.clientStore
                        .fetchContainers(this.currentClient.id)
                        .then(() => {
                            this.clientStore.containers.forEach((container) => {
                                this.containerOptions.push({
                                    realName: container.Name,
                                    name:
                                        container.Name.includes('_') ||
                                        container.Name === 'pallet' ||
                                        container.Name === 'envelope'
                                            ? this.$t(container.Name)
                                            : container.Name,
                                    value: +container.Id,
                                })
                            })
                        })
                    this.formData = {
                        Name: this.currentClient.company_name,
                        Email: this.currentClient.email,
                        ClientType: this.currentClient.client_type,
                        VATIntra: this.currentClient.vat_intra,
                        RCS: this.currentClient.rcs,
                        Address1: this.currentClient.address,
                        Address2: this.currentClient.address2,
                        Address3: this.currentClient.address3,
                        Country: this.currentClient.country,
                        PostalCode: this.currentClient.postal_code,
                        City: this.currentClient.city,
                        // FirstName: this.currentClient.firstname,
                        // LastName: this.currentClient.lastname,

                        // Phone: this.currentClient,
                        Type: this.currentClient.type,
                        Siret: this.currentClient.registration_number,
                        PaymentType: this.currentClient.payment_type,
                        IdExternal: this.currentClient.id_external,
                        IdUserFacturation:
                            this.currentClient.id_user_facturation,
                        IdAddressFacturation:
                            this.currentClient.id_address_facturation,
                        VoucherPrice: this.currentClient.voucher_price,
                        VoucherLimit: this.currentClient.voucher_limit,
                        Informations: this.currentClient.informations,
                        CreationUserId: this.currentClient.creation_user_id,
                        CreationDate: this.currentClient.creation_date,
                        IdDefaultContainer:
                            this.currentClient.default_container > 0
                                ? this.currentClient.default_container
                                : this.userStore.current.env.defaultContainer,
                    }
                    if (
                        this.currentClient.universeId !== null &&
                        this.currentClient.universeId !== 0 &&
                        this.currentClient.universeId !== '0'
                    ) {
                        this.formData.IdUniverse = this.currentClient.universeId
                    } else {
                        this.formData.IdUniverse = this.universeOptions.find(
                            (universe) => universe.realName === 'Courses',
                        ).value
                    }
                    this.refreshMS += 1
                    if (this.currentClient.billing_address) {
                        this.billingAddress = {
                            IdClient: this.currentClient.id,
                            Address1:
                                this.currentClient.billing_address.Address1,
                            Address2:
                                this.currentClient.billing_address.Address2,
                            Address3:
                                this.currentClient.billing_address.Address3,
                            Address4:
                                this.currentClient.billing_address.Address4,

                            Country: this.currentClient.billing_address.Country,
                            PostalCode:
                                this.currentClient.billing_address.PostalCode,
                            City: this.currentClient.billing_address.City,
                            AddressType:
                                this.currentClient.billing_address.AddressType,
                            Name: this.currentClient.billing_address.Name,
                            Code: this.currentClient.billing_address.Code,
                            AdditionalInformation:
                                this.currentClient.billing_address
                                    .AdditionalInformation,
                            DriverInformation:
                                this.currentClient.billing_address
                                    .DriverInformation,
                            Latitude:
                                this.currentClient.billing_address.Latitude,
                            Longitude:
                                this.currentClient.billing_address.Longitude,
                            ContactName:
                                this.currentClient.billing_address.ContactName,
                            ContactEmail:
                                this.currentClient.billing_address.ContactEmail,
                            ContactPhone:
                                this.currentClient.billing_address.ContactPhone,
                            ContactNameImported:
                                this.currentClient.billing_address
                                    .ContactNameImported,
                            ContactPhoneImported:
                                this.currentClient.billing_address
                                    .ContactPhoneImported,
                            WaitingTime:
                                this.currentClient.billing_address.WaitingTime,
                            Id: this.currentClient.billing_address.Id,
                            CreationUserId:
                                this.currentClient.billing_address
                                    .CreationUserId,
                            IdExternal:
                                this.currentClient.billing_address.IdExternal,
                            CreationDate:
                                this.currentClient.billing_address.CreationDate,
                            ModificationUserId: this.userStore.current.id,
                        }
                    } else {
                        // TODO faire comme pour les contacts si pas d'adresse de facturation alors on recherche l'adresse client pour la setter
                        this.clientStore
                            .fetchOneAddressByIdClientAndNameAndAddress1AndPostalCode(
                                {
                                    idClient: this.$route.params.id,
                                    name: 'Principale',
                                    zip: this.currentClient.postal_code,
                                    address: this.currentClient.address,
                                },
                            )
                            .then((res) => {
                                if (res && res.status === 200) {
                                    this.billingAddress = {
                                        Id: this.currentBillingAddress.id,
                                        AddressName:
                                            this.currentBillingAddress.name,
                                        Address1:
                                            this.currentBillingAddress.address,
                                        Address2:
                                            this.currentBillingAddress.address2,
                                        Address3:
                                            this.currentBillingAddress.address3,

                                        Address4:
                                            this.currentBillingAddress.address4,
                                        Country:
                                            this.currentBillingAddress.Country,
                                        PostalCode:
                                            this.currentBillingAddress
                                                .postal_code,
                                        City: this.currentBillingAddress.city,
                                        AddressType:
                                            this.currentBillingAddress
                                                .address_type,
                                        CreationUserId:
                                            this.currentBillingAddress
                                                .creation_user_id,
                                        ModificationUserId:
                                            this.userStore.current.id,
                                    }
                                    this.refresh += 1
                                } else {
                                    this.billingSameAsClient = true
                                    this.createNewBillingAddress = true

                                    this.billingAddress = {
                                        AddressName: `Facturation ${this.formData.Name}`,
                                        Address1: this.formData.Address1,
                                        Address2: this.formData.Address2,
                                        Address3: '',
                                        Address4: '',
                                        Country: this.formData.Country,
                                        PostalCode: this.formData.PostalCode,
                                        City: this.formData.City,
                                        AddressType: 'origin',
                                        CreationUserId:
                                            this.userStore.current.id,
                                        ModificationUserId:
                                            this.userStore.current.id,
                                    }
                                    this.refresh += 1
                                }
                            })
                    }
                    const clientAddressesPayload = {
                        clientId: this.currentClient.id,
                        query: [],
                        filter: [],
                        perPage: 100000000000,
                        page: 1,
                        sortField: 'Name',
                        sortDirection: 'ASC',
                    }
                    this.clientStore
                        .fetchAddresses(clientAddressesPayload)
                        .then(() => {
                            const clientAddresses = setInterval(() => {
                                if (this.clientAddresses) {
                                    this.clientAddresses.forEach((address) => {
                                        clearInterval(clientAddresses)

                                        const option = {
                                            name: address.name,
                                            value: address.id,
                                        }
                                        this.addressOptions.push(option)
                                        this.refreshMS += 1
                                    })
                                    this.refresh += 1

                                    this.refreshMS += 1
                                }
                            }, 1000)
                        })
                    this.contactOptions = []

                    this.clientStore
                        .fetchClientContacts({
                            clientId: this.currentClient.id,
                            fromOrders: true,
                        })
                        .then((res) => {
                            if (res && res.status === 200) {
                                if (
                                    res.data.Records &&
                                    res.data.Records.length
                                ) {
                                    res.data.Records.forEach((contact) => {
                                        this.noContactForClient = false
                                        const option = {
                                            name: `${contact.FirstName}  ${contact.LastName}`,
                                            value: +contact.IdUser,
                                        }
                                        this.contactOptions.push(option)
                                    })
                                    this.refreshMS += 1
                                    // this.formData.IdUserContact =
                                    //     this.contactOptions[0].value
                                } else {
                                    this.noContactForClient = true
                                }
                            } else {
                                this.noContactForClient = true
                            }
                        })
                    this.refresh += 1
                    this.refreshMS += 1
                    if (this.currentClient.billing_contact) {
                        this.contactForm = {
                            Id: this.currentClient.billing_contact.Id,
                            LastName:
                                this.currentClient.billing_contact.LastName,
                            FirstName:
                                this.currentClient.billing_contact.FirstName,
                            Email: this.currentClient.billing_contact.Email,
                            Login: this.currentClient.billing_contact.Login,
                            Type: this.currentClient.billing_contact.Type,

                            Phone: this.currentClient.billing_contact.Phone,
                            MobilePhone:
                                this.currentClient.billing_contact.MobilePhone,
                            CreationUserId:
                                this.currentClient.billing_contact
                                    .CreationUserId,
                            ModificationUserId: this.userStore.current.id,
                        }
                    } else {
                        this.userStore
                            .fetchOneByEmail(this.currentClient.email)
                            .then((res) => {
                                if (res && res.status === 200) {
                                    this.contactForm = {
                                        Id: this.clientStore.currentContact.id,
                                        LastName:
                                            this.clientStore.currentContact
                                                .lastname,
                                        FirstName:
                                            this.clientStore.currentContact
                                                .firstname,
                                        Email: this.clientStore.currentContact
                                            .email_address,
                                        Login: this.clientStore.currentContact
                                            .login,
                                        Type: this.clientStore.currentContact
                                            .type,

                                        Phone: this.clientStore.currentContact
                                            .phone,
                                        MobilePhone:
                                            this.clientStore.currentContact
                                                .mobile_phone,
                                        CreationUserId:
                                            this.clientStore.currentContact
                                                .creation_user_id,
                                        ModificationUserId:
                                            this.userStore.current.id,
                                    }
                                    this.refresh += 1
                                } else {
                                    this.contactSameAsClient = true
                                    this.createNewContact = true
                                    this.contactForm = {
                                        LastName: '',
                                        FirstName: '',
                                        Email: this.currentClient.email,
                                        Phone: '',
                                        MobilePhone: '',
                                        CreationUserId:
                                            this.userStore.current.id,
                                        ModificationUserId:
                                            this.userStore.current.id,
                                        Login: '',
                                        Type: 'client',
                                    }
                                    this.refresh += 1
                                }
                            })
                    }
                }
            }, 1000)
        } else {
            this.clientStore
                .searchContainers({
                    query: [],
                    filter: [
                        { dbField: 'Active', value: 1 },
                        { dbField: 'IdClient', value: 0 },
                    ],
                    sortDirection: 'ASC',
                    sortField: 'Name',
                    perPage: 1000000,
                    page: 1,
                })
                .then(() => {
                    this.clientStore.containers.forEach((container) => {
                        this.containerOptions.push({
                            realName: container.name,
                            name:
                                container.name.includes('_') ||
                                container.name === 'pallet' ||
                                container.Name === 'envelope'
                                    ? this.$t(container.name)
                                    : container.name,
                            value: +container.id,
                        })
                    })

                    this.refreshMS += 1
                })
            this.formData.IdDefaultContainer =
                this.userStore.current.env.defaultContainer
            this.refreshMS += 1
        }
        this.loadGoogleScript('autocomplete')
        this.loadGoogleScript('autocomplete-billing', true)
        this.refreshMS += 1
        this.refresh += 1
    },
    data() {
        return {
            createAndAddContract: false,
            showContractModal: false,
            contractFormData: {},
            clientOptions: [],
            showContractList: false,
            isUpdate: false,
            disableForm: false,
            createAndAddNote: false,
            clientCreated: false,
            showNoteForm: false,
            containerForm: {
                Name: '',
                Id: '',
                CreationUserId: '',
                Height: '',
                Width: '',
                Length: '',
                Weight: '',
                Description: '',
                Active: 1,
                IdClient: 0,
                IsClientDefault: false,
                SetAsDefaultTo: null,
            },
            isDefault: false,
            containerForAll: false,
            containerCreation: false,
            containerOptions: [],
            contactOptions: [],
            contactForm: {
                LastName: '',
                FirstName: '',
                Email: '',
                Phone: '',
                MobilePhone: '',
                Login: '',
                Type: 'client',
            },
            billingSameAsClient: true,
            contactSameAsClient: true,
            creating: false,
            userAlreadyExists: false,
            currentClientInterval: null,
            contact: null,
            createUser: true,
            createNewContact: false,
            createNewBillingAddress: false,
            universeOptions: [],
            formData: {
                WithVAT: true,
                Name: '',
                Email: '',
                IdUniverse: 0,
                ClientType: 'occasional',
                VATIntra: '',
                RCS: '',
                Address1: '',
                Address2: '',
                Address3: '',
                Address4: '',
                Country: 'FRA',
                PostalCode: '',
                City: '',
                Type: 'business',
                Siret: '',
                PaymentType: 'web',
                IdExternal: 0,
                IdUserFacturation: 0,
                IdAddressFacturation: null,
                VoucherPrice: 0,
                VoucherLimit: 0,
                Informations: '',
                CreationUserId: '',
                CreationDate: '',
                ModificationUserId: '',
                ModificationDate: '',
                IdDefaultContainer: 0,
            },
            billingAddress: {
                AddressName: 'Facturation',
                Address1: '',
                Address2: '',
                Address3: '',
                Address4: '',
                Country: 'FRA',
                PostalCode: '',
                City: '',
                AddressType: '',
            },
            addressOptions: [],
            refresh: 0,
            refreshMS: 0,
        }
    },
    validations() {
        return {
            containerForm: {
                Name: {
                    required: requiredIf(() => this.containerCreation === true),
                },
                Width: {
                    required: requiredIf(() => this.containerCreation === true),
                    minValueValue: minValue(0.01),
                },
                Length: {
                    required: requiredIf(() => this.containerCreation === true),
                    minValueValue: minValue(0.01),
                },
                Weight: {
                    required: requiredIf(() => this.containerCreation === true),
                    minValueValue: minValue(0.01),
                },
            },
            contactForm: {
                Email: {
                    required: requiredIf(() => !this.contactSameAsClient),
                    email,
                },
                FirstName: {
                    required: requiredIf(
                        () =>
                            this.$route.name === 'new_client' ||
                            (this.currentClient &&
                                (+this.currentClient.id_user_facturation ===
                                    0 ||
                                    this.currentClient.id_user_facturation ===
                                        null)),
                    ),
                },
                LastName: {
                    required: requiredIf(
                        () =>
                            this.$route.name === 'new_client' ||
                            (this.currentClient &&
                                (+this.currentClient.id_user_facturation ===
                                    0 ||
                                    this.currentClient.id_user_facturation ===
                                        null)),
                    ),
                },
                Phone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
                MobilePhone: {
                    isPhoneNumber: helpers.withMessage(
                        this.$t('bad_phone_format'),
                        isPhoneNumber,
                    ),
                },
            },
            formData: {
                IdUniverse: { required },
                IdDefaultContainer: {
                    required: requiredIf(
                        () => this.containerCreation === false,
                    ),
                },
                Name: { required },
                Address1: { required },
                PostalCode: {
                    required,
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },
                City: { required },
                Email: {
                    required,
                    email,
                },
            },
            billingAddress: {
                Address1: {
                    required: requiredIf(() => !this.billingSameAsClient),
                },
                PostalCode: {
                    required: requiredIf(() => !this.billingSameAsClient),
                    isZipCode: helpers.withMessage(
                        this.$t('bad_zip_format'),
                        isZipCode,
                    ),
                },
                City: { required: requiredIf(() => !this.billingSameAsClient) },
            },
        }
    },
    computed: {
        ...mapState(useUniverseStore, {
            universes: 'all',
        }),

        ...mapState(useContractStore, {
            contracts: 'all',
        }),
        clientActionsAccess() {
            return actionsAccess.clients
        },
        canAccessNotes() {
            return checkAccess(
                this.userStore,
                actionsAccess.clients.contactClientHistory.list,
            )
        },
        updateDefaultPackageAccess() {
            return checkAccess(
                this.userStore,
                actionsAccess.orders.containers.updateDefault,
            )
        },

        ...mapState(useClientStore, {
            currentClient: 'current',
            clientAddresses: 'addresses',
            currentBillingAddress: 'currentAddress',
        }),
        ...mapStores(
            useUserStore,
            useClientStore,
            useContactClientHistoryStore,
        ),
        clientTypeOptions() {
            return [
                { name: this.$t('voucher_book'), value: 'voucher' },
                { name: this.$t('occasional'), value: 'occasional' },
                // { name: this.$t('web'), value: 'web' },
                { name: this.$t('month_invoice'), value: 'month' },
            ]
        },

        paymentTypeOptions() {
            let options = [
                { name: this.$t('check'), value: 'check' },
                { name: this.$t('credit_card'), value: 'creditcard' },
                { name: this.$t('cash'), value: 'cash' },
                { name: this.$t('transfer'), value: 'transfer' },
            ]
            if (
                this.formData.ClientType === 'occasional' ||
                this.formData.ClientType === ''
            ) {
                options = [
                    { name: this.$t('web'), value: 'web' },
                    { name: this.$t('check'), value: 'check' },
                    { name: this.$t('credit_card'), value: 'creditcard' },
                    { name: this.$t('cash'), value: 'cash' },
                    { name: this.$t('transfer'), value: 'transfer' },
                ]
            }
            return options
        },
        typeOptions() {
            return [
                { name: this.$t('B2C'), value: 'customer' },
                { name: this.$t('B2B'), value: 'business' },
                { name: this.$t('prospect'), value: 'prospect' },
            ]
        },
    },
    watch: {
        'formData.ClientType': function () {
            if (
                this.formData.ClientType !== 'occasional' &&
                this.formData.PaymentType === 'web'
            ) {
                this.formData.PaymentType = 'transfer'
            }
        },
        // possibilité de créer un container => modale de création crée on save, refetch la liste et récuperer l'id de la response pour
        // le mettre dans le MSselect
        $route() {
            if (this.$route.params.id && this.currentClient !== null) {
                this.currentClientInterval = setInterval(() => {
                    if (this.currentClient) {
                        clearInterval(this.currentClientInterval)
                        this.clientStore
                            .fetchContainers(this.currentClient.id)
                            .then(() => {
                                this.clientStore.containers.forEach(
                                    (container) => {
                                        this.containerOptions.push({
                                            realName: container.Name,
                                            name:
                                                container.Name.includes('_') ||
                                                container.Name === 'pallet' ||
                                                container.Name === 'envelope'
                                                    ? this.$t(container.Name)
                                                    : container.Name,
                                            value: +container.Id,
                                        })
                                    },
                                )
                            })
                        this.formData = {
                            Name: this.currentClient.company_name,
                            Email: this.currentClient.email,
                            ClientType: this.currentClient.client_type,
                            VATIntra: this.currentClient.vat_intra,
                            RCS: this.currentClient.rcs,
                            Address1: this.currentClient.address,
                            Address2: this.currentClient.address2,
                            Address3: this.currentClient.address3,
                            Country: this.currentClient.country,
                            PostalCode: this.currentClient.postal_code,
                            City: this.currentClient.city,
                            WithVAT: this.currentClient.with_vat === 1,

                            // FirstName: this.currentClient.firstname,
                            // LastName: this.currentClient.lastname,

                            // Phone: this.currentClient,
                            Type: this.currentClient.type,
                            Siret: this.currentClient.registration_number,
                            PaymentType: this.currentClient.payment_type,
                            IdExternal: this.currentClient.id_external,
                            IdUserFacturation:
                                this.currentClient.id_user_facturation,
                            IdAddressFacturation:
                                this.currentClient.id_address_facturation,
                            VoucherPrice: this.currentClient.voucher_price,
                            VoucherLimit: this.currentClient.voucher_limit,
                            Informations: this.currentClient.informations,
                            CreationUserId: this.currentClient.creation_user_id,
                            CreationDate: this.currentClient.creation_date,
                            IdDefaultContainer:
                                this.currentClient.default_container > 0
                                    ? this.currentClient.default_container
                                    : this.userStore.current.env
                                          .defaultContainer,
                        }
                        if (
                            this.currentClient.universeId !== null &&
                            this.currentClient.universeId !== 0 &&
                            this.currentClient.universeId !== '0'
                        ) {
                            this.formData.IdUniverse =
                                this.currentClient.universeId
                        } else {
                            this.formData.IdUniverse =
                                this.universeOptions.find(
                                    (universe) =>
                                        universe.realName === 'Courses',
                                ).value
                        }
                        this.refreshMS += 1
                        if (this.currentClient.billing_address) {
                            this.billingAddress = {
                                IdClient: this.currentClient.id,
                                Address1:
                                    this.currentClient.billing_address.Address1,
                                Address2:
                                    this.currentClient.billing_address.Address2,
                                Address3:
                                    this.currentClient.billing_address.Address3,
                                Address4:
                                    this.currentClient.billing_address.Address4,

                                Country:
                                    this.currentClient.billing_address.Country,
                                PostalCode:
                                    this.currentClient.billing_address
                                        .PostalCode,
                                City: this.currentClient.billing_address.City,
                                AddressType:
                                    this.currentClient.billing_address
                                        .AddressType,
                                Name: this.currentClient.billing_address.Name,
                                Code: this.currentClient.billing_address.Code,
                                AdditionalInformation:
                                    this.currentClient.billing_address
                                        .AdditionalInformation,
                                DriverInformation:
                                    this.currentClient.billing_address
                                        .DriverInformation,
                                Latitude:
                                    this.currentClient.billing_address.Latitude,
                                Longitude:
                                    this.currentClient.billing_address
                                        .Longitude,
                                ContactName:
                                    this.currentClient.billing_address
                                        .ContactName,
                                ContactEmail:
                                    this.currentClient.billing_address
                                        .ContactEmail,
                                ContactPhone:
                                    this.currentClient.billing_address
                                        .ContactPhone,
                                ContactNameImported:
                                    this.currentClient.billing_address
                                        .ContactNameImported,
                                ContactPhoneImported:
                                    this.currentClient.billing_address
                                        .ContactPhoneImported,
                                WaitingTime:
                                    this.currentClient.billing_address
                                        .WaitingTime,
                                Id: this.currentClient.billing_address.Id,
                                CreationUserId:
                                    this.currentClient.billing_address
                                        .CreationUserId,
                                IdExternal:
                                    this.currentClient.billing_address
                                        .IdExternal,
                                CreationDate:
                                    this.currentClient.billing_address
                                        .CreationDate,
                                ModificationUserId: this.userStore.current.id,
                            }
                        } else {
                            // TODO faire comme pour les contacts si pas d'adresse de facturation alors on recherche l'adresse client pour la setter
                            this.clientStore
                                .fetchOneAddressByIdClientAndNameAndAddress1AndPostalCode(
                                    {
                                        idClient: this.$route.params.id,
                                        name: 'Principale',
                                        zip: this.currentClient.postal_code,
                                        address: this.currentClient.address,
                                    },
                                )
                                .then(() => {
                                    if (this.currentBillingAddress) {
                                        this.billingAddress = {
                                            Id: this.currentBillingAddress.id,
                                            AddressName:
                                                this.currentBillingAddress.name,
                                            Address1:
                                                this.currentBillingAddress
                                                    .address,
                                            Address2:
                                                this.currentBillingAddress
                                                    .address2,
                                            Address3:
                                                this.currentBillingAddress
                                                    .address3,

                                            Address4:
                                                this.currentBillingAddress
                                                    .address4,
                                            Country:
                                                this.currentBillingAddress
                                                    .Country,
                                            PostalCode:
                                                this.currentBillingAddress
                                                    .postal_code,
                                            City: this.currentBillingAddress
                                                .city,
                                            AddressType:
                                                this.currentBillingAddress
                                                    .address_type,
                                            CreationUserId:
                                                this.currentBillingAddress
                                                    .creation_user_id,
                                            ModificationUserId:
                                                this.userStore.current.id,
                                        }
                                        this.refresh += 1
                                    } else {
                                        this.billingSameAsClient = true

                                        this.billingAddress = {
                                            AddressName: `Facturation ${this.formData.Name}`,
                                            Address1: this.formData.Address1,
                                            Address2: this.formData.Address2,
                                            Address3: '',
                                            Address4: '',
                                            Country: this.formData.Country,
                                            PostalCode:
                                                this.formData.PostalCode,
                                            City: this.formData.City,
                                            AddressType: 'origin',
                                            CreationUserId:
                                                this.userStore.current.id,
                                            ModificationUserId:
                                                this.userStore.current.id,
                                        }
                                        this.refresh += 1
                                    }
                                })
                        }
                        const clientAddressesPayload = {
                            clientId: this.currentClient.id,
                            query: [],
                            filter: [],
                            perPage: 100000000000,
                            page: 1,
                            sortField: 'Name',
                            sortDirection: 'ASC',
                        }
                        this.clientStore
                            .fetchAddresses(clientAddressesPayload)
                            .then(() => {
                                const clientAddresses = setInterval(() => {
                                    if (this.clientAddresses) {
                                        this.clientAddresses.forEach(
                                            (address) => {
                                                clearInterval(clientAddresses)

                                                const option = {
                                                    name: address.name,
                                                    value: address.id,
                                                }
                                                this.addressOptions.push(option)
                                                this.refreshMS += 1
                                            },
                                        )
                                        this.refresh += 1

                                        this.refreshMS += 1
                                    }
                                }, 1000)
                            })

                        this.refresh += 1
                        this.refreshMS += 1
                        if (this.currentClient.billing_contact) {
                            this.contactForm = {
                                Id: this.currentClient.billing_contact.Id,
                                LastName:
                                    this.currentClient.billing_contact.LastName,
                                FirstName:
                                    this.currentClient.billing_contact
                                        .FirstName,
                                Email: this.currentClient.billing_contact.Email,
                                Login: this.currentClient.billing_contact.Login,
                                Type: this.currentClient.billing_contact.Type,

                                Phone: this.currentClient.billing_contact.Phone,
                                MobilePhone:
                                    this.currentClient.billing_contact
                                        .MobilePhone,
                                CreationUserId:
                                    this.currentClient.billing_contact
                                        .CreationUserId,
                                ModificationUserId: this.userStore.current.id,
                            }
                        } else {
                            this.userStore
                                .fetchOneByEmail(this.currentClient.email)
                                .then(() => {
                                    if (this.clientStore.currentContact) {
                                        this.contactForm = {
                                            Id: this.clientStore.currentContact
                                                .id,
                                            LastName:
                                                this.clientStore.currentContact
                                                    .lastname,
                                            FirstName:
                                                this.clientStore.currentContact
                                                    .firstname,
                                            Email: this.clientStore
                                                .currentContact.email_address,
                                            Login: this.clientStore
                                                .currentContact.login,
                                            Type: this.clientStore
                                                .currentContact.type,

                                            Phone: this.clientStore
                                                .currentContact.phone,
                                            MobilePhone:
                                                this.clientStore.currentContact
                                                    .mobile_phone,
                                            CreationUserId:
                                                this.clientStore.currentContact
                                                    .creation_user_id,
                                            ModificationUserId:
                                                this.userStore.current.id,
                                        }
                                        this.refresh += 1
                                    } else {
                                        this.contactSameAsClient = true

                                        this.contactForm = {
                                            LastName: '',
                                            FirstName: '',
                                            Email: this.currentClient.email,
                                            Phone: '',
                                            MobilePhone: '',
                                            CreationUserId:
                                                this.userStore.current.id,
                                            ModificationUserId:
                                                this.userStore.current.id,
                                            Login: '',
                                            Type: 'client',
                                        }
                                        this.refresh += 1
                                    }
                                })
                        }
                        this.contactOptions = []

                        this.clientStore
                            .fetchClientContacts({
                                clientId: this.currentClient.id,
                                fromOrders: true,
                            })
                            .then((res) => {
                                if (res && res.status === 200) {
                                    if (
                                        res.data.Records &&
                                        res.data.Records.length
                                    ) {
                                        res.data.Records.forEach((contact) => {
                                            this.noContactForClient = false
                                            const option = {
                                                name: `${contact.FirstName}  ${contact.LastName}`,
                                                value: +contact.IdUser,
                                            }
                                            this.contactOptions.push(option)
                                        })
                                        this.refreshMS += 1
                                        // this.formData.IdUserContact =
                                        //     this.contactOptions[0].value
                                    } else {
                                        this.noContactForClient = true
                                    }
                                } else {
                                    this.noContactForClient = true
                                }
                            })
                    }
                }, 1000)
            }
        },
    },
    methods: {
        ...mapActions(useUniverseStore, {
            searchUniverses: 'search',
        }),
        refreshNoteFiles(id) {
            this.contactClientHistoryStore.fetchNoteFiles(id).then(() => {
                this.refresh += 1
            })
        },
        openNoteForm(note = null, refetchClient = false) {
            this.contactClientHistoryStore.$patch({ current: null })
            this.disableForm = false

            if (note && this.showNoteForm) {
                this.disableForm = note.disabled
                this.contactClientHistoryStore.fetchOne(note.id).then(() => {
                    this.refresh += 1
                })
            }
            if (refetchClient) {
                this.clientStore.fetchOne(this.$route.params.id).then(() => {
                    this.refresh += 1
                })
            }
            this.showNoteForm = !this.showNoteForm

            this.refresh += 1
        },
        setContractForm(contractId = null, clientId = null) {
            if (this.$route.name === 'new_client') {
                this.createClient(false, true)
            } else {
                clearInterval(this.contractsInterval)
                if (contractId === null) {
                    this.contractFormData = {
                        Name: '',
                        SignatureDate: new Date().toISOString().substr(0, 10),
                        EndDate: new Date().toISOString().substr(0, 10),
                        CreationDate: '',
                        ModificationDate: '',
                        CreationUserId: this.userStore.current.id,
                        ModificationUserId: this.userStore.current.id,
                        AutomaticRenewal: 'no',
                        Type: 'client',
                        Duration: 'none',
                        AlertBeforeEnd: 'none',
                        NoticePeriod: 'none',
                        IdUniverse: '',
                        IdClient: +clientId,
                        Id: '',
                    }
                    const option = {
                        name: this.currentClient.company_name,
                        value: +clientId,
                        dbField: 'IdCLient',
                    }
                    this.clientOptions.push(option)
                    this.isUpdate = false
                    this.showContractModal = true
                } else {
                    const contractToUpdate = this.contracts.find(
                        (contract) => contract.id === +contractId,
                    )
                    this.isUpdate = true

                    this.contractFormData = {
                        Name: contractToUpdate.name,
                        SignatureDate: contractToUpdate.signature_date.substr(
                            0,
                            10,
                        ),
                        EndDate: contractToUpdate.end_date
                            ? contractToUpdate.end_date.substr(0, 10)
                            : '',
                        CreationDate: contractToUpdate.creation_date,
                        ModificationDate: '',
                        CreationUserId: contractToUpdate.creation_user_id,
                        ModificationUserId: this.userStore.current.id,
                        AutomaticRenewal: contractToUpdate.auto_renew,
                        Type: contractToUpdate.contract_type,
                        Duration: contractToUpdate.contract_duration,
                        AlertBeforeEnd: contractToUpdate.alert_before_end,
                        NoticePeriod: contractToUpdate.notice,
                        IdUniverse: contractToUpdate.idUniverse,
                        IdClient: +contractToUpdate.id_client,
                        Id: contractToUpdate.id,
                    }
                    const option = {
                        name: this.currentClient.company_name,
                        value: +contractToUpdate.id_client,
                        dbField: 'IdCLient',
                    }
                    this.clientOptions.push(option)
                    this.showContractModal = true
                }
            }
        },
        resetContractForm() {
            // this.v$.contractFormData.$reset()
            this.showContractModal = false
            this.isUpdate = false
            // this.creatingContract = false
            // this.badTimeRange = false

            this.contractFormData = {}
            this.clientStore.fetchOne(this.$route.params.id).then(() => {
                this.refresh += 1
            })
        },
        canAccess(action) {
            let result = false
            if (action === 'createContact') {
                result = checkAccess(
                    this.userStore,
                    this.clientActionsAccess.contacts.create,
                )
            }
            if (action === 'updateContact') {
                result = checkAccess(
                    this.userStore,
                    this.clientActionsAccess.contacts.update,
                )
            }
            if (action === 'createAddress') {
                result = checkAccess(
                    this.userStore,
                    this.clientActionsAccess.addresses.create,
                )
            }
            if (action === 'updateAddress') {
                result = checkAccess(
                    this.userStore,
                    this.clientActionsAccess.addresses.update,
                )
            }
            return result
        },
        back() {
            const backPath = this.$router.options.history.state.back
            this.$router.push(backPath)
            this.clientStore.$patch({ current: null })
            this.$emit('back', 'clients')
        },
        unsetContainerCreation() {
            this.v$.containerForm.$reset()

            this.containerCreation = false

            this.containerForm = {
                Name: '',
                Id: '',
                CreationUserId: '',
                Height: '',
                Width: '',
                Length: '',
                Weight: '',
                Description: '',
                Active: 1,
                IdClient: 0,
            }

            this.formData.IdDefaultContainer =
                this.currentClient && this.currentClient.default_container > 0
                    ? +this.currentClient.default_container
                    : +this.userStore.current.env.defaultContainer
        },
        setContainerCreation() {
            this.v$.containerForm.$reset()

            this.containerCreation = true

            if (!this.$route.params.id) {
                this.formData.IdDefaultContainer =
                    +this.userStore.current.env.defaultContainer
            }

            this.clientStore.$patch({ currrentContainer: null })
        },
        setBillingContact(contactId = null, createNewContact = false) {
            if (contactId !== null) {
                this.createNewContact = false

                this.userStore.fetchOne(contactId)
                const currentContact = setInterval(() => {
                    if (this.clientStore.currentContact !== null) {
                        clearInterval(currentContact)
                        this.contactForm = {
                            Id: contactId,
                            LastName: this.clientStore.currentContact.lastname,
                            FirstName:
                                this.clientStore.currentContact.firstname,
                            Email: this.clientStore.currentContact
                                .email_address,

                            Login: this.clientStore.currentContact.login,
                            Phone: this.clientStore.currentContact.phone,
                            Type: this.clientStore.currentContact.type,

                            MobilePhone: this.clientStore.currentContact.mobile,
                            CreationUserId:
                                this.clientStore.currentContact
                                    .creation_user_id,
                        }
                        this.formData.IdUserFacturation = contactId
                        this.refreshMS += 1
                        this.contactSameAsClient = false
                    }
                }, 500)
            } else if (
                this.contactSameAsClient === true &&
                createNewContact === false
            ) {
                this.createNewContact = false

                this.contactForm = {
                    Id: this.currentClient.billing_contact.Id,
                    LastName: this.currentClient.billing_contact.LastName,
                    FirstName: this.currentClient.billing_contact.FirstName,
                    Email: this.currentClient.billing_contact.Email,
                    Login: this.currentClient.billing_contact.Login,
                    Type: this.currentClient.billing_contact.Type,

                    Phone: this.currentClient.billing_contact.Phone,
                    MobilePhone: this.currentClient.billing_contact.MobilePhone,
                    CreationUserId:
                        this.currentClient.billing_contact.CreationUserId,
                    ModificationUserId: this.userStore.current.id,
                }
            } else {
                this.createNewContact = true

                this.contactForm = {
                    LastName: '',
                    Type: 'client',

                    FirstName: '',
                    Email: '',
                    Phone: '',
                    MobilePhone: '',
                    CreationUserId: this.userStore.current.id,
                    ModificationUserId: this.userStore.current.id,
                    Login: '',
                }
            }
        },
        setBillingAddress(addressId = null, createNewAddress = false) {
            if (addressId !== null) {
                this.createNewBillingAddress = false
                this.clientStore.$patch({ currentAddress: null })
                this.clientStore.fetchOneAddress({ id: addressId })
                const currentAddressInterval = setInterval(() => {
                    if (this.currentBillingAddress !== null) {
                        clearInterval(currentAddressInterval)
                        this.billingAddress = {
                            Id: addressId,
                            Name: this.currentBillingAddress.name,
                            Address1: this.currentBillingAddress.address,
                            Address2: this.currentBillingAddress.address2,
                            Address3: this.currentBillingAddress.address3,
                            Address4: this.currentBillingAddress.address4,
                            Country: this.currentBillingAddress.country,
                            PostalCode: this.currentBillingAddress.postal_code,
                            City: this.currentBillingAddress.city,
                            AddressType:
                                this.currentBillingAddress.address_type,
                        }
                        this.formData.IdAddressFacturation = addressId
                        this.refreshMS += 1
                    }
                    this.billingSameAsClient = false
                }, 500)
            } else if (
                this.billingSameAsClient === true &&
                createNewAddress === false
            ) {
                this.createNewBillingAddress = false

                this.billingAddress = {
                    Name: `Facturation ${this.formData.Name}`,
                    Address1: this.formData.Address1,
                    Address2: this.formData.Address2,
                    Country: this.formData.Country,
                    Address3: '',
                    Address4: '',
                    PostalCode: this.formData.PostalCode,
                    City: this.formData.City,
                    AddressType: 'origin',
                }
            } else {
                this.createNewBillingAddress = true

                this.loadGoogleScript('autocomplete-billing', true)

                this.billingAddress = {
                    AddressName: '',
                    Address1: '',
                    Address2: '',
                    Country: 'FRA',
                    Address3: '',
                    Address4: '',
                    PostalCode: '',
                    City: '',
                    AddressType: 'origin',
                    CreationUserId: this.userStore.current.id,
                    ModificationUserId: this.userStore.current.id,
                }
                this.billingSameAsClient = false
            }
        },
        fillInAddress(autocomplete, billing = false) {
            let addressToFillIn = this.formData
            if (billing === true) {
                addressToFillIn = this.billingAddress
            }
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace()

            // Get each component of the address from the place details
            // and fill the corresponding field on the formData.
            // TODO gérér les cas selon le type de places ... ex CDG ne remplit pas le champ address1
            // TODO vérifier sur google les valeurs disponibles 'airport poi ...""
            // TODO checker place.formatted_address
            addressToFillIn.Address2 = ''
            addressToFillIn.Address1 = ''

            addressToFillIn.PostalCode = ''
            addressToFillIn.City = ''

            const userInput = addressToFillIn.Address1
            place.address_components.forEach((element, index) => {
                const addressType = element.types[0]
                const val = element.short_name

                if (addressType === 'postal_code') {
                    addressToFillIn.PostalCode = val
                } else if (addressType === 'locality') {
                    addressToFillIn.City = val
                } else if (addressType === 'street_number') {
                    addressToFillIn.Address1 = ` ${val}`
                } else if (addressType === 'route') {
                    addressToFillIn.Address1 =
                        addressToFillIn.Address1 !== '' &&
                        place.types[0] !== 'route'
                            ? `${addressToFillIn.Address1} ${val}`
                            : `${val}`
                } else if (addressType === 'premise') {
                    addressToFillIn.Address2 = `${val}`
                }
                if (
                    (place.types[0] !== 'street_number' ||
                        place.types[0] !== 'route') &&
                    addressType === 'locality' &&
                    addressToFillIn.Address1 === userInput
                ) {
                    addressToFillIn.Address1 = ` ${val}`
                }
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'premise' &&
                    addressToFillIn.Address2 === ''
                ) {
                    addressToFillIn.Address2 = place.name
                }
                // TODO à voir si on ajoute pour préremplir le nom du client s'il est vide
                if (
                    place.types[0] !== 'street_address' &&
                    place.types[0] !== 'route' &&
                    place.types[0] !== 'locality' &&
                    place.types[0] !== 'premise' &&
                    addressToFillIn.Name === ''
                ) {
                    addressToFillIn.Name = place.name
                }
            })
        },
        async createClient(
            createAndAddNote = false,
            createAndAddContract = false,
        ) {
            this.creating = true
            if (createAndAddNote === true) {
                this.createAndAddNote = createAndAddNote
            }
            if (createAndAddContract === true) {
                this.createAndAddContract = createAndAddContract
            }
            this.creating = true
            this.userAlreadyExists = false
            this.formData.BillingSameAsClient = this.billingSameAsClient
            if (this.billingSameAsClient === false) {
                this.formData.BillingAddress = this.billingAddress
            }
            this.formData.LastName = this.contactForm.LastName
            this.formData.FirstName = this.contactForm.FirstName
            if (!this.formData.IdDefaultContainer) {
                this.formData.IdDefaultContainer =
                    this.userStore.current.env.defaultContainer
            }
            this.contactForm.Login = this.contactForm.Email
            this.contactForm.CreationUserId = this.userStore.current.id
            this.contactForm.ModificationUserId = this.userStore.current.id

            this.formData.BillingContact = this.contactForm

            this.formData.ContactSameAsClient = this.contactSameAsClient

            this.formData.CreationUserId = this.userStore.current.id
            this.formData.ModificationUserId = this.userStore.current.id
            const isFormCorrect = await this.v$.$validate()

            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                try {
                    // if (this.createUser === true) {
                    const userForm = {
                        Login: this.formData.Email,
                        Password: '',
                        Email: this.formData.Email,
                        FirstName: this.contactForm.FirstName,
                        LastName: this.contactForm.LastName,
                        Type: 'client',
                        Phone: this.contactForm.Phone,
                        MobilePhone: this.contactForm.MobilePhone,
                        CreationUserId: this.formData.CreationUserId,
                        ModificationUserId: this.formData.ModificationUserId,
                        Client: this.formData,
                        IdUniverse: this.formData.IdUniverse,
                    }
                    this.userStore
                        .create(userForm)
                        .then(async (res) => {
                            this.userStore.forgotPassword({
                                Email: this.formData.Email,
                            })
                            if (this.contactSameAsClient === false) {
                                this.userStore.forgotPassword({
                                    Email: this.contactForm.Email,
                                })
                            }
                            this.userStore
                                .fetchOne(res.data.Id)
                                .then((userRes) => {
                                    const clientId = userRes.data.IdClient
                                    if (this.containerCreation === true) {
                                        this.containerForm.CreationUserId =
                                            this.userStore.current.id
                                        this.containerForm.ModificationUserId =
                                            this.userStore.current.id
                                        this.containerForm.IdClient = this
                                            .containerForAll
                                            ? 0
                                            : clientId
                                        this.containerForm.IsClientDefault =
                                            this.isDefault
                                        this.containerForm.SetAsDefaultTo = this
                                            .isDefault
                                            ? clientId
                                            : null
                                        this.clientStore
                                            .createContainer(this.containerForm)
                                            .then((containerRes) => {
                                                if (
                                                    containerRes.status ===
                                                        200 &&
                                                    this.isDefault === true
                                                ) {
                                                    const payload = {
                                                        IdClient: clientId,
                                                        form: {
                                                            IdDefaultContainer:
                                                                containerRes
                                                                    .data
                                                                    .IdDefaultContainer,
                                                            ModificationUserId:
                                                                this.userStore
                                                                    .current.id,
                                                        },
                                                        reloadClient: false,
                                                    }
                                                    this.clientStore.setNewDefaultContainer(
                                                        payload,
                                                    )
                                                }
                                            })
                                            .catch((containerErr) => {
                                                this.$toast.error(
                                                    `${this.$t(
                                                        'container_create_ko',
                                                    )}`,
                                                )
                                            })
                                    }
                                    if (
                                        !createAndAddNote &&
                                        this.createAndAddNote === false &&
                                        !createAndAddContract &&
                                        this.createAndAddContract === false
                                    ) {
                                        this.creating = false
                                        const backPath =
                                            this.$router.options.history.state
                                                .back
                                        this.$router.push(backPath)
                                        this.$toast.success(
                                            this.$t('client_create_ok'),
                                        )
                                        this.$emit('created', 'clients')
                                    } else {
                                        this.creating = false
                                        this.$toast.success(
                                            this.$t('client_create_ok'),
                                        )
                                        if (
                                            createAndAddNote &&
                                            this.createAndAddNote === true
                                        ) {
                                            this.openNoteForm(null)
                                            this.$toast.info(
                                                this.$t('can_add_note'),
                                            )
                                        }
                                        if (
                                            createAndAddContract &&
                                            this.createAndAddContract === true
                                        ) {
                                            this.$toast.info(
                                                this.$t('can_add_contract'),
                                            )
                                        }

                                        this.createAndAddNote = false
                                        this.$emit('goToUpdateClient', clientId)
                                    }
                                })
                        })
                        .catch((err) => {
                            this.creating = false
                            if (err.response.status === 422) {
                                this.userAlreadyExists = true
                                this.$toast.error(
                                    `${this.$t('user_already_exists')}`,
                                )
                            } else {
                                this.$toast.error(
                                    `${this.$t('client_create_ko')}`,
                                )
                                // this.$toast.error(err.data.message)
                            }
                        })
                } catch (err) {
                    this.creating = false
                    if (err.response.status === 422) {
                        this.userAlreadyExists = true
                        this.$toast.error(`${this.$t('user_already_exists')}`)
                    } else {
                        this.$toast.error(`${this.$t('client_create_ko')}`)
                        // this.$toast.error(err.data.message)
                    }
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
                if (createAndAddNote === true) {
                    this.$toast.info(this.$t('save_client_first'))
                }
                if (createAndAddContract === true) {
                    this.$toast.info(this.$t('save_client_before_contract'))
                }
            }
        },
        async updateClient() {
            this.creating = true
            this.formData.ModificationUserId = this.userStore.current.id

            if (this.createNewBillingAddress === true) {
                this.billingAddress.CreationUserId = this.userStore.current.id
                this.billingAddress.ModificationUserId =
                    this.userStore.current.id
            }
            if (this.createNewContact === true) {
                this.contactForm.CreationUserId = this.userStore.current.id
                this.contactForm.ModificationUserId = this.userStore.current.id
            }

            this.formData.SetExistingUserFacturationId =
                +this.currentClient.id_user_facturation === 0 ||
                this.currentClient.id_user_facturation === null

            this.formData.SetExistingAddressFacturationId =
                +this.currentClient.id_address_facturation === 0 ||
                this.currentClient.id_address_facturation === null

            this.formData.BillingAddress = this.billingAddress
            this.formData.BillingContact = this.contactForm
            this.formData.BillingContact.Login = this.contactForm.Email
            this.formData.BillingSameAsClient = this.billingSameAsClient
            this.formData.ContactSameAsClient = this.contactSameAsClient
            this.formData.CreateContact = this.createNewContact
            this.formData.CreateAddress = this.createNewBillingAddress
            this.formData.ClientHasNoUser =
                this.currentClient.email === '' ||
                this.currentClient.email === null
            const isFormCorrect = await this.v$.$validate()
            const clientId = this.currentClient.id
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            if (isFormCorrect === true) {
                const params = {
                    id: this.$route.params.id,
                    form: this.formData,
                }
                try {
                    // TODO gérer le emailalreadyused
                    this.clientStore
                        .update(params)
                        .then((res) => {
                            if (
                                this.createNewContact ||
                                this.formData.ClientHasUser === false
                            ) {
                                this.userStore.forgotPassword({
                                    Email: this.contactForm.Email,
                                })
                            }
                            if (this.containerCreation === true) {
                                this.containerForm.ModificationUserId =
                                    this.userStore.current.id
                                this.containerForm.IdClient = this
                                    .containerForAll
                                    ? 0
                                    : clientId
                                this.containerForm.IsClientDefault =
                                    this.isDefault
                                this.containerForm.SetAsDefaultTo = this
                                    .isDefault
                                    ? clientId
                                    : null
                                this.clientStore
                                    .createContainer(this.containerForm)
                                    .then((containerRes) => {
                                        if (
                                            containerRes.status === 201 &&
                                            this.isDefault === true
                                        ) {
                                            const payload = {
                                                IdClient: clientId,
                                                form: {
                                                    IdDefaultContainer:
                                                        containerRes.data
                                                            .IdDefaultContainer,
                                                    ModificationUserId:
                                                        this.userStore.current
                                                            .id,
                                                },
                                                reloadClient: false,
                                            }
                                            this.clientStore.setNewDefaultContainer(
                                                payload,
                                            )
                                        }
                                    })
                                    .catch((containerErr) => {
                                        this.$toast.error(
                                            `${this.$t('container_create_ko')}`,
                                        )
                                    })
                            }
                            // document.head.removeChild(this.googleScript)
                            this.clientStore.$patch({ current: null })

                            const backPath =
                                this.$router.options.history.state.back
                            this.$router.push(backPath)
                            this.$toast.success(this.$t('client_update_ok'))

                            this.$emit('updated', 'clients')
                        })
                        .catch((err) => {
                            this.creating = false

                            if (err.response.status === 422) {
                                this.userAlreadyExists = true
                                this.$toast.error(
                                    `${this.$t('user_already_exists')}`,
                                )
                            } else {
                                this.$toast.error(
                                    `${this.$t('client_create_ko')}`,
                                )
                                // this.$toast.error(err.data.message)
                            }
                        })
                } catch (err) {
                    this.creating = false
                    if (err.response.status === 422) {
                        this.userAlreadyExists = true
                        this.$toast.error(`${this.$t('user_already_exists')}`)
                    } else {
                        this.$toast.error(`${this.$t('client_create_ko')}`)
                        // this.$toast.error(err.data.message)
                    }
                }
            } else {
                this.creating = false

                this.$toast.error(this.$t('form_errors'))
            }
        },
    },
    unmounted() {
        this.clientStore.$patch({ current: null })

        this.contactClientHistoryStore.$patch({ current: null })
    },
}
</script>
<style scoped>
@media (min-width: 1920px) and (max-width: 2560px) {
    main {
        width: 97vw !important;
    }
}
@media (max-width: 1919px) {
    main {
        width: 96vw !important;
    }
}
</style>
