<template>
    <div @click="$emit('closePop')">
        <div
            tabindex="-1"
            aria-hidden="hidden"
            class="overflow-y-auto overflow-x-hidden flex fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center bg-gray-400/25"
        >
            <div class="relative p-4 w-full h-screen" :class="widthAndHeight">
                <div class="relative darky bg-white rounded shadow">
                    <span
                        class="flex justify-between items-start py-1 px-4 rounded-t border-b"
                    >
                        <div class="grid grid-cols-3 gap-2 w-full">
                            <div
                                class="item-start col-span-1 mt-2 font-semibold text-zinc-400"
                            >
                                <h3
                                    class="flex cursor-copy items-center"
                                    :title="copyTitle"
                                    @click.stop="copyToClipboard(headerText)"
                                >
                                    {{ headerText }}
                                    <fa-icon
                                        icon="copy"
                                        class="fa-md ml-2"
                                    ></fa-icon>
                                </h3>
                                <div
                                    v-if="
                                        card.externalId &&
                                        userStore.isB2C === false
                                    "
                                    class="flex items-center cursor-copy"
                                    :title="`${$t(
                                        'external_ref',
                                    )} - ${copyTitle}`"
                                    @click.stop="
                                        copyToClipboard(card.externalId)
                                    "
                                >
                                    {{ card.externalId }}
                                    <fa-icon
                                        icon="copy"
                                        class="fa-md ml-2"
                                    ></fa-icon>
                                </div>
                            </div>
                            <div
                                v-if="userStore.isB2C === false"
                                class="col-end-4 mt-2 col-span-1"
                            >
                                <span class="text-xs flex justify-end italic">
                                    {{
                                        `${$t('creation_date')} ${
                                            card.creation
                                        }`
                                    }}
                                </span>

                                <span
                                    class="text-xs flex justify-end italic mt-1"
                                >
                                    {{
                                        `${$t('modification_date')} ${
                                            card.modification
                                        }`
                                    }}
                                </span>
                            </div>
                        </div>
                    </span>

                    <slot name="body">
                        <div class="p-3 space-y-6">
                            <span class="flex-row flex-wrap">
                                <div class="w-full">
                                    <!-- Profile Card -->
                                    <div v-if="card === false" class="py-6">
                                        <div class="mb-2 font-semibold text-lg">
                                            {{ $t('incomplete_order') }}
                                        </div>
                                        <div>{{ $t('contact_support') }}</div>
                                    </div>
                                    <div v-else>
                                        <!-- class="flex flex-row flex-wrap p-3 antialiased" -->
                                        <div
                                            class="justify-center flex w-full text-lg p-2 pb-4 items-center"
                                        >
                                            <div class="">
                                                <span
                                                    :id="`sub-icon-recurrent`"
                                                    :title="$t('recurrent')"
                                                    class="fa-fw text-sm"
                                                    v-if="
                                                        card.recurrent === true
                                                    "
                                                >
                                                    <fa-layer>
                                                        <fa-icon
                                                            :icon="'cart-shopping'"
                                                        ></fa-icon>

                                                        <fa-icon
                                                            style="
                                                                font-weight: 200;
                                                            "
                                                            transform="shrink-6 up-10 right-14"
                                                            :icon="'repeat'"
                                                        ></fa-icon>
                                                        <!-- transform="shrink-6" -->
                                                    </fa-layer>
                                                    <span
                                                        class="ml-4 mr-2 text-base"
                                                    >
                                                        {{
                                                            `${$t(
                                                                'recurrent',
                                                            )} ${
                                                                card.roundTrip ===
                                                                true
                                                                    ? ' -'
                                                                    : ' '
                                                            }`
                                                        }}
                                                    </span>
                                                </span>
                                                <span
                                                    :id="`sub-icon-round`"
                                                    :title="$t('round_trip')"
                                                    class="fa-fw text-sm"
                                                    v-if="
                                                        card.roundTrip === true
                                                    "
                                                >
                                                    <fa-layer>
                                                        <fa-icon
                                                            :icon="'route'"
                                                        ></fa-icon>

                                                        <fa-icon
                                                            style="
                                                                font-weight: 200;
                                                            "
                                                            transform="shrink-6 up-10 right-14"
                                                            :icon="'rotate'"
                                                        ></fa-icon>
                                                    </fa-layer>
                                                    <span
                                                        class="ml-4 text-base"
                                                    >
                                                        {{ $t('round_trip') }}
                                                    </span>
                                                </span>
                                                <span
                                                    v-for="(
                                                        gPack, indGPack
                                                    ) in card.package
                                                        .groupedPacks"
                                                    :key="indGPack"
                                                >
                                                    <span class="mt-2">
                                                        <div
                                                            class="text-sm font-semibold items-center justify-center flex"
                                                        >
                                                            <fa-layer
                                                                :title="
                                                                    $t(
                                                                        'no_temp_specified',
                                                                    )
                                                                "
                                                                v-if="
                                                                    gPack
                                                                        .temperature
                                                                        .noTemp
                                                                "
                                                                class="fa-xl mr-2"
                                                            >
                                                                <fa-icon
                                                                    icon="ban"
                                                                    style="
                                                                        color: grey;
                                                                    "
                                                                ></fa-icon>
                                                                <fa-icon
                                                                    :icon="
                                                                        gPack
                                                                            .temperature
                                                                            .icon
                                                                    "
                                                                    transform="shrink-6"
                                                                ></fa-icon>
                                                            </fa-layer>
                                                            <span
                                                                v-else
                                                                class="mr-2"
                                                                :style="`${gPack.temperature.color}`"
                                                            >
                                                                <fa-icon
                                                                    :title="
                                                                        $t(
                                                                            gPack
                                                                                .temperature
                                                                                .title,
                                                                        )
                                                                    "
                                                                    :icon="
                                                                        gPack
                                                                            .temperature
                                                                            .icon
                                                                    "
                                                                    class="fa-xl"
                                                                ></fa-icon>
                                                            </span>
                                                            <span
                                                                v-if="
                                                                    gPack.container.includes(
                                                                        '_',
                                                                    ) ===
                                                                        true ||
                                                                    gPack.container ===
                                                                        'pallet' ||
                                                                    gPack.container ===
                                                                        'envelope'
                                                                "
                                                                class="whitespace-nowrap"
                                                            >
                                                                {{
                                                                    `${
                                                                        gPack.quantity
                                                                    } ${$tc(
                                                                        gPack.container,
                                                                        +gPack.quantity ===
                                                                            1
                                                                            ? 1
                                                                            : 2,
                                                                    )}`
                                                                }}
                                                            </span>
                                                            <span
                                                                v-else
                                                                class="whitespace-nowrap"
                                                            >
                                                                {{
                                                                    `${gPack.quantity} ${gPack.container}`
                                                                }}
                                                            </span>
                                                        </div>
                                                        <div class="text-xs">
                                                            {{
                                                                `${
                                                                    gPack.size
                                                                }  - 
                                                        
                                                            ${
                                                                gPack.weight
                                                            } kg  - 
                                                          ${$t('value')}: ${
                                                              gPack.worth
                                                          }`
                                                            }}
                                                        </div>
                                                    </span>
                                                </span>

                                                <div
                                                    v-if="
                                                        card.distance &&
                                                        card.duration
                                                    "
                                                    class="text-base font-semibold my-2"
                                                >
                                                    {{
                                                        `${$t('distance_nokm')}
                                                        ${card.distance} km -
                                                        ${$t('duration')}
                                                        ${card.duration}`
                                                    }}
                                                </div>
                                                <div
                                                    v-if="card.isInvoiced"
                                                    class="text-base theme-color mt-2"
                                                >
                                                    <fa-icon
                                                        :title="$t('invoiced')"
                                                        icon="file-invoice-dollar"
                                                        class="hover:font-semibold mr-2"
                                                    ></fa-icon>
                                                    {{
                                                        `${$t('invoiced')} 
                                                            `
                                                    }}
                                                </div>

                                                <div
                                                    v-if="
                                                        card.internal_comment &&
                                                        userStore.isB2C ===
                                                            false
                                                    "
                                                    class="text-base border-t-2 mt-2"
                                                >
                                                    {{
                                                        `${$t(
                                                            'internal_comments',
                                                        )}  : ${
                                                            card.internal_comment
                                                        }`
                                                    }}
                                                </div>
                                                <div
                                                    v-if="card.client_comment"
                                                    class="text-base border-t-2 mt-2"
                                                >
                                                    {{
                                                        `${$t(
                                                            'client_comments',
                                                        )} :  ${
                                                            card.client_comment
                                                        }`
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <hr
                                            class="mx-8 my-2 justify-center flex"
                                        />
                                        <!-- <div
                                            class="w-auto bg-cover bg-no-repeat h-60"
                                            style="
                                                background-image: url('/img/ecran3_map_2.png');
                                            "
                                        > -->
                                        <div
                                            v-if="tabs.length > 1"
                                            class="flex darky bg-white pt-1 px-2 items-center"
                                        >
                                            <ul
                                                v-for="(tab, index) in tabs"
                                                :key="index"
                                                class="flex flex-wrap -mb-px text-sm font-bold text-center text-zinc-400"
                                            >
                                                <li class="mr-2 cursor-pointer">
                                                    <div
                                                        :title="$t(tab)"
                                                        class="p-2 rounded-b border-b-2 border-r-2 tab-highlight group"
                                                        @click.stop="
                                                            tabToShow = tab
                                                        "
                                                        :class="
                                                            tabToShow === tab
                                                                ? 'nav-tab border-b-2 rounded-b  active'
                                                                : ''
                                                        "
                                                    >
                                                        <!-- <fa-con :icon="tab.icon" class="mr-2"></fa-con> -->
                                                        <div
                                                            class="hidden xl:inline nav-tab-text"
                                                        >
                                                            <span>
                                                                {{ $t(tab) }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            v-if="
                                                tabToShow === 'delivery_files'
                                            "
                                        >
                                            <div
                                                class="grid grid-cols-12 gap-2 w-full pb-4"
                                            >
                                                <div
                                                    v-for="(
                                                        file, fileIndex
                                                    ) in card.files"
                                                    :key="fileIndex"
                                                    class="col-span-4 px-2 darky pt-4"
                                                >
                                                    <button
                                                        @click.prevent="
                                                            $emit(
                                                                'download',
                                                                file,
                                                            )
                                                        "
                                                        :title="$t('see_file')"
                                                        class="hover:font-semibold"
                                                    >
                                                        <span
                                                            class="items-center flex custom-link"
                                                        >
                                                            <fa-icon
                                                                icon="eye"
                                                                class="fa-sm mr-2"
                                                            ></fa-icon>
                                                            {{ file.Name }}
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            v-if="tabToShow === 'trackings'"
                                            class="py-4"
                                        >
                                            <table
                                                class="table-auto leading-normal w-full"
                                            >
                                                <thead class="text-xs">
                                                    <tr
                                                        class="darky z-100 text-xsfile:flex flex-col bg-zinc-100 flex-no wrap sm:table-row xs:table-row"
                                                    >
                                                        <th
                                                            class="rounded-tl-lg border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                        >
                                                            {{
                                                                $t(
                                                                    'package_reference',
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                        >
                                                            {{
                                                                $t(
                                                                    'completion_date',
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                        >
                                                            {{
                                                                $t(
                                                                    'tracking_step',
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                        >
                                                            {{ $t('code') }}
                                                        </th>
                                                        <th
                                                            class="border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                        >
                                                            {{
                                                                $t(
                                                                    'description',
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            v-if="
                                                                userStore.isB2C ===
                                                                false
                                                            "
                                                            class="border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                        >
                                                            {{ $t('driver') }}
                                                        </th>
                                                        <th
                                                            class="rounded-tr-lg border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                        >
                                                            {{ $t('address') }}
                                                        </th>
                                                        <th
                                                            class="rounded-tr-lg border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                        >
                                                            {{ $t('map') }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody
                                                    class="flex-1 sm:flex-none"
                                                    v-for="(
                                                        trackings, ind
                                                    ) in card.package.trackings"
                                                    :key="ind"
                                                >
                                                    <tr
                                                        v-for="(
                                                            track, inde
                                                        ) in trackings"
                                                        :key="inde"
                                                        class="darky flex flex-col flex-no wrap sm:table-row xs:table-row"
                                                        :class="
                                                            !isOdd(inde)
                                                                ? 'light-stripe'
                                                                : 'dark-stripe'
                                                        "
                                                        :style="`font-weight: ${
                                                            inde === 0
                                                                ? '600'
                                                                : ''
                                                        }; border-bottom: ${
                                                            inde ===
                                                            trackings.length - 1
                                                                ? '0.1rem solid'
                                                                : ''
                                                        }`"
                                                    >
                                                        <td
                                                            class="py-2 text-xs cursor-copy flex items-center justify-center"
                                                            :title="copyTitle"
                                                            @click.stop="
                                                                copyToClipboard(
                                                                    track.Reference,
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                track.Reference
                                                            }}

                                                            <fa-icon
                                                                icon="copy"
                                                                class="fa-md ml-2 cursor-copy"
                                                            ></fa-icon>
                                                        </td>
                                                        <td
                                                            class="py-2 text-xs"
                                                        >
                                                            {{
                                                                new Date(
                                                                    track.CreationDate,
                                                                ).toLocaleString(
                                                                    $i18n.locale,
                                                                    {
                                                                        year: 'numeric',
                                                                        month: '2-digit',
                                                                        day: '2-digit',
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                    },
                                                                )
                                                            }}
                                                        </td>
                                                        <td
                                                            class="py-2 text-xs"
                                                        >
                                                            {{
                                                                track.StepTracking !==
                                                                'delivery'
                                                                    ? $t(
                                                                          track.StepTracking,
                                                                      )
                                                                    : $t(
                                                                          'delivery_finished',
                                                                      )
                                                            }}
                                                        </td>
                                                        <td
                                                            class="py-2 text-xs"
                                                            :title="copyTitle"
                                                            @click.stop="
                                                                copyToClipboard(
                                                                    track.DeliveryCode,
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                track.DeliveryCode
                                                            }}

                                                            <fa-icon
                                                                v-if="
                                                                    track.DeliveryCode
                                                                "
                                                                icon="copy"
                                                                class="fa-md ml-2 cursor-copy"
                                                            ></fa-icon>
                                                        </td>
                                                        <td
                                                            class="py-2 text-xs"
                                                        >
                                                            {{
                                                                track.Description
                                                            }}
                                                        </td>
                                                        <td
                                                            v-if="
                                                                userStore.isB2C ===
                                                                false
                                                            "
                                                            class="py-2 text-xs"
                                                        >
                                                            {{
                                                                `${track.Driver.FirstName} ${track.Driver.LastName}`
                                                            }}
                                                        </td>
                                                        <td
                                                            class="py-2 text-xs"
                                                        >
                                                            <!-- {{ track.Address }} -->
                                                        </td>
                                                        <td
                                                            class="py-2 text-xs"
                                                        >
                                                            <fa-icon
                                                                :icon="'map'"
                                                                :title="
                                                                    $t('map')
                                                                "
                                                                class="cursor-pointer"
                                                                @click.stop="
                                                                    trackPackage(
                                                                        track.Reference,
                                                                        {
                                                                            pickup: card.pick_up,
                                                                            delivery:
                                                                                card.delivery,
                                                                        },
                                                                    )
                                                                "
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            v-if="
                                                tabToShow ===
                                                'tracking_documents'
                                            "
                                            class="grid grid-cols-6 gap-2 w-full pb-4"
                                        >
                                            <div
                                                class="px-2 pt-4 darky col-span-3 justify-start border rounded my-2"
                                            >
                                                <div
                                                    class="mb-2 font-semibold theme-color"
                                                >
                                                    {{ $t('signatures') }}
                                                </div>
                                                <table
                                                    class="table-auto leading-normal w-full mb-2"
                                                >
                                                    <thead class="text-xs">
                                                        <tr
                                                            class="darky z-100 text-xsfile:flex flex-col bg-zinc-100 flex-no wrap sm:table-row xs:table-row"
                                                        >
                                                            <th
                                                                class="rounded-tl-lg border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'package_reference',
                                                                    )
                                                                }}
                                                            </th>
                                                            <th
                                                                class="border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'completion_date',
                                                                    )
                                                                }}
                                                            </th>
                                                            <th
                                                                class="rounded-tr-lg border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'actions',
                                                                    )
                                                                }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody
                                                        class="flex-1 sm:flex-none"
                                                        v-for="(
                                                            signatures, ind
                                                        ) in card.package
                                                            .signatures"
                                                        :key="ind"
                                                    >
                                                        <tr
                                                            v-for="(
                                                                sign, inde
                                                            ) in signatures"
                                                            :key="inde"
                                                            class="darky flex flex-col flex-no wrap sm:table-row xs:table-row"
                                                            :class="
                                                                !isOdd(inde)
                                                                    ? 'light-stripe'
                                                                    : 'dark-stripe'
                                                            "
                                                            :style="`font-weight: ${
                                                                inde === 0
                                                                    ? '600'
                                                                    : ''
                                                            }; border-bottom: ${
                                                                inde ===
                                                                signatures.length -
                                                                    1
                                                                    ? '0.1rem solid'
                                                                    : ''
                                                            }`"
                                                        >
                                                            <td
                                                                class="py-2 text-xs cursor-copy flex justify-center items-center"
                                                                :title="
                                                                    copyTitle
                                                                "
                                                                @click.stop="
                                                                    copyToClipboard(
                                                                        sign.Reference,
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    sign.Reference
                                                                }}

                                                                <fa-icon
                                                                    icon="copy"
                                                                    class="fa-md ml-2 cursor-copy"
                                                                ></fa-icon>
                                                            </td>
                                                            <td
                                                                class="py-2 text-xs"
                                                            >
                                                                {{
                                                                    new Date(
                                                                        sign.CreationDate,
                                                                    ).toLocaleString(
                                                                        $i18n.locale,
                                                                        {
                                                                            year: 'numeric',
                                                                            month: '2-digit',
                                                                            day: '2-digit',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                        },
                                                                    )
                                                                }}
                                                            </td>
                                                            <td
                                                                class="py-2 text-xs"
                                                            >
                                                                <fa-icon
                                                                    :icon="'eye'"
                                                                    :title="
                                                                        $t(
                                                                            'view_file',
                                                                        )
                                                                    "
                                                                    class="cursor-pointer"
                                                                    @click.stop="
                                                                        viewOrDownloadDocument(
                                                                            false,
                                                                            sign,
                                                                            'signatures',
                                                                        )
                                                                    "
                                                                />
                                                                <fa-icon
                                                                    :icon="'download'"
                                                                    :title="
                                                                        $t(
                                                                            'download',
                                                                        )
                                                                    "
                                                                    class="ml-3 cursor-pointer"
                                                                    @click.stop="
                                                                        viewOrDownloadDocument(
                                                                            true,
                                                                            sign,
                                                                            'signatures',
                                                                        )
                                                                    "
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                class="px-2 pt-4 darky col-span-3 justify-start border rounded my-2"
                                            >
                                                <div
                                                    class="mb-2 font-semibold theme-color"
                                                >
                                                    {{ $t('pictures') }}
                                                </div>

                                                <table
                                                    class="table-auto leading-normal w-full mb-2"
                                                >
                                                    <thead class="text-xs">
                                                        <tr
                                                            class="darky z-100 text-xsfile:flex flex-col bg-zinc-100 flex-no wrap sm:table-row xs:table-row"
                                                        >
                                                            <th
                                                                class="rounded-tl-lg border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'package_reference',
                                                                    )
                                                                }}
                                                            </th>
                                                            <th
                                                                class="border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'completion_date',
                                                                    )
                                                                }}
                                                            </th>
                                                            <th
                                                                class="rounded-tr-lg border-b-2 bg-zinc-100 border-zinc-200 py-2"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'actions',
                                                                    )
                                                                }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody
                                                        class="flex-1 sm:flex-none"
                                                        v-for="(
                                                            pictures, ind
                                                        ) in card.package
                                                            .pictures"
                                                        :key="ind"
                                                    >
                                                        <tr
                                                            v-for="(
                                                                pic, inde
                                                            ) in pictures"
                                                            :key="inde"
                                                            class="darky flex flex-col flex-no wrap sm:table-row xs:table-row"
                                                            :class="
                                                                !isOdd(inde)
                                                                    ? 'light-stripe'
                                                                    : 'dark-stripe'
                                                            "
                                                            :style="`font-weight: ${
                                                                inde === 0
                                                                    ? '600'
                                                                    : ''
                                                            }; border-bottom: ${
                                                                inde ===
                                                                pictures.length -
                                                                    1
                                                                    ? '0.1rem solid'
                                                                    : ''
                                                            }`"
                                                        >
                                                            <!--
                                                         -->
                                                            <td
                                                                class="py-2 text-xs cursor-copy flex items-center justify-center"
                                                                :title="
                                                                    copyTitle
                                                                "
                                                                @click.stop="
                                                                    copyToClipboard(
                                                                        pic.Reference,
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    pic.Reference
                                                                }}

                                                                <fa-icon
                                                                    icon="copy"
                                                                    class="fa-md ml-2"
                                                                ></fa-icon>
                                                            </td>
                                                            <td
                                                                class="py-2 text-xs"
                                                            >
                                                                {{
                                                                    new Date(
                                                                        pic.CreationDate,
                                                                    ).toLocaleString(
                                                                        $i18n.locale,
                                                                        {
                                                                            year: 'numeric',
                                                                            month: '2-digit',
                                                                            day: '2-digit',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                        },
                                                                    )
                                                                }}
                                                            </td>
                                                            <td
                                                                class="py-2 text-xs"
                                                            >
                                                                <fa-icon
                                                                    :icon="'eye'"
                                                                    :title="
                                                                        $t(
                                                                            'view_file',
                                                                        )
                                                                    "
                                                                    class="cursor-pointer"
                                                                    @click.stop="
                                                                        viewOrDownloadDocument(
                                                                            false,
                                                                            pic,
                                                                            'pictures',
                                                                        )
                                                                    "
                                                                />
                                                                <fa-icon
                                                                    :icon="'download'"
                                                                    :title="
                                                                        $t(
                                                                            'download',
                                                                        )
                                                                    "
                                                                    class="ml-3 cursor-pointer"
                                                                    @click.stop="
                                                                        viewOrDownloadDocument(
                                                                            true,
                                                                            pic,
                                                                            'pictures',
                                                                        )
                                                                    "
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div
                                            v-if="
                                                tabToShow === 'detail' &&
                                                userStore.isB2C === false
                                            "
                                            class="grid grid-cols-6 gap-2 w-full pb-4"
                                        >
                                            <div
                                                class="px-2 pt-4 darky col-span-2 justify-start"
                                            >
                                                <div
                                                    class="custom-link font-semibold mt-4"
                                                >
                                                    <fa-layer
                                                        v-if="
                                                            card.pick_up.key ===
                                                            'new'
                                                        "
                                                        :title="
                                                            card.pick_up.status
                                                        "
                                                        class="fa-lg"
                                                    >
                                                        <fa-icon
                                                            icon="ban"
                                                            style="color: grey"
                                                        ></fa-icon>
                                                        <fa-icon
                                                            icon="route"
                                                            transform="shrink-6"
                                                        ></fa-icon>
                                                    </fa-layer>
                                                    <fa-icon
                                                        v-else
                                                        :title="
                                                            card.pick_up.status
                                                        "
                                                        :icon="
                                                            card.pick_up.icon
                                                        "
                                                    ></fa-icon>
                                                    {{ card.pick_up.status }}
                                                    <span class="ml-1"
                                                        >{{
                                                            card.pick_up
                                                                .executedOn !==
                                                            null
                                                                ? new Date(
                                                                      card.pick_up.executedOn,
                                                                  ).toLocaleString(
                                                                      $i18n.locale,
                                                                      {
                                                                          year: 'numeric',
                                                                          month: '2-digit',
                                                                          day: '2-digit',
                                                                          hour: '2-digit',
                                                                          minute: '2-digit',
                                                                      },
                                                                  )
                                                                : ''
                                                        }}
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="card.pick_up.distance"
                                                    class="font-semibold text-xs"
                                                >
                                                    {{
                                                        `${$t(
                                                            'distance_last_step',
                                                        )} : ${
                                                            card.pick_up
                                                                .distance
                                                        } km`
                                                    }}
                                                </div>
                                                <div
                                                    v-if="card.pick_up.duration"
                                                    class="font-semibold text-xs"
                                                >
                                                    {{
                                                        `${$t(
                                                            'duration_last_step',
                                                        )} : ${
                                                            card.pick_up
                                                                .duration
                                                        }`
                                                    }}
                                                </div>
                                                <span
                                                    class="text-base mt-4 italic"
                                                >
                                                    <fa-icon
                                                        icon="house"
                                                        class="fa-md pr-1"
                                                    ></fa-icon>
                                                    {{ card.pick_up.date }}
                                                </span>
                                                <div class="text-sm">
                                                    {{ card.pick_up.moment }}
                                                </div>
                                                <div
                                                    class="text-sm items-center font-semibold mt-2"
                                                    :class="
                                                        card.pick_up.inError ===
                                                        true
                                                            ? 'ko-link'
                                                            : 'ok-link'
                                                    "
                                                >
                                                    {{ card.pick_up.name }}
                                                </div>
                                                <button
                                                    v-if="!card.isInvoiced"
                                                    class="hover:bg-transparent"
                                                    :title="
                                                        $t(
                                                            'step_needs_correction',
                                                        )
                                                    "
                                                    @click="
                                                        $emit(
                                                            'updateStep',
                                                            card.pick_up.id,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="items-center text-sm"
                                                        :class="
                                                            card.pick_up
                                                                .inError ===
                                                            true
                                                                ? 'ko-link'
                                                                : 'ok-link'
                                                        "
                                                    >
                                                        <fa-icon
                                                            v-if="
                                                                card.pick_up
                                                                    .inError ===
                                                                true
                                                            "
                                                            icon="warning"
                                                            class="mr-1"
                                                        />

                                                        {{ card.pick_up.address
                                                        }}<br />

                                                        {{
                                                            card.pick_up.zipCity
                                                        }}
                                                    </span>
                                                </button>

                                                <div
                                                    v-else
                                                    class="items-center text-sm"
                                                    :class="
                                                        card.pick_up.inError ===
                                                        true
                                                            ? 'error-msg'
                                                            : 'success-msg'
                                                    "
                                                >
                                                    <fa-icon
                                                        v-if="
                                                            card.pick_up
                                                                .inError ===
                                                            true
                                                        "
                                                        icon="warning"
                                                        class="mr-1"
                                                    />

                                                    {{ card.pick_up.address
                                                    }}<br />

                                                    {{ card.pick_up.zipCity }}
                                                </div>

                                                <fa-icon
                                                    icon="copy"
                                                    :title="copyTitle"
                                                    @click.stop="
                                                        copyToClipboard(
                                                            `${card.pick_up.address} ${card.pick_up.zipCity}`,
                                                        )
                                                    "
                                                    class="fa-md ml-2 cursor-copy"
                                                ></fa-icon>

                                                <div class="text-base mt-4">
                                                    <span
                                                        v-if="
                                                            card.pick_up
                                                                .driver !== null
                                                        "
                                                    >
                                                        {{
                                                            `${$t('driver')} : 
                                                            ${
                                                                card.pick_up
                                                                    .driver
                                                            }`
                                                        }}
                                                    </span>
                                                    <span v-else class="italic">
                                                        {{
                                                            $t(
                                                                'no_driver_selected',
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="text-base mt-2">
                                                    <span
                                                        v-if="
                                                            card.pick_up
                                                                .vehicle !==
                                                                '' &&
                                                            card.pick_up
                                                                .vehicle !==
                                                                null
                                                        "
                                                    >
                                                        {{
                                                            `${$t(
                                                                'vehicle_type',
                                                            )} : ${
                                                                card.pick_up
                                                                    .vehicle
                                                            }`
                                                        }}
                                                    </span>
                                                    <span v-else class="italic">
                                                        {{
                                                            $t(
                                                                'no_vehicle_type_selected',
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    card.technicals.length === 0
                                                "
                                                class="ml-3 col-span-2 themed-icon darky relative px-2 pt-12 darky"
                                            >
                                                <fa-icon
                                                    :icon="'truck-fast'"
                                                    class="px-1 mr-2 fa-2xl"
                                                ></fa-icon>
                                                <br />

                                                <div
                                                    v-if="
                                                        card.delivery &&
                                                        card.delivery.distance
                                                    "
                                                    class="font-semibold mt-2 text-sm"
                                                >
                                                    {{
                                                        `${$t(
                                                            'distance_nokm',
                                                        )} : ${
                                                            card.delivery
                                                                .distance
                                                        } km`
                                                    }}
                                                </div>
                                                <div
                                                    v-if="
                                                        card.delivery &&
                                                        card.delivery.duration
                                                    "
                                                    class="font-semibold text-sm"
                                                >
                                                    {{
                                                        `${$t('duration')} : ${
                                                            card.delivery
                                                                .duration
                                                        }`
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    card.technicals.length > 0
                                                "
                                                class="ml-3 col-span-2 darky relative px-2 pt-4 darky"
                                            >
                                                <span
                                                    v-for="(
                                                        technical, index
                                                    ) in card.technicals"
                                                    :key="index"
                                                    class="mb-2"
                                                >
                                                    <div
                                                        class="custom-link font-semibold mt-4"
                                                    >
                                                        <fa-layer
                                                            v-if="
                                                                technical.key ===
                                                                'new'
                                                            "
                                                            :title="
                                                                technical.status
                                                            "
                                                            class="fa-lg"
                                                        >
                                                            <fa-icon
                                                                icon="ban"
                                                                style="
                                                                    color: grey;
                                                                "
                                                            ></fa-icon>
                                                            <fa-icon
                                                                icon="route"
                                                                transform="shrink-6"
                                                            ></fa-icon>
                                                        </fa-layer>
                                                        <fa-icon
                                                            v-else
                                                            :title="
                                                                technical.status
                                                            "
                                                            :icon="
                                                                technical.icon
                                                            "
                                                        ></fa-icon>
                                                        {{ technical.status }}
                                                        <span class="ml-1"
                                                            >{{
                                                                technical.executedOn !==
                                                                null
                                                                    ? new Date(
                                                                          technical.executedOn,
                                                                      ).toLocaleString(
                                                                          $i18n.locale,
                                                                          {
                                                                              year: 'numeric',
                                                                              month: '2-digit',
                                                                              day: '2-digit',
                                                                              hour: '2-digit',
                                                                              minute: '2-digit',
                                                                          },
                                                                      )
                                                                    : ''
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        v-if="
                                                            technical.distance
                                                        "
                                                        class="font-semibold text-xs"
                                                    >
                                                        {{
                                                            `${$t(
                                                                'distance_last_step',
                                                            )} : ${
                                                                technical.distance
                                                            } km`
                                                        }}
                                                    </div>
                                                    <div
                                                        v-if="
                                                            technical.duration
                                                        "
                                                        class="font-semibold text-xs"
                                                    >
                                                        {{
                                                            `${$t(
                                                                'duration_last_step',
                                                            )} : ${
                                                                technical.duration
                                                            }`
                                                        }}
                                                    </div>
                                                    <span
                                                        class="text-base mt-4 italic"
                                                    >
                                                        <fa-icon
                                                            v-if="
                                                                index ===
                                                                    card
                                                                        .technicals
                                                                        .length -
                                                                        1 ||
                                                                index === 0
                                                            "
                                                            :icon="
                                                                index ===
                                                                card.technicals
                                                                    .length -
                                                                    1
                                                                    ? 'hourglass-end'
                                                                    : 'hourglass-start'
                                                            "
                                                            class="px-1 mr-2 fa-md"
                                                        ></fa-icon>
                                                        <fa-icon
                                                            :icon="
                                                                technical.type ===
                                                                    'delivery' ||
                                                                technical.type ===
                                                                    'pickup'
                                                                    ? 'house'
                                                                    : index ===
                                                                        card
                                                                            .technicals
                                                                            .length -
                                                                            1
                                                                      ? 'truck-fast'
                                                                      : 'warehouse'
                                                            "
                                                            class="fa-md mr-2"
                                                        ></fa-icon>

                                                        {{ technical.date }}
                                                    </span>

                                                    <div class="text-sm">
                                                        {{ technical.moment }}
                                                    </div>
                                                    <div
                                                        class="text-sm items-center font-semibold mt-2"
                                                        :class="
                                                            technical.inError ===
                                                            true
                                                                ? 'ko-link'
                                                                : 'ok-link'
                                                        "
                                                    >
                                                        {{ technical.name }}
                                                    </div>
                                                    <button
                                                        class="hover:bg-transparent"
                                                        :title="
                                                            $t(
                                                                'step_needs_correction',
                                                            )
                                                        "
                                                        @click="
                                                            $emit(
                                                                'updateStep',
                                                                technical.id,
                                                            )
                                                        "
                                                    >
                                                        <span
                                                            class="items-center text-sm"
                                                            :class="
                                                                technical.inError ===
                                                                true
                                                                    ? 'ko-link'
                                                                    : 'ok-link'
                                                            "
                                                        >
                                                            <fa-icon
                                                                v-if="
                                                                    technical.inError ===
                                                                    true
                                                                "
                                                                icon="warning"
                                                                class="mr-1"
                                                            />

                                                            {{
                                                                technical.address
                                                            }}<br />

                                                            {{
                                                                technical.zipCity
                                                            }}
                                                        </span>
                                                    </button>
                                                    <fa-icon
                                                        icon="copy"
                                                        :title="copyTitle"
                                                        @click.stop="
                                                            copyToClipboard(
                                                                `${technical.address} ${technical.zipCity}`,
                                                            )
                                                        "
                                                        class="fa-md ml-2 cursor-copy"
                                                    ></fa-icon>

                                                    <div class="text-base mt-4">
                                                        <span
                                                            v-if="
                                                                technical.driver !==
                                                                null
                                                            "
                                                        >
                                                            {{
                                                                `${$t(
                                                                    'driver',
                                                                )} : 
                                                            ${technical.driver}`
                                                            }}
                                                        </span>
                                                        <span
                                                            v-else
                                                            class="italic"
                                                        >
                                                            {{
                                                                $t(
                                                                    'no_driver_selected',
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div class="text-base mt-2">
                                                        <span
                                                            v-if="
                                                                technical.vehicle !==
                                                                    '' &&
                                                                technical.vehicle !==
                                                                    null
                                                            "
                                                        >
                                                            {{
                                                                `${$t(
                                                                    'vehicle_type',
                                                                )} : ${
                                                                    technical.vehicle
                                                                }`
                                                            }}
                                                        </span>
                                                        <span
                                                            v-else
                                                            class="italic"
                                                        >
                                                            {{
                                                                $t(
                                                                    'no_vehicle_type_selected',
                                                                )
                                                            }}
                                                        </span>
                                                    </div>
                                                    <hr
                                                        v-if="
                                                            index !==
                                                            card.technicals
                                                                .length -
                                                                1
                                                        "
                                                        class="py-2 mt-2"
                                                    />
                                                </span>
                                            </div>

                                            <div
                                                class="relative px-2 pt-4 darky col-span-2 ml-3 darky"
                                            >
                                                <div
                                                    class="custom-link font-semibold mt-4"
                                                >
                                                    <fa-layer
                                                        v-if="
                                                            card.delivery &&
                                                            card.delivery
                                                                .key === 'new'
                                                        "
                                                        :title="card.delivery"
                                                        class="fa-lg"
                                                    >
                                                        <fa-icon
                                                            icon="ban"
                                                            style="color: grey"
                                                        ></fa-icon>
                                                        <fa-icon
                                                            icon="route"
                                                            transform="shrink-6"
                                                        ></fa-icon>
                                                    </fa-layer>
                                                    <fa-icon
                                                        v-else
                                                        :title="
                                                            card.delivery &&
                                                            card.delivery.status
                                                        "
                                                        :icon="
                                                            card.delivery &&
                                                            card.delivery.icon
                                                        "
                                                    ></fa-icon>
                                                    {{
                                                        card.delivery &&
                                                        card.delivery.status
                                                    }}
                                                    <span class="ml-1"
                                                        >{{
                                                            card.delivery &&
                                                            card.delivery
                                                                .executedOn !==
                                                                null
                                                                ? new Date(
                                                                      card.delivery &&
                                                                          card
                                                                              .delivery
                                                                              .executedOn,
                                                                  ).toLocaleString(
                                                                      $i18n.locale,
                                                                      {
                                                                          year: 'numeric',
                                                                          month: '2-digit',
                                                                          day: '2-digit',
                                                                          hour: '2-digit',
                                                                          minute: '2-digit',
                                                                      },
                                                                  )
                                                                : ''
                                                        }}
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="
                                                        card.delivery &&
                                                        card.delivery
                                                            .distance &&
                                                        card.technicals
                                                            .length !== 0
                                                    "
                                                    class="font-semibold text-xs"
                                                >
                                                    {{
                                                        `${$t(
                                                            'distance_last_step',
                                                        )} : ${
                                                            card.delivery
                                                                .distance
                                                        } km`
                                                    }}
                                                </div>
                                                <div
                                                    v-if="
                                                        card.delivery &&
                                                        card.delivery
                                                            .duration &&
                                                        card.technicals
                                                            .length !== 0
                                                    "
                                                    class="font-semibold text-xs"
                                                >
                                                    {{
                                                        `${$t(
                                                            'duration_last_step',
                                                        )} : ${
                                                            card.delivery
                                                                .duration
                                                        }`
                                                    }}
                                                </div>
                                                <span
                                                    class="text-base mt-4 italic"
                                                >
                                                    <fa-icon
                                                        icon="flag-checkered"
                                                        class="fa-md pr-1"
                                                    ></fa-icon>
                                                    {{
                                                        card.delivery &&
                                                        card.delivery.date
                                                    }}
                                                </span>
                                                <div class="text-sm">
                                                    {{
                                                        card.delivery &&
                                                        card.delivery.moment
                                                    }}
                                                </div>
                                                <div
                                                    class="text-sm items-center font-semibold mt-2"
                                                    :class="
                                                        card.delivery &&
                                                        card.delivery
                                                            .inError === true
                                                            ? 'ko-link'
                                                            : 'ok-link'
                                                    "
                                                >
                                                    {{
                                                        card.delivery &&
                                                        card.delivery.name
                                                    }}
                                                </div>
                                                <button
                                                    v-if="
                                                        !card.isInvoiced &&
                                                        card.delivery
                                                    "
                                                    class="hover:bg-transparent"
                                                    :title="
                                                        $t(
                                                            'step_needs_correction',
                                                        )
                                                    "
                                                    @click="
                                                        $emit(
                                                            'updateStep',
                                                            card.delivery &&
                                                                card.delivery
                                                                    .id,
                                                        )
                                                    "
                                                >
                                                    <span
                                                        class="items-center text-sm"
                                                        :class="
                                                            card.delivery &&
                                                            card.delivery
                                                                .inError ===
                                                                true
                                                                ? 'ko-link'
                                                                : 'ok-link'
                                                        "
                                                    >
                                                        <fa-icon
                                                            v-if="
                                                                card.delivery &&
                                                                card.delivery
                                                                    .inError ===
                                                                    true
                                                            "
                                                            icon="warning"
                                                            class="mr-1"
                                                        />

                                                        {{
                                                            card.delivery
                                                                .address
                                                        }}<br />

                                                        {{
                                                            card.delivery
                                                                .zipCity
                                                        }}
                                                    </span>
                                                </button>
                                                <div
                                                    v-else
                                                    class="items-center text-sm"
                                                    :class="
                                                        card.delivery &&
                                                        card.delivery
                                                            .inError === true
                                                            ? 'ko-link'
                                                            : 'ok-link'
                                                    "
                                                >
                                                    <fa-icon
                                                        v-if="
                                                            card.delivery &&
                                                            card.delivery
                                                                .inError ===
                                                                true
                                                        "
                                                        icon="warning"
                                                        class="mr-1"
                                                    />

                                                    {{
                                                        card.delivery &&
                                                        card.delivery.address
                                                    }}<br />

                                                    {{
                                                        card.delivery &&
                                                        card.delivery.zipCity
                                                    }}
                                                </div>
                                                <fa-icon
                                                    icon="copy"
                                                    :title="copyTitle"
                                                    @click.stop="
                                                        copyToClipboard(
                                                            `${
                                                                card.delivery &&
                                                                card.delivery
                                                                    .address
                                                            } ${
                                                                card.delivery &&
                                                                card.delivery
                                                                    .zipCity
                                                            }`,
                                                        )
                                                    "
                                                    class="fa-md ml-2 cursor-copy"
                                                ></fa-icon>
                                                <div class="text-base mt-4">
                                                    <span
                                                        v-if="
                                                            card.delivery &&
                                                            card.delivery
                                                                .driver !== null
                                                        "
                                                    >
                                                        {{
                                                            `${$t('driver')} : 
                                                            ${
                                                                card.delivery
                                                                    .driver
                                                            }`
                                                        }}
                                                    </span>
                                                    <span v-else class="italic">
                                                        {{
                                                            $t(
                                                                'no_driver_selected',
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                                <div class="text-base mt-2">
                                                    <span
                                                        v-if="
                                                            card.delivery &&
                                                            card.delivery
                                                                .vehicle !==
                                                                '' &&
                                                            card.delivery
                                                                .vehicle !==
                                                                null
                                                        "
                                                    >
                                                        {{
                                                            $t('vehicle_type') +
                                                            ' : ' +
                                                            card.delivery
                                                                .vehicle
                                                        }}
                                                    </span>
                                                    <span v-else class="italic">
                                                        {{
                                                            $t(
                                                                'no_vehicle_type_selected',
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>

                                    <!--  End Profile Card -->
                                </div>
                            </span>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import converter from '@/mixins/converter'
import copyToClipboard from '@/mixins/copyToClipboard.js'
import checkAccess from '@/resources/accessChecker'
import actionsAccess from '@/resources/actionsAccess'
import { useClientStore } from '@/stores/clientStore'
import { useOrderStore } from '@/stores/orderStore'
import { useUserStore } from '@/stores/userStore'
import { mapActions, mapState, mapStores } from 'pinia'

export default {
    name: 'Popover',
    mixins: [copyToClipboard, converter],
    data() {
        return {
            tabToShow: 'detail',
        }
    },
    props: {
        headerText: {
            type: String,
            default: 'Popover header',
        },
        item: {
            type: Object,
            default: () => {},
        },
        mxWidth: {
            type: String,
            default: 'max-w-6xl',
        },
        mxHeight: {
            type: String,
            default: '',
        },
    },
    mounted() {
        if (this.userStore.isB2C === true) {
            this.tabToShow = 'trackings'
        }
    },
    computed: {
        ...mapState(useClientStore, { containers: 'containers' }),
        ...mapState(useOrderStore, {
            order: 'current',
        }),
        ordersActionsAccess() {
            return actionsAccess.orders
        },
        ...mapStores(useUserStore),
        tabs() {
            const realTabs = this.userStore.isB2C === false ? ['detail'] : []
            if (this.card.files.length > 0 && this.userStore.isB2C === false) {
                realTabs.push('delivery_files')
            }

            if (
                this.card.package.trackings.length > 0 &&
                checkAccess(this.userStore, this.ordersActionsAccess.trackings)
            ) {
                realTabs.push('trackings')
            }
            if (
                (this.card.package.signatures.length > 0 ||
                    this.card.package.pictures.length > 0) &&
                checkAccess(this.userStore, this.ordersActionsAccess.signatures)
            ) {
                realTabs.push('tracking_documents')
            }
            return realTabs
        },
        widthAndHeight() {
            return `${this.mxHeight} ${this.mxWidth}`
        },

        card() {
            let card = null
            if (this.item.packages.length > 0) {
                // const container =
                //     this.userStore.isB2C === false
                //         ? this.containers.find(
                //               (el) =>
                //                   +el.id === +this.item.packages[0].IdContainer
                //           )
                //         : {}
                // if (this.userStore.isB2C === true) {
                //     container.name = this.item.packages[0].Container.Name
                // }

                const trackings = []
                const signatures = []
                const pictures = []

                this.item.packages.forEach((pack, index) => {
                    if (pack.Trackings && pack.Trackings.length > 0) {
                        trackings.push(pack.Trackings)
                    }
                    if (pack.Signatures && pack.Signatures.length > 0) {
                        signatures.push(pack.Signatures)
                    }
                    if (pack.Pictures && pack.Pictures.length > 0) {
                        pictures.push(pack.Pictures)
                    }
                })
                let { weight } = this.item
                let { distance } = this.item

                let { duration } = this.item

                //! remove trackings that B2C user must not see (internal...) + add weight dis dur
                if (this.userStore.isB2C === true) {
                    weight = this.item.Weight
                    distance = this.item.OrderClient.Distance
                    duration = this.item.OrderClient.Duration
                    trackings.forEach((tracking, index) => {
                        trackings[index] = tracking.filter(
                            (track) => track.TypeTracking === 'client',
                        )
                        if (trackings[index].length === 0) {
                            trackings.splice(index, 1)
                        }
                    })
                }

                const groupedPacks = []
                this.item.groupedPackages.forEach((pck) => {
                    const temperatureInfos =
                        pck.TemperatureOption === 'warm'
                            ? {
                                  icon: 'temperature-high',
                                  color: 'color:firebrick;',
                                  title: 'hot',
                              }
                            : pck.TemperatureOption === 'fresh'
                              ? {
                                    icon: 'temperature-low',
                                    color: 'color:dodgerblue;',

                                    title: 'cool',
                                }
                              : pck.TemperatureOption === 'frozen'
                                ? {
                                      icon: 'temperature-arrow-down',
                                      color: 'color:cyan;',

                                      title: 'frozen',
                                  }
                                : {
                                      icon: 'temperature-half',
                                      noTemp: true,
                                  }
                    const container =
                        this.userStore.isB2C === false
                            ? this.containers.find(
                                  (el) => +el.id === +pck.IdContainer,
                              )
                            : {}
                    if (this.userStore.isB2C === true) {
                        container.name = pck.Container.Name
                    }
                    const oPack = {
                        container: container
                            ? container.name
                            : this.$t('package_format_not_found'),
                        temperature: temperatureInfos,
                        quantity: pck.Quantity,
                        size:
                            `${pck.Length} cm x ${pck.Width} cm x ${
                                pck.Height
                            } cm ${this.$t('lxwxh')} ` ?? null,
                        weight,
                        worth: `${pck.Value} €` ?? null,
                    }
                    groupedPacks.push(oPack)
                })
                card = {
                    isInvoiced: this.item.isInvoiced,
                    distance: this.formatDistance(distance),
                    duration: this.formatDuration(duration),
                    pick_up: null,
                    recurrent: this.item.recurrent,
                    roundTrip: this.item.roundTrip,
                    files: this.item.files,
                    client_comment: this.item.client_comment,
                    internal_comment: this.item.internal_comment,
                    technicals: [],
                    delivery: null,
                    creation: new Date(this.item.creation_date).toLocaleString(
                        this.$i18n.locale,
                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        },
                    ),
                    modification: new Date(
                        this.item.modification_date,
                    ).toLocaleString(this.$i18n.locale, {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    }),
                    externalId: this.item.externalId,
                    package: {
                        groupedPacks,
                        trackings,
                        signatures,
                        pictures,
                        index: 0,
                        packageId: this.item.packages[0].Id ?? null,
                        reference: this.item.packages[0].Reference ?? null,
                        // size:
                        //     `${this.item.packages[0].Length} cm x ${
                        //         this.item.packages[0].Width
                        //     } cm x ${this.item.packages[0].Height} cm ${this.$t(
                        //         'lxwxh'
                        //     )} ` ?? null,
                        // weight,
                        // worth: `${this.item.packages[0].Value} €` ?? null,
                        // quantity: this.item.packages.length,
                        // container: container
                        //     ? container.name
                        //     : this.$t('package_format_not_found'),

                        // temperature:
                        //     this.item.packages[0].TemperatureOption === 'warm'
                        //         ? {
                        //               icon: 'temperature-high',
                        //               color: 'color:firebrick;',
                        //               title: 'hot',
                        //           }
                        //         : this.item.packages[0].TemperatureOption ===
                        //           'fresh'
                        //         ? {
                        //               icon: 'temperature-low',
                        //               color: 'color:dodgerblue;',

                        //               title: 'cool',
                        //           }
                        //         : this.item.packages[0].TemperatureOption ===
                        //           'frozen'
                        //         ? {
                        //               icon: 'temperature-arrow-down',
                        //               color: 'color:cyan;',

                        //               title: 'frozen',
                        //           }
                        //         : {
                        //               icon: 'temperature-half',
                        //               noTemp: true,
                        //           },
                    },
                }
                const stepsWithDate =
                    this.userStore.isB2C === false
                        ? structuredClone(this.item.steps)
                        : structuredClone(this.item.OrderSteps)
                stepsWithDate.forEach((step, index) => {
                    this.formatcurrentDeliveryDateTimes(step)
                    if (
                        card.pick_up === null &&
                        step.Type === 'pickup' &&
                        step.Step !== 'technical'
                    ) {
                        card.pick_up = {
                            date: new Date(step.stepDate).toLocaleDateString(
                                this.$i18n.locale,
                            ),
                            distance: this.formatDistance(
                                step.DistanceFromLastStep,
                            ),
                            duration: this.formatDuration(
                                step.DurationFromLastStep,
                            ),
                            id: step.Id,
                            pos: {
                                lat: step.Latitude,
                                lng: step.Longitude,
                            },
                            executedOn: step.ExecutedDateTime,
                            status:
                                step.Status !== '' && step.Status !== null
                                    ? this.$t(step.Status)
                                    : this.$t('no_status_found'),

                            key: step.Status,
                            name: step.AddressName,

                            inError:
                                step.Latitude === null ||
                                step.Longitude === null ||
                                step.Latitude === 0 ||
                                step.Longitude === 0 ||
                                step.Latitude === '' ||
                                step.Longitude === '',
                            icon:
                                step.Status === 'inProgress'
                                    ? 'truck-fast'
                                    : step.Status === 'affected'
                                      ? 'route'
                                      : step.Status === 'executed'
                                        ? 'flag-checkered'
                                        : step.Status === 'affectationError'
                                          ? 'xmark'
                                          : step.Status === 'new'
                                            ? 'route'
                                            : 'circle-question',

                            moment: `${
                                step.Moment !== 'between'
                                    ? this.$t(step.Moment)
                                    : ''
                            } ${
                                step.Moment === 'between'
                                    ? `${step.FromDateTime} - ${step.BeforeDateTime}`
                                    : step.Moment === 'before'
                                      ? step.BeforeDateTime
                                      : step.FromDateTime
                            }`,
                            address: `${step.Address1} ${
                                step.Address2 !== 'null' && step.Address2 !== ''
                                    ? step.Address2
                                    : ''
                            }`,
                            zipCity: `${step.PostalCode} ${step.City}`,
                            index,
                            driver: step.Driver
                                ? `${step.Driver.FirstName} ${step.Driver.LastName}`
                                : null,
                            vehicle: step.Vehicle
                                ? `${step.Vehicle.Name} - ${this.$t(
                                      step.Vehicle.Type,
                                  )}`
                                : step.TypeVehicle !== '' &&
                                    step.TypeVehicle !== null
                                  ? this.$t(step.TypeVehicle)
                                  : step.TypeVehicle,
                        }
                    } else if (
                        card.delivery === null &&
                        index === stepsWithDate.length - 1 &&
                        step.Type === 'delivery' &&
                        step.Step !== 'technical'
                    ) {
                        card.delivery = {
                            distance: this.formatDistance(
                                step.DistanceFromLastStep,
                            ),
                            duration: this.formatDuration(
                                step.DurationFromLastStep,
                            ),
                            date: new Date(step.stepDate).toLocaleDateString(
                                this.$i18n.locale,
                            ),
                            pos: {
                                lat: step.Latitude,
                                lng: step.Longitude,
                            },
                            inError:
                                step.Latitude === null ||
                                step.Longitude === null ||
                                step.Latitude === 0 ||
                                step.Longitude === 0 ||
                                step.Latitude === '' ||
                                step.Longitude === '',
                            executedOn: step.ExecutedDateTime,

                            status:
                                step.Status !== '' && step.Status !== null
                                    ? this.$t(step.Status)
                                    : this.$t('no_status_found'),
                            key: step.Status,

                            icon:
                                step.Status === 'inProgress'
                                    ? 'truck-fast'
                                    : step.Status === 'affected'
                                      ? 'route'
                                      : step.Status === 'executed'
                                        ? 'flag-checkered'
                                        : step.Status === 'affectationError'
                                          ? 'xmark'
                                          : step.Status === 'new'
                                            ? 'route'
                                            : 'circle-question',
                            index: index + 1,
                            moment: `${
                                step.Moment !== 'between'
                                    ? this.$t(step.Moment)
                                    : ''
                            } ${
                                step.Moment === 'between'
                                    ? `${step.FromDateTime} - ${step.BeforeDateTime}`
                                    : step.Moment === 'before'
                                      ? step.BeforeDateTime
                                      : step.FromDateTime
                            }`,
                            name: step.AddressName,
                            id: step.Id,
                            address: `${step.Address1} ${
                                step.Address2 !== 'null' && step.Address2 !== ''
                                    ? step.Address2
                                    : ''
                            }`,
                            zipCity: `${step.PostalCode} ${step.City}`,
                            driver: step.Driver
                                ? `${step.Driver.FirstName} ${step.Driver.LastName}`
                                : null,
                            vehicle: step.Vehicle
                                ? `${step.Vehicle.Name} - ${this.$t(
                                      step.Vehicle.Type,
                                  )}`
                                : step.TypeVehicle !== '' &&
                                    step.TypeVehicle !== null
                                  ? this.$t(step.TypeVehicle)
                                  : step.TypeVehicle,
                        }
                    } else {
                        card.technicals.push({
                            distance: this.formatDistance(
                                step.DistanceFromLastStep,
                            ),
                            duration: this.formatDuration(
                                step.DurationFromLastStep,
                            ),
                            date: new Date(step.stepDate).toLocaleDateString(
                                this.$i18n.locale,
                            ),
                            executedOn: step.ExecutedDateTime,
                            type: step.Type,
                            status:
                                step.Status !== '' && step.Status !== null
                                    ? this.$t(step.Status)
                                    : this.$t('no_status_found'),
                            key: step.Status,
                            inError:
                                step.Latitude === null ||
                                step.Longitude === null,
                            id: step.Id,
                            icon:
                                step.Status === 'inProgress'
                                    ? 'truck-fast'
                                    : step.Status === 'affected'
                                      ? 'route'
                                      : step.Status === 'executed'
                                        ? 'flag-checkered'
                                        : step.Status === 'affectationError'
                                          ? 'xmark'
                                          : step.Status === 'new'
                                            ? 'route'
                                            : 'circle-question',
                            moment: `${
                                step.Moment !== 'between'
                                    ? this.$t(step.Moment)
                                    : ''
                            } ${
                                step.Moment === 'between'
                                    ? `${step.FromDateTime} - ${step.BeforeDateTime}`
                                    : step.Moment === 'before'
                                      ? step.BeforeDateTime
                                      : step.FromDateTime
                            }`,
                            address: `${step.Address1} ${
                                step.Address2 !== 'null' && step.Address2 !== ''
                                    ? step.Address2
                                    : ''
                            }`,
                            zipCity: `${step.PostalCode} ${step.City}`,
                            index,
                            driver: step.Driver
                                ? `${step.Driver.FirstName} ${step.Driver.LastName}`
                                : null,
                            vehicle: step.Vehicle
                                ? `${step.Vehicle.Name} - ${this.$t(
                                      step.Vehicle.Type,
                                  )}`
                                : step.TypeVehicle !== '' &&
                                    step.TypeVehicle !== null
                                  ? this.$t(step.TypeVehicle)
                                  : step.TypeVehicle,
                        })
                    }
                })
            }
            return card
        },
    },
    methods: {
        ...mapActions(useOrderStore, ['trackOnePackage']),
        isOdd(num) {
            return num % 2
        },

        formatcurrentDeliveryDateTimes(step) {
            if (step.Moment === 'between') {
                step.stepDate = step.FromDateTime.substring(
                    0,
                    step.FromDateTime.indexOf(' '),
                )
                const stepFromDateTime = step.FromDateTime.substring(
                    step.FromDateTime.indexOf(' ') + 1,
                )
                step.FromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )

                const stepBeforeDateTime = step.BeforeDateTime.substring(
                    step.BeforeDateTime.indexOf(' ') + 1,
                )
                step.BeforeDateTime = stepBeforeDateTime.substring(
                    0,
                    stepBeforeDateTime.length - 3,
                )
            } else if (step.Moment === 'from') {
                step.stepDate = step.FromDateTime.substring(
                    0,
                    step.FromDateTime.indexOf(' '),
                )
                const stepFromDateTime = step.FromDateTime.substring(
                    step.FromDateTime.indexOf(' ') + 1,
                )
                step.FromDateTime = stepFromDateTime.substring(
                    0,
                    stepFromDateTime.length - 3,
                )
                step.BeforeDateTime = ''
            } else if (step.Moment === 'before') {
                step.stepDate = step.BeforeDateTime.substring(
                    0,
                    step.BeforeDateTime.indexOf(' '),
                )

                const stepBeforeDateTime = step.BeforeDateTime.substring(
                    step.BeforeDateTime.indexOf(' ') + 1,
                )
                step.BeforeDateTime = stepBeforeDateTime.substring(
                    0,
                    stepBeforeDateTime.length - 3,
                )
                step.FromDateTime = ''
            }
        },
        trackPackage(packageRef, steps) {
            this.$cookies.remove('currentTrackedPackageSteps')

            this.$cookies.set('currentTrackedPackageSteps', steps)
            this.$emit('packageTracking', packageRef)
        },
        viewOrDownloadDocument(isDownload, file, entity) {
            this.axios
                .get(
                    `/uploads/orderPackages/${entity}/${file.IdOrderPackage}/${file.FileName}`,
                    {
                        responseType: 'blob',
                        headers: {
                            Authorization: `Bearer ${this.$cookies.get(
                                'user_session',
                            )}`,
                        },
                        signal: this.$controller.signal,
                    },
                )
                .then((res) => {
                    const url = URL.createObjectURL(res.data)
                    const link = document.createElement('a')
                    link.href = url
                    if (isDownload === true) {
                        link.setAttribute('download', file.FileName)
                    } else {
                        link.target = '_external'
                    }
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    URL.revokeObjectURL(url)
                })
        },
    },
}
</script>
