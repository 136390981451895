<template>
    <!-- Page Content -->
    <div class="pb-4" :class="smallMT === true ? 'mt-2' : 'mt-14'">
        <div class="-mx-4">
            <div
                :class="
                    isB2C === true || $route.name !== 'orders' ? 'px-4' : 'px-'
                "
            >
                <table class="leading-normal" :class="tableWidth">
                    <thead class="text-xs" :key="refresh">
                        <tr
                            class="darky z-20 text-xs file:flex flex-col bg-zinc-100 flex-no wrap sm:table-row xs:table-row"
                        >
                            <th
                                v-if="
                                    multiActions.length > 0 ||
                                    canSelectItems === true
                                "
                                class="rounded-tl-lg pl-4 stop-stretching border-b-2 bg-zinc-100 border-zinc-200"
                            >
                                <!-- :class="
                                    isB2C === true ||
                                    $route.name !== 'orders'
                                        ? 'px-3'
                                        : ''
                                " -->
                                <div class="flex items-center">
                                    <input
                                        class="themed-checkbox h-4 w-4 border border-gray-300 rounded-sm bg-white focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                        type="checkbox"
                                        value=""
                                        :disabled="expandedRows.length > 0"
                                        v-model="all"
                                        @change="checkAll()"
                                        :id="`check-all`"
                                    />
                                    <dropdown-menu
                                        ref="itemsActionDD"
                                        v-if="canSelectItems === false"
                                        class="inline-flex"
                                        :position="''"
                                        :ellipsisStyle="true"
                                        :items="multiActions"
                                        @picked="multiAction($event)"
                                    >
                                        <template #buttonContent>
                                            <fa-icon
                                                :title="$t('action')"
                                                icon="ellipsis-vertical"
                                                class="fa-lg"
                                                :disabled="!all"
                                            />
                                        </template>
                                    </dropdown-menu>
                                </div>
                            </th>
                            <th
                                v-for="(column, index) in columns"
                                :key="index"
                                :id="
                                    column.hide && column.hide === true
                                        ? `${column.name}-${index}-hidden`
                                        : `${column.name}-${index}`
                                "
                                class="py-1 border-b-2 border-zinc-200 bg-zinc-100 font-semibold text-zinc-500 tracking-wider darky"
                                :class="
                                    column.hide && column.hide === true
                                        ? 'hidden'
                                        : (multiActions.length > 0 ||
                                                canSelectItems === true ||
                                                isNotFirstColumn(index)) &&
                                            (isB2C === true ||
                                                $route.name !== 'orders')
                                          ? 'px-3 sm:px-0'
                                          : isB2C === true ||
                                              $route.name !== 'orders'
                                            ? 'px-3 sm:px-0 rounded-tl-lg'
                                            : multiActions.length > 0 ||
                                                canSelectItems === true ||
                                                isNotFirstColumn(index)
                                              ? ''
                                              : 'rounded-tl-lg'
                                "
                            >
                                <span
                                    v-if="!column.hide || column.hide === false"
                                >
                                    <span v-if="column.filtrable">
                                        <span
                                            class="flex items-center justify-center"
                                            :class="
                                                multiActions.length == 0 &&
                                                !isNotFirstColumn(index)
                                                    ? 'ml-1'
                                                    : ''
                                            "
                                        >
                                            <div class="relative">
                                                <div
                                                    class="absolute inset-y-0 right-0 flex items-center px-2"
                                                >
                                                    <sort-icon
                                                        v-if="column.sortable"
                                                        :storeToSort="
                                                            storeToSort
                                                        "
                                                        :noStyle="true"
                                                        :iconStyle="'text-zinc-500 pt-2'"
                                                        :sortDirection="
                                                            sortDirection
                                                        "
                                                        :sortField="
                                                            column.dbField
                                                        "
                                                        :items="items"
                                                        :title="$t('order')"
                                                        @sortChanged="
                                                            $emit(
                                                                'sortDirectionChanged',
                                                                $event,
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <search-input
                                                    v-if="
                                                        column.type === 'search'
                                                    "
                                                    :key="refresh"
                                                    :searchBy="
                                                        column.showRef &&
                                                        column.showRef === true
                                                            ? 'operator_ref'
                                                            : column.name.includes(
                                                                    'name',
                                                                )
                                                              ? 'search_name'
                                                              : column.name
                                                    "
                                                    :dbField="column.dbField"
                                                    :searchQuery="
                                                        column.term
                                                            ? column.term
                                                            : searchQuery
                                                    "
                                                    :small="false"
                                                    :searchIcon="
                                                        column.searchIcon
                                                            ? column.searchIcon
                                                            : 'magnifying-glass'
                                                    "
                                                    @changeSearch="
                                                        $emit('changeSearch')
                                                    "
                                                    @searched="
                                                        $emit(
                                                            'searched',
                                                            $event,
                                                        )
                                                    "
                                                >
                                                </search-input>
                                            </div>
                                        </span>
                                        <select-many
                                            v-if="
                                                column.type === 'select' &&
                                                column.multi &&
                                                column.multi === true
                                            "
                                            id="sort-select-multi"
                                            :elemType="column.name"
                                            :sortableFields="column.options"
                                            :storeToSort="storeToSort"
                                            :selected="column.selected"
                                            :withLabel="false"
                                            :fieldType="column.name"
                                            :multi="true"
                                            :searchedItems="items"
                                            @clear="$emit('clear', $event)"
                                            @selected="
                                                $emit('selected', $event)
                                            "
                                        ></select-many>
                                        <select-one
                                            v-else-if="column.type === 'select'"
                                            :class="
                                                column.width ? column.width : ''
                                            "
                                            :id="`sort-select-one`"
                                            :customWidth="
                                                $route.name === 'orders'
                                                    ? 'custom-width'
                                                    : ''
                                            "
                                            :optionAlreadyTranslated="
                                                column.optionAlreadyTranslated &&
                                                column.optionAlreadyTranslated ===
                                                    true
                                                    ? true
                                                    : false
                                            "
                                            :placeholderText="column.name"
                                            :key="refresh"
                                            :sortField="column.options[0]"
                                            :canSearch="
                                                column.canSearch
                                                    ? column.canSearch
                                                    : false
                                            "
                                            :sortableFields="column"
                                            :storeToSort="storeToSort"
                                            :selected="column.selected ?? null"
                                            :withLabel="false"
                                            :searchedItems="items"
                                            :allowEmpty="
                                                column.canBeEmpty &&
                                                column.canBeEmpty === true
                                            "
                                            :canBeCleared="
                                                column.canBeCleared &&
                                                column.canBeCleared === true
                                            "
                                            @clear="$emit('clear', $event)"
                                            @selected="
                                                $emit('selected', $event)
                                            "
                                        ></select-one>
                                        <!-- :key="refresh" -->
                                        <icon-select
                                            v-if="
                                                column.type === 'icon-select' &&
                                                !column.multi
                                            "
                                            id="sort-select-icon"
                                            :sortField="column.options[0]"
                                            :sortableFields="column"
                                            :storeToSort="storeToSort"
                                            :selected="column.selected ?? []"
                                            :withLabel="false"
                                            :fieldType="column.name"
                                            :searchedItems="items"
                                            @clear="$emit('clear', $event)"
                                            @selected="
                                                $emit('selected', $event)
                                            "
                                            @disableOthers="
                                                disableOthers($event)
                                            "
                                        ></icon-select>
                                        <icon-select-multiple
                                            v-else-if="
                                                column.type === 'icon-select' &&
                                                column.multi &&
                                                column.multi === true
                                            "
                                            id="sort-select-multi-icon"
                                            :elemType="column.name"
                                            :sortableFields="column.options"
                                            :storeToSort="storeToSort"
                                            :selected="column.selected"
                                            :withLabel="false"
                                            :fieldType="column.name"
                                            :multi="true"
                                            :searchedItems="items"
                                            @clear="$emit('clear', $event)"
                                            @selected="
                                                $emit('selected', $event)
                                            "
                                        ></icon-select-multiple>
                                        <!-- :sortField="sortField" -->
                                    </span>

                                    <span v-else>
                                        <!-- :class="
                                            column.sortable
                                                ? 'flex items-center md:whitespace-nowrap'
                                                : ''
                                        " -->
                                        <div
                                            class="flex items-center"
                                            :class="
                                                column.notCentered &&
                                                column.notCentered === true
                                                    ? 'pl-2'
                                                    : ' justify-center'
                                            "
                                        >
                                            <span
                                                class="text-xs items-center flex"
                                                :class="
                                                    column.withButton
                                                        ? 'border-2 rounded px-1 py-1'
                                                        : ''
                                                "
                                            >
                                                <span
                                                    :class="
                                                        $route.name ===
                                                            'invoicings' ||
                                                        $route.name ===
                                                            'orders' ||
                                                        $route.name ===
                                                            'unpaid_orders'
                                                            ? 'items-center flex'
                                                            : ' text-xs'
                                                    "
                                                >
                                                    {{
                                                        $t(
                                                            column.range &&
                                                                column.name ===
                                                                    'pick_up'
                                                                ? 'pick_up_between'
                                                                : column.range &&
                                                                    column.name ===
                                                                        'delivery'
                                                                  ? 'delivery_and'
                                                                  : column.name,
                                                        )
                                                    }}
                                                    <span
                                                        v-if="
                                                            column.datePicker &&
                                                            column.datePicker ===
                                                                true
                                                        "
                                                        class="ml-1"
                                                    >
                                                        <span
                                                            class="items-center flex"
                                                        >
                                                            <input
                                                                :id="`${column.name}-time`"
                                                                type="date"
                                                                v-model="
                                                                    pickedDate[
                                                                        column
                                                                            .dbField
                                                                    ]
                                                                "
                                                                @change="
                                                                    $emit(
                                                                        'dateFilterUpdate',
                                                                        {
                                                                            dbField:
                                                                                column.dbField,
                                                                            value: pickedDate[
                                                                                column
                                                                                    .dbField
                                                                            ],
                                                                        },
                                                                    )
                                                                "
                                                                class="border rounded px-3 py-2 w-full"
                                                            />
                                                            <!-- <label
                                                                :for="`${column.name}-checkbox`"
                                                                class="ml-2 text-xs cursor-pointer font-medium"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'show_today_only'
                                                                    )
                                                                }}
                                                            </label> -->
                                                        </span>
                                                    </span>
                                                    <span
                                                        v-if="
                                                            column.withButton &&
                                                            column.withButton ===
                                                                true
                                                        "
                                                        class="ml-2"
                                                    >
                                                        <span
                                                            class="items-center flex mr-1"
                                                        >
                                                            <input
                                                                :id="`${column.name}-checkbox`"
                                                                type="checkbox"
                                                                v-model="
                                                                    column.buttonValue
                                                                "
                                                                @click.stop="
                                                                    $emit(
                                                                        'checkboxFilterUpdate',
                                                                        column.dbField,
                                                                    ) &
                                                                        resetDatePicker(
                                                                            {
                                                                                value: column.buttonValue,
                                                                                name: column.dbField,
                                                                            },
                                                                        )
                                                                "
                                                                class="themed-checkbox cursor-pointer w-3 h-3 bg-gray-100 rounded border-gray-300"
                                                            />
                                                            <label
                                                                :for="`${column.name}-checkbox`"
                                                                class="text-xs cursor-pointer whitespace-nowrap ml-1 font-medium"
                                                            >
                                                                {{
                                                                    $t(
                                                                        'show_today_only',
                                                                    )
                                                                }}
                                                            </label>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <sort-icon
                                                v-if="column.sortable"
                                                :storeToSort="storeToSort"
                                                :sortDirection="sortDirection"
                                                :items="items"
                                                :noStyle="true"
                                                :sortField="column.dbField"
                                                :iconStyle="'text-zinc-500 pt-1'"
                                                :title="$t('order')"
                                                @sortChanged="
                                                    $emit(
                                                        'sortDirectionChanged',
                                                        $event,
                                                    )
                                                "
                                            />
                                        </div>
                                    </span>
                                </span>
                            </th>

                            <th
                                class="z-10 w-0 py-1 border-b-2 stop-stretching border-zinc-200 bg-zinc-100 font-semibold text-zinc-500 rounded-tr-lg"
                            >
                                <icon-button
                                    v-if="canAddNew"
                                    icon="plus"
                                    :noStyle="true"
                                    :iconStyle="'fa-xl cursor-pointer'"
                                    :title="$t('new_item')"
                                    @click.prevent="$emit('create')"
                                ></icon-button>
                            </th>
                        </tr>
                    </thead>

                    <tbody
                        v-if="count > 0"
                        :key="refresh"
                        class="flex-1 sm:flex-none"
                    >
                        <template v-for="(item, index) in items" :key="index">
                            <tr
                                class="darky flex flex-col flex-no wrap sm:table-row xs:table-row"
                                :class="
                                    !isOdd(index)
                                        ? 'light-stripe'
                                        : 'dark-stripe'
                                "
                            >
                                <td
                                    :class="
                                        multiActions.length === 0 &&
                                        canSelectItems === false
                                            ? 'hidden'
                                            : ''
                                    "
                                    class="px-4 text-xs"
                                >
                                    <input
                                        v-if="
                                            !item.isInvoiced &&
                                            ((item.id &&
                                                item.currentStepsToDisplay &&
                                                item.currentStepsToDisplay
                                                    .length &&
                                                item.currentStepsToDisplay[0]
                                                    .Status === 'new') ||
                                                (item.id &&
                                                    $route.name !== 'orders' &&
                                                    $route.name !==
                                                        'my_cart') ||
                                                (item.id &&
                                                    ($route.name === 'orders' ||
                                                        $route.name ===
                                                            'my_cart') &&
                                                    isB2C === true &&
                                                    (item.status ===
                                                        'waitingforpayment' ||
                                                        item.status ===
                                                            'waiting')))
                                        "
                                        class="themed-checkbox h-4 w-4 border border-gray-300 rounded-sm focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                        type="checkbox"
                                        :disabled="expandedRows.length > 0"
                                        :value="item.id"
                                        :id="`check-${item.id}`"
                                        v-model="checkedItems"
                                        @click="check(item.id)"
                                    />

                                    <span
                                        v-if="!item.id"
                                        :id="`planned_rec_${index}`"
                                        class="fa-fw mr-1"
                                        :title="$t('planned')"
                                    >
                                        <fa-layer>
                                            <fa-icon icon="repeat"></fa-icon>

                                            <!-- <fa-icon
                                            style="font-weight: 200"
                                            transform="shrink-6 up-10 right-14"
                                            icon="calendar"
                                        ></fa-icon> -->
                                            <!-- transform="shrink-6" -->
                                        </fa-layer>
                                    </span>

                                    <!-- @change="check(item.id)" -->
                                </td>
                                <td
                                    v-for="(column, i) in columns"
                                    :key="i"
                                    class="border-b border-zinc-200"
                                    :class="
                                        column.hide && column.hide === true
                                            ? 'hidden'
                                            : column.json
                                              ? 'stop-stretching-json text-xs'
                                              : column.withIconNoText &&
                                                  column.withIconNoText ===
                                                      true &&
                                                  isB2C === false
                                                ? 'stop-stretching w-2 text-xs'
                                                : column.withIconNoText &&
                                                    column.withIconNoText ===
                                                        true &&
                                                    (isB2C === true ||
                                                        $route.name !==
                                                            'orders')
                                                  ? 'stop-stretching py-4 w-4 text-xs'
                                                  : column.withIconNoText &&
                                                      column.withIconNoText ===
                                                          true &&
                                                      (isB2C === true ||
                                                          $route.name !==
                                                              'orders')
                                                    ? 'py-4 w-4 text-xs'
                                                    : isB2C === true &&
                                                        column.packages
                                                      ? 'w-fit pt-4 text-sm'
                                                      : 'text-xs'
                                    "
                                >
                                    <span
                                        v-if="
                                            !column.hide ||
                                            column.hide === false
                                        "
                                    >
                                        <span
                                            v-if="
                                                column.name ===
                                                'distance_duration'
                                            "
                                            class="items-center flex justify-center"
                                        >
                                            <span
                                                v-if="
                                                    item.distance &&
                                                    item.duration &&
                                                    item.distance > 0
                                                "
                                            >
                                                {{
                                                    formatDistance(
                                                        item.distance,
                                                    ) +
                                                    ' km / ' +
                                                    formatDuration(
                                                        item.duration,
                                                    )
                                                }}
                                            </span>
                                            <span
                                                v-else-if="
                                                    item.distance &&
                                                    item.distance > 0
                                                "
                                            >
                                                {{
                                                    formatDistance(
                                                        item.distance,
                                                    ) +
                                                    ' km / ' +
                                                    $t('no_data_available')
                                                }}
                                            </span>
                                            <span v-else-if="item.duration">
                                                {{
                                                    $t('no_data_available') +
                                                    ' / ' +
                                                    formatDuration(
                                                        item.duration,
                                                    )
                                                }}
                                            </span>
                                            <span v-else>
                                                {{ $t('no_data_available') }}
                                            </span>
                                        </span>
                                        <span
                                            v-if="column.name === 'ocs'"
                                            class="items-center grid grid-cols-4"
                                        >
                                            <span
                                                v-for="(
                                                    toRefund, iToRefund
                                                ) in item.ocs"
                                                :key="iToRefund"
                                                class="col-span-4"
                                            >
                                                <span
                                                    v-for="(
                                                        pack, indPack
                                                    ) in toRefund.packs"
                                                    :key="indPack"
                                                    class="my-2"
                                                >
                                                    <div
                                                        class="flex items-center text-xs justify-center mt-2"
                                                    >
                                                        <fa-icon
                                                            icon="box"
                                                            class="fa-sm mr-2"
                                                        ></fa-icon>
                                                        {{ pack.quantity }}

                                                        {{ $t(pack.container) }}
                                                        PRIX
                                                    </div>
                                                </span>
                                                <div
                                                    v-if="
                                                        toRefund.driver !== null
                                                    "
                                                    class="flex error-msg items-center text-xs justify-center italic mt-2"
                                                >
                                                    {{
                                                        `${$t('driver')} ${
                                                            toRefund.driver
                                                        }`
                                                    }}
                                                </div>
                                                <div
                                                    v-else
                                                    class="flex items-center text-xs justify-center italic mt-2"
                                                >
                                                    {{ $tc('not_affected', 2) }}
                                                </div>
                                                <div
                                                    class="flex items-center text-xs justify-center theme-color font-semibold"
                                                >
                                                    {{ $t(toRefund.status) }}
                                                </div>
                                                <div
                                                    class="items-center text-xs justify-center grid grid-cols-2 mb-2"
                                                >
                                                    <span class="col-span-1">
                                                        {{ toRefund.pickUp.date
                                                        }}<br />
                                                        {{
                                                            toRefund.pickUp
                                                                .address
                                                        }}<br />
                                                        {{
                                                            toRefund.pickUp
                                                                .zipCity
                                                        }}
                                                    </span>
                                                    <span class="col-span-1">
                                                        {{
                                                            toRefund.delivery
                                                                .date
                                                        }}<br />
                                                        {{
                                                            toRefund.delivery
                                                                .address
                                                        }}<br />
                                                        {{
                                                            toRefund.delivery
                                                                .zipCity
                                                        }}
                                                    </span>
                                                </div>
                                            </span>
                                        </span>
                                        <span
                                            v-else-if="
                                                column.recurrent &&
                                                column.name ===
                                                    'additional_info'
                                            "
                                            class="text-xs mr-1 whitespace-nowrap flex justify-center items-center"
                                        >
                                            <span
                                                :id="`sub-icon-round-${item.id}`"
                                                :title="$t('round_trip')"
                                                class="fa-fw"
                                                v-if="item.roundTrip === true"
                                            >
                                                <fa-layer>
                                                    <fa-icon
                                                        :icon="'route'"
                                                    ></fa-icon>

                                                    <fa-icon
                                                        style="font-weight: 200"
                                                        transform="shrink-6 up-10 right-14"
                                                        :icon="'rotate'"
                                                    ></fa-icon>
                                                </fa-layer>
                                                <span
                                                    class="ml-2 font-extrabold"
                                                >
                                                    |
                                                </span>
                                            </span>
                                            <span
                                                v-for="(
                                                    groupedPack, groupedIndex
                                                ) in item.groupedPackages"
                                                :key="groupedIndex"
                                            >
                                                <fa-layer
                                                    :title="
                                                        $t('no_temp_specified')
                                                    "
                                                    v-if="
                                                        groupedPack.TemperatureOption ===
                                                            'none' ||
                                                        groupedPack.TemperatureOption ===
                                                            null
                                                    "
                                                    class="fa-xl mr-1"
                                                >
                                                    <fa-icon
                                                        icon="ban"
                                                        style="color: grey"
                                                    ></fa-icon>
                                                    <fa-icon
                                                        icon="temperature-half"
                                                        transform="shrink-6"
                                                    ></fa-icon>
                                                </fa-layer>

                                                <span
                                                    v-else
                                                    class="mr-1"
                                                    :style="
                                                        groupedPack.TemperatureOption ===
                                                        'fresh'
                                                            ? 'color:dodgerblue;'
                                                            : groupedPack.TemperatureOption ===
                                                                'frozen'
                                                              ? 'color:cyan;'
                                                              : 'color:firebrick;'
                                                    "
                                                >
                                                    <fa-icon
                                                        :title="
                                                            $t(
                                                                groupedPack.TemperatureOption ===
                                                                    'fresh'
                                                                    ? 'cool'
                                                                    : item
                                                                            .packages[0]
                                                                            .TemperatureOption ===
                                                                        'frozen'
                                                                      ? 'frozen'
                                                                      : 'hot',
                                                            )
                                                        "
                                                        :icon="
                                                            groupedPack.TemperatureOption ===
                                                            'fresh'
                                                                ? 'temperature-low'
                                                                : groupedPack.TemperatureOption ===
                                                                    'frozen'
                                                                  ? 'temperature-arrow-down'
                                                                  : 'temperature-high'
                                                        "
                                                        class="fa-lg"
                                                    ></fa-icon>
                                                </span>
                                                <span class="mr-1">
                                                    {{
                                                        `${groupedPack.Quantity} ${
                                                            groupedIndex !==
                                                            item.groupedPackages
                                                                .length -
                                                                1
                                                                ? ' • '
                                                                : ''
                                                        }
                                                     `
                                                    }}
                                                </span>
                                            </span>
                                        </span>
                                        <!-- WIP display packages for extranet -->

                                        <span
                                            v-else-if="
                                                column.packages &&
                                                column.name == 'packages'
                                            "
                                            class="grid grid-cols-2 gap-x-14"
                                        >
                                            <span
                                                v-for="(
                                                    order, indKey
                                                ) in item.orderClient"
                                                :key="indKey"
                                                class="mb-2 items-center justify-center col-span-1"
                                            >
                                                <span
                                                    v-for="(
                                                        pack, index
                                                    ) in setDeliveryPackages(
                                                        order.Orders,
                                                        order.groupedPackages,
                                                    )"
                                                    :key="index"
                                                    class=""
                                                >
                                                    <div class="mb-2 text-xs">
                                                        <span class="mr-2">
                                                            <span
                                                                :id="`sub-icon-recurrent-${order.id}`"
                                                                :title="
                                                                    $t(
                                                                        'recurrent',
                                                                    )
                                                                "
                                                                class="fa-fw"
                                                                v-if="
                                                                    +order.Recurrent ===
                                                                    1
                                                                "
                                                            >
                                                                <fa-layer>
                                                                    <fa-icon
                                                                        :icon="'cart-shopping'"
                                                                    ></fa-icon>

                                                                    <fa-icon
                                                                        style="
                                                                            font-weight: 200;
                                                                        "
                                                                        transform="shrink-6 up-10 right-14"
                                                                        :icon="'repeat'"
                                                                    ></fa-icon>
                                                                    <!-- transform="shrink-6" -->
                                                                </fa-layer>
                                                                <span
                                                                    class="ml-2"
                                                                >
                                                                    {{
                                                                        $t(
                                                                            'recurrent',
                                                                        )
                                                                    }}
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span
                                                            :id="`sub-icon-round-trip-${order.id}`"
                                                            :title="
                                                                $t('round_trip')
                                                            "
                                                            class="fa-fw"
                                                            v-if="
                                                                +order.RoundTrip ===
                                                                1
                                                            "
                                                        >
                                                            <fa-layer>
                                                                <fa-icon
                                                                    :icon="'route'"
                                                                ></fa-icon>

                                                                <fa-icon
                                                                    style="
                                                                        font-weight: 200;
                                                                    "
                                                                    transform="shrink-6 up-10 right-14"
                                                                    :icon="'rotate'"
                                                                ></fa-icon>
                                                                <!-- transform="shrink-6" -->
                                                            </fa-layer>
                                                            <span class="ml-2">
                                                                {{
                                                                    $t(
                                                                        'round_trip',
                                                                    )
                                                                }}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <span
                                                        class="font-semibold text-base"
                                                    >
                                                        <fa-icon
                                                            :title="
                                                                orderStatus(
                                                                    order,
                                                                ).title
                                                            "
                                                            :class="
                                                                orderStatus(
                                                                    order,
                                                                ).color
                                                            "
                                                            :icon="
                                                                orderStatus(
                                                                    order,
                                                                ).icon
                                                            "
                                                            class="fa-lg mr-2"
                                                        ></fa-icon>
                                                        {{
                                                            `${
                                                                orderStatus(
                                                                    order,
                                                                ).status
                                                            }`
                                                        }}
                                                        <fa-icon
                                                            v-if="
                                                                ocIsInvoiced(
                                                                    order
                                                                        .Orders[0],
                                                                )
                                                            "
                                                            icon="file-invoice-dollar"
                                                            class="hover:font-semibold fa-lg mx-2 text-gray-400"
                                                            @mouseenter="
                                                                showTooltip(
                                                                    'tooltip-right-invoiced' +
                                                                        order.id,
                                                                    true,
                                                                )
                                                            "
                                                            @mouseleave="
                                                                showTooltip(
                                                                    'tooltip-right-invoiced' +
                                                                        order.id,
                                                                    false,
                                                                )
                                                            "
                                                        ></fa-icon>
                                                        <span
                                                            :id="
                                                                'tooltip-right-invoiced' +
                                                                order.id
                                                            "
                                                            role="tooltip"
                                                            class="absolute invisible w-32 mb-3 z-10 inline-block px-2 py-2 text-sm font-medium text-white bg-gray-900 rounded shadow-sm ml-6 tooltip dark:bg-gray-700"
                                                        >
                                                            {{
                                                                $t(
                                                                    'invoice_available',
                                                                )
                                                            }}
                                                        </span>
                                                        <button
                                                            v-if="
                                                                (pack.trackings
                                                                    .length >
                                                                    0 ||
                                                                    pack
                                                                        .pictures
                                                                        .length >
                                                                        0 ||
                                                                    pack
                                                                        .signatures
                                                                        .length >
                                                                        0) &&
                                                                orderStatus(
                                                                    order,
                                                                ).real !==
                                                                    'waitingforpayment'
                                                            "
                                                            @click.prevent="
                                                                showPopover(
                                                                    order.Id,
                                                                )
                                                            "
                                                            class="inline-block cursor-help px-2 text-zinc font-medium text-xs leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                                                        >
                                                            <div
                                                                class="py-0.5 relative w-fit"
                                                            >
                                                                <fa-icon
                                                                    :title="
                                                                        $t(
                                                                            'trackings_and_proofs',
                                                                        )
                                                                    "
                                                                    icon="eye"
                                                                    class="fa-lg"
                                                                />
                                                            </div>
                                                        </button>

                                                        <div
                                                            class="text-xs mb-1 font-normal text-zinc-500"
                                                            v-else-if="
                                                                orderStatus(
                                                                    order,
                                                                ).real !==
                                                                'waitingforpayment'
                                                            "
                                                        >
                                                            {{
                                                                $t(
                                                                    'no_tracking_data_available',
                                                                )
                                                            }}
                                                        </div>
                                                    </span>
                                                    <div
                                                        class="whitespace-nowrap my-1"
                                                    >
                                                        <span
                                                            :style="
                                                                methodStyle(
                                                                    pack.method,
                                                                )
                                                            "
                                                        >
                                                            <fa-icon
                                                                :icon="
                                                                    pack.method ===
                                                                    'express'
                                                                        ? 'rocket'
                                                                        : pack.method ===
                                                                            'normal'
                                                                          ? 'gauge'
                                                                          : 'gauge-high'
                                                                "
                                                                :title="
                                                                    $t(
                                                                        pack.method ===
                                                                            'express'
                                                                            ? 'express'
                                                                            : pack.method ===
                                                                                'normal'
                                                                              ? 'normal'
                                                                              : 'fast',
                                                                    )
                                                                "
                                                                class="fa-md mr-3"
                                                            ></fa-icon>
                                                        </span>
                                                        <span class="text-xs">
                                                            {{
                                                                `${$t(
                                                                    'distance_nokm',
                                                                )}
                                                        ${pack.distance} km -
                                                        ${$t('duration')}
                                                        ${pack.duration}`
                                                            }}
                                                        </span>
                                                    </div>

                                                    <popover
                                                        v-if="
                                                            showPop === order.Id
                                                        "
                                                        :item="
                                                            setPopoverData(
                                                                order,
                                                            )
                                                        "
                                                        :headerText="
                                                            order.Reference
                                                        "
                                                        :id="`popoverB2C-${order.Id}`"
                                                        @packageTracking="
                                                            $emit(
                                                                'packageTracking',
                                                                $event,
                                                            )
                                                        "
                                                        @closePop="
                                                            showPopover(
                                                                item.id,
                                                                true,
                                                            )
                                                        "
                                                    ></popover>
                                                    <div class="italic">
                                                        {{
                                                            `${formattedPickUpStepDate(
                                                                order.PickupFromDateTime,
                                                            )} `
                                                        }}
                                                    </div>
                                                    <span
                                                        v-for="(
                                                            fPack, indpck
                                                        ) in pack.groupedPacks"
                                                        :key="indpck"
                                                    >
                                                        <div
                                                            class="whitespace-nowrap"
                                                        >
                                                            <fa-layer
                                                                :title="
                                                                    $t(
                                                                        'no_temp_specified',
                                                                    )
                                                                "
                                                                v-if="
                                                                    fPack
                                                                        .temperature
                                                                        .noTemp
                                                                "
                                                                class="fa-lg mr-1"
                                                            >
                                                                <fa-icon
                                                                    icon="ban"
                                                                    style="
                                                                        color: grey;
                                                                    "
                                                                ></fa-icon>
                                                                <fa-icon
                                                                    :icon="
                                                                        fPack
                                                                            .temperature
                                                                            .icon
                                                                    "
                                                                    transform="shrink-6"
                                                                ></fa-icon>
                                                            </fa-layer>
                                                            <span
                                                                v-else
                                                                class="mr-1"
                                                                :style="`${fPack.temperature.color}`"
                                                            >
                                                                <fa-icon
                                                                    :title="
                                                                        $t(
                                                                            fPack
                                                                                .temperature
                                                                                .title,
                                                                        )
                                                                    "
                                                                    :icon="
                                                                        fPack
                                                                            .temperature
                                                                            .icon
                                                                    "
                                                                    class="fa-md"
                                                                ></fa-icon>
                                                            </span>
                                                            <span
                                                                v-if="
                                                                    fPack.name.includes(
                                                                        '_',
                                                                    ) ===
                                                                        true ||
                                                                    fPack.name ===
                                                                        'pallet' ||
                                                                    fPack.name ===
                                                                        'envelope'
                                                                "
                                                                class="whitespace-nowrap"
                                                            >
                                                                {{
                                                                    `${
                                                                        fPack.quantity
                                                                    } ${$tc(
                                                                        fPack.name,
                                                                        +fPack.quantity ===
                                                                            1
                                                                            ? 1
                                                                            : 2,
                                                                    )}`
                                                                }}
                                                            </span>
                                                            <span
                                                                v-else
                                                                class="whitespace-nowrap"
                                                            >
                                                                {{
                                                                    `${fPack.quantity} ${fPack.name}`
                                                                }}
                                                            </span>
                                                        </div>
                                                    </span>

                                                    <span
                                                        class="items-center flex justify-center mt-1"
                                                        v-if="
                                                            pack.client_comments
                                                        "
                                                    >
                                                        {{
                                                            `${$t('comments')} :  ${
                                                                pack.client_comments
                                                            }`
                                                        }}
                                                    </span>
                                                    <div
                                                        class="whitespace-nowrap my-1"
                                                    >
                                                        <span class="text-xs">
                                                            {{
                                                                `${$t('demo_price')}
                                                        ${order.Price} ${$t(
                                                            'demo_price_info',
                                                        )}
                                                    `
                                                            }}
                                                        </span>
                                                    </div>
                                                    <span
                                                        class="custom-link items-center flex justify-center mt-1 cursor-copy"
                                                        :title="copyTitle"
                                                        @click.prevent="
                                                            copyToClipboard(
                                                                order.Reference,
                                                            )
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="copy"
                                                            class="fa-md mr-2"
                                                        ></fa-icon>
                                                        {{
                                                            `${$t('reference')}  ${
                                                                order.Reference
                                                            }`
                                                        }}
                                                    </span>

                                                    <br />
                                                </span>
                                            </span>
                                        </span>
                                        <span
                                            v-if="column.name === 'pick_up'"
                                            class="flex"
                                        >
                                            <span
                                                v-if="
                                                    item.currentStepsToDisplay &&
                                                    item.currentStepsToDisplay
                                                        .length &&
                                                    item.steps &&
                                                    item.steps.length &&
                                                    item
                                                        .currentStepsToDisplay[0] &&
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .Type === 'pickup'
                                                "
                                                class="text-xs flex items-center"
                                                :class="
                                                    item.currentStepsToDisplay &&
                                                    item.currentStepsToDisplay
                                                        .length &&
                                                    item.steps &&
                                                    item.steps.length &&
                                                    item
                                                        .currentStepsToDisplay[0] &&
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .ExecutedDateTime !==
                                                        '' &&
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .ExecutedDateTime !==
                                                        null &&
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .Status ===
                                                        'executed' &&
                                                    !column.noStepInfo
                                                        ? 'executed-step-color'
                                                        : ''
                                                "
                                            >
                                                <span
                                                    v-if="
                                                        column.canUpdate &&
                                                        item.status !==
                                                            'planned' &&
                                                        item
                                                            .currentStepsToDisplay[0] &&
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .Id &&
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .Status !==
                                                            'executed'
                                                    "
                                                >
                                                    <fa-icon
                                                        v-if="
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .ExecutedDateTime !==
                                                                '' &&
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .ExecutedDateTime !==
                                                                null &&
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .Status ===
                                                                'executed'
                                                        "
                                                        :title="
                                                            $t(
                                                                'force_inprogress',
                                                            )
                                                        "
                                                        :icon="'toggle-on'"
                                                        class="fa-md m-1 cursor-pointer success-msg"
                                                        @click.prevent="
                                                            $emit(
                                                                'updateStepStatus',
                                                                {
                                                                    step: item
                                                                        .currentStepsToDisplay[0],
                                                                    newStatus:
                                                                        item
                                                                            .currentStepsToDisplay[0]
                                                                            .Driver
                                                                            ? 'affected'
                                                                            : 'new',
                                                                    lastStatus:
                                                                        item
                                                                            .steps[
                                                                            item
                                                                                .steps
                                                                                .length -
                                                                                1
                                                                        ]
                                                                            .Status,
                                                                    updateStepAfter: true,
                                                                },
                                                            )
                                                        "
                                                    ></fa-icon>
                                                    <fa-icon
                                                        v-else-if="
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .Status !==
                                                            'new'
                                                        "
                                                        :title="
                                                            $t('force_finish')
                                                        "
                                                        :icon="'toggle-off'"
                                                        class="fa-md m-1 cursor-pointer"
                                                        @click.prevent="
                                                            $emit(
                                                                'updateStepStatus',
                                                                {
                                                                    step: item
                                                                        .currentStepsToDisplay[0],
                                                                    newStatus:
                                                                        'executed',
                                                                    lastStatus:
                                                                        item
                                                                            .steps[
                                                                            item
                                                                                .steps
                                                                                .length -
                                                                                1
                                                                        ]
                                                                            .Status,
                                                                },
                                                            )
                                                        "
                                                    ></fa-icon>
                                                </span>
                                                <span
                                                    class="font-extrabold"
                                                    v-if="
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .ExecutedDateTime !==
                                                            '' &&
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .ExecutedDateTime !==
                                                            null &&
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .Status ===
                                                            'executed'
                                                    "
                                                >
                                                    {{
                                                        new Date(
                                                            item.currentStepsToDisplay[0].ExecutedDateTime,
                                                        ).toLocaleString(
                                                            $i18n.locale,
                                                            {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                            },
                                                        )
                                                    }}
                                                </span>
                                                <span
                                                    v-else
                                                    class="font-extrabold"
                                                    :style="
                                                        !column.noStepInfo
                                                            ? `color:${latOmetre(
                                                                  item
                                                                      .currentStepsToDisplay[0],
                                                              )}`
                                                            : ''
                                                    "
                                                >
                                                    <!-- <fa-icon
                                                    v-if="
                                                        (item.steps[0]
                                                            .ExecutedDateTime !==
                                                            '' &&
                                                            item.steps[0]
                                                                .ExecutedDateTime !==
                                                                null) ||
                                                        item.steps[0].Status ===
                                                            'executed'
                                                    "
                                                    :icon="'check'"
                                                    class="success-msg"
                                                ></fa-icon> -->

                                                    {{
                                                        setMoment(
                                                            item
                                                                .currentStepsToDisplay[0],
                                                        )
                                                    }}</span
                                                >
                                                <span
                                                    class="font-extrabold mx-1"
                                                >
                                                    |
                                                </span>

                                                {{
                                                    ` ${item.currentStepsToDisplay[0].Address1} ${item.currentStepsToDisplay[0].PostalCode} ${item.currentStepsToDisplay[0].City} `
                                                }}
                                            </span>
                                            <span
                                                v-else
                                                class="error-msg font-semibold"
                                            >
                                                {{ $t('undefined_address') }}
                                            </span>
                                        </span>
                                        <span
                                            v-if="column.name === 'delivery'"
                                            class="flex"
                                        >
                                            <span
                                                v-if="
                                                    column.canUpdate &&
                                                    item.status !== 'planned' &&
                                                    item.currentStepsToDisplay &&
                                                    item.currentStepsToDisplay
                                                        .length &&
                                                    item
                                                        .currentStepsToDisplay[1]
                                                        .Id &&
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .Status === 'executed'
                                                "
                                            >
                                                <fa-icon
                                                    v-if="
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .ExecutedDateTime !==
                                                            '' &&
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .ExecutedDateTime !==
                                                            null &&
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .Status ===
                                                            'executed'
                                                    "
                                                    :title="
                                                        $t('force_inprogress')
                                                    "
                                                    :icon="'toggle-on'"
                                                    class="fa-md m-1 cursor-pointer success-msg"
                                                    @click.prevent="
                                                        $emit(
                                                            'updateStepStatus',
                                                            {
                                                                step: item
                                                                    .currentStepsToDisplay[1],
                                                                newStatus: item
                                                                    .currentStepsToDisplay[0]
                                                                    .Driver
                                                                    ? 'affected'
                                                                    : 'inProgress',
                                                                isLast:
                                                                    item
                                                                        .currentStepsToDisplay[1]
                                                                        .Id ===
                                                                    item.steps[
                                                                        item
                                                                            .steps
                                                                            .length -
                                                                            1
                                                                    ].Id,
                                                                lastStatus:
                                                                    item.steps[
                                                                        item
                                                                            .steps
                                                                            .length -
                                                                            1
                                                                    ].Status,
                                                            },
                                                        )
                                                    "
                                                ></fa-icon>
                                                <fa-icon
                                                    v-else-if="
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .Status !== 'new'
                                                    "
                                                    :title="$t('force_finish')"
                                                    :icon="'toggle-off'"
                                                    class="fa-md m-1 cursor-pointer"
                                                    @click.prevent="
                                                        $emit(
                                                            'updateStepStatus',
                                                            {
                                                                step: item
                                                                    .currentStepsToDisplay[1],
                                                                newStatus:
                                                                    'executed',
                                                                isLast:
                                                                    item
                                                                        .currentStepsToDisplay[1]
                                                                        .Id ===
                                                                    item.steps[
                                                                        item
                                                                            .steps
                                                                            .length -
                                                                            1
                                                                    ].Id,
                                                                lastStatus:
                                                                    item.steps[
                                                                        item
                                                                            .steps
                                                                            .length -
                                                                            1
                                                                    ].Status,
                                                            },
                                                        )
                                                    "
                                                ></fa-icon>
                                            </span>
                                            <span
                                                v-if="
                                                    item.currentStepsToDisplay &&
                                                    item.currentStepsToDisplay
                                                        .length &&
                                                    (item.currentStepsToDisplay
                                                        .length -
                                                        1 !==
                                                        0 ||
                                                        (item
                                                            .currentStepsToDisplay[1] &&
                                                            item
                                                                .currentStepsToDisplay[1]
                                                                .Type ===
                                                                'delivery'))
                                                "
                                                class="text-xs flex items-center"
                                                :class="
                                                    item
                                                        .currentStepsToDisplay[1]
                                                        .ExecutedDateTime !==
                                                        '' &&
                                                    item
                                                        .currentStepsToDisplay[1]
                                                        .ExecutedDateTime !==
                                                        null &&
                                                    item
                                                        .currentStepsToDisplay[1]
                                                        .Status ===
                                                        'executed' &&
                                                    !column.noStepInfo
                                                        ? 'executed-step-color'
                                                        : ''
                                                "
                                            >
                                                <span
                                                    class="font-extrabold"
                                                    v-if="
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .ExecutedDateTime !==
                                                            '' &&
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .ExecutedDateTime !==
                                                            null &&
                                                        item
                                                            .currentStepsToDisplay[1]
                                                            .Status ===
                                                            'executed'
                                                    "
                                                >
                                                    {{
                                                        new Date(
                                                            item.currentStepsToDisplay[1].ExecutedDateTime,
                                                        ).toLocaleString(
                                                            $i18n.locale,
                                                            {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                            },
                                                        )
                                                    }}
                                                </span>
                                                <span
                                                    v-else
                                                    class="font-extrabold"
                                                    :style="
                                                        !column.noStepInfo
                                                            ? `color:${latOmetre(
                                                                  item
                                                                      .currentStepsToDisplay[1],
                                                              )}`
                                                            : ''
                                                    "
                                                >
                                                    <!-- <fa-icon
                                                    v-if="
                                                        (item.steps[
                                                            item.steps.length -
                                                                1
                                                        ].ExecutedDateTime !==
                                                            '' &&
                                                            item.steps[
                                                                item.steps
                                                                    .length - 1
                                                            ]
                                                                .ExecutedDateTime !==
                                                                null) ||
                                                        item.steps[
                                                            item.steps.length -
                                                                1
                                                        ].Status === 'executed'
                                                    "
                                                    :icon="'check'"
                                                    class="success-msg"
                                                ></fa-icon> -->
                                                    {{
                                                        setMoment(
                                                            item
                                                                .currentStepsToDisplay[1],
                                                        )
                                                    }}
                                                </span>
                                                <span
                                                    class="mx-1 font-extrabold"
                                                >
                                                    |
                                                </span>
                                                {{
                                                    ` ${item.currentStepsToDisplay[1].Address1} ${item.currentStepsToDisplay[1].PostalCode} ${item.currentStepsToDisplay[1].City}`
                                                }}
                                                <!-- </span> -->
                                            </span>
                                            <span
                                                v-else
                                                class="error-msg font-semibold"
                                            >
                                                {{ $t('undefined_address') }}
                                            </span>
                                        </span>
                                        <span
                                            v-if="
                                                column.packages &&
                                                column.name == 'additional_info'
                                            "
                                            class="whitespace-nowrap flex justify-center items-center"
                                        >
                                            <span class="text-xs mr-1">
                                                <span
                                                    :id="`sub-icon-recurrent-${item.id}`"
                                                    :title="$t('recurrent')"
                                                    class="fa-fw"
                                                    v-if="
                                                        item.recurrent === true
                                                    "
                                                >
                                                    <fa-layer>
                                                        <fa-icon
                                                            :icon="'cart-shopping'"
                                                        ></fa-icon>

                                                        <fa-icon
                                                            style="
                                                                font-weight: 200;
                                                            "
                                                            transform="shrink-6 up-10 right-14"
                                                            :icon="'repeat'"
                                                        ></fa-icon>
                                                        <!-- transform="shrink-6" -->
                                                    </fa-layer>
                                                    <span
                                                        class="ml-2 font-extrabold"
                                                    >
                                                        |
                                                    </span>
                                                </span>
                                                <span
                                                    :id="`sub-icon-round-${item.id}`"
                                                    :title="$t('round_trip')"
                                                    class="fa-fw"
                                                    v-if="
                                                        item.roundTrip === true
                                                    "
                                                >
                                                    <fa-layer>
                                                        <fa-icon
                                                            :icon="'route'"
                                                        ></fa-icon>

                                                        <fa-icon
                                                            style="
                                                                font-weight: 200;
                                                            "
                                                            transform="shrink-6 up-10 right-14"
                                                            :icon="'rotate'"
                                                        ></fa-icon>
                                                    </fa-layer>
                                                    <span
                                                        class="ml-2 font-extrabold"
                                                    >
                                                        |
                                                    </span>
                                                </span>
                                                <!-- <span
                                                v-if="item.roundTrip === true"
                                                :title="$t('round_trip')"
                                            >
                                                <fa-layer class="fa-xl mr-1">
                                                    <fa-icon
                                                    style="color: grey"

                                                        icon="rotate"
                                                    ></fa-icon>
                                                    <fa-icon
                                                        icon="route"
                                                        transform="shrink-9"
                                                    ></fa-icon>
                                                </fa-layer>
                                                <span
                                                    class=" font-extrabold"
                                                >
                                                    |
                                                </span>
                                            </span> -->
                                                <span
                                                    v-for="(
                                                        groupedPack,
                                                        groupedIndex
                                                    ) in item.groupedPackages"
                                                    :key="groupedIndex"
                                                >
                                                    <fa-layer
                                                        :title="
                                                            $t(
                                                                'no_temp_specified',
                                                            )
                                                        "
                                                        v-if="
                                                            groupedPack.TemperatureOption ===
                                                                'none' ||
                                                            groupedPack.TemperatureOption ===
                                                                null
                                                        "
                                                        class="fa-xl mr-1"
                                                    >
                                                        <fa-icon
                                                            icon="ban"
                                                            style="color: grey"
                                                        ></fa-icon>
                                                        <fa-icon
                                                            icon="temperature-half"
                                                            transform="shrink-6"
                                                        ></fa-icon>
                                                    </fa-layer>

                                                    <span
                                                        v-else
                                                        class="mr-1"
                                                        :style="
                                                            groupedPack.TemperatureOption ===
                                                            'fresh'
                                                                ? 'color:dodgerblue;'
                                                                : groupedPack.TemperatureOption ===
                                                                    'frozen'
                                                                  ? 'color:cyan;'
                                                                  : 'color:firebrick;'
                                                        "
                                                    >
                                                        <fa-icon
                                                            :title="
                                                                $t(
                                                                    groupedPack.TemperatureOption ===
                                                                        'fresh'
                                                                        ? 'cool'
                                                                        : groupedPack.TemperatureOption ===
                                                                            'frozen'
                                                                          ? 'frozen'
                                                                          : 'hot',
                                                                )
                                                            "
                                                            :icon="
                                                                groupedPack.TemperatureOption ===
                                                                'fresh'
                                                                    ? 'temperature-low'
                                                                    : groupedPack.TemperatureOption ===
                                                                        'frozen'
                                                                      ? 'temperature-arrow-down'
                                                                      : 'temperature-high'
                                                            "
                                                            class="fa-lg"
                                                        ></fa-icon>
                                                    </span>
                                                    <span class="mr-1">
                                                        {{
                                                            `${
                                                                groupedPack.Quantity
                                                            } ${
                                                                groupedIndex !==
                                                                item
                                                                    .groupedPackages
                                                                    .length -
                                                                    1
                                                                    ? ' • '
                                                                    : ''
                                                            }
                                                     `
                                                        }}
                                                    </span>
                                                </span>
                                            </span>

                                            <span
                                                v-if="!column.noStepInfo"
                                                class="text-xs"
                                                :title="$t('finished_steps')"
                                            >
                                                <span
                                                    v-if="
                                                        hasStepsErrors(
                                                            item.steps,
                                                        ) === true
                                                    "
                                                    class="mr-1"
                                                >
                                                    <span
                                                        class="font-extrabold mx-1"
                                                    >
                                                        |
                                                    </span>
                                                    <fa-icon
                                                        :title="
                                                            $t('steps_errors')
                                                        "
                                                        icon="warning"
                                                        class="ko-link fa-lg"
                                                    />
                                                </span>
                                                <span v-if="item.id">
                                                    <span
                                                        class="mr-1 font-extrabold"
                                                    >
                                                        |
                                                    </span>
                                                    {{
                                                        ` ${item.executedSteps} / ${item.steps.length}`
                                                    }}
                                                    <fa-icon
                                                        icon="flag-checkered"
                                                        class="ml-1 fa-lg"
                                                    ></fa-icon>
                                                </span>
                                                <span
                                                    v-if="item.break === true"
                                                    class="mr-1"
                                                >
                                                    <span
                                                        class="ml-1 font-extrabold"
                                                    >
                                                        |
                                                    </span>
                                                    <fa-icon
                                                        :title="$t('break')"
                                                        icon="bolt"
                                                        class="ko-link fa-lg"
                                                    />
                                                </span>
                                            </span>
                                            <!-- <span class="whitespace-nowrap">
                                            <fa-layer
                                                :title="$t('no_temp_specified')"
                                                v-if="pack.temperature.noTemp"
                                                class="fa-lg mr-1"
                                            >
                                                <fa-icon
                                                    icon="ban"
                                                    style="color: grey"
                                                ></fa-icon>
                                                <fa-icon
                                                    :icon="
                                                        pack.temperature.icon
                                                    "
                                                    transform="shrink-6"
                                                ></fa-icon>
                                            </fa-layer>
                                            <span
                                                v-else
                                                class="mr-1"
                                                :style="`${pack.temperature.color}`"
                                            >
                                                <fa-icon
                                                    :title="
                                                        $t(
                                                            pack.temperature
                                                                .title
                                                        )
                                                    "
                                                    :icon="
                                                        pack.temperature.icon
                                                    "
                                                    class="fa-md"
                                                ></fa-icon>
                                            </span>
                                            <span
                                                v-if="
                                                    pack.name.includes('_') ===
                                                        true ||
                                                    pack.name === 'pallet'
                                                "
                                                class="whitespace-nowrap text-xs"
                                            >
                                                {{
                                                    `${order.NbPackages} ${$tc(
                                                        pack.name,
                                                        +order.NbPackages === 1
                                                            ? 1
                                                            : 2
                                                    )}`
                                                }}
                                            </span>
                                            <span
                                                v-else
                                                class="whitespace-nowrap text-xs"
                                            >
                                                {{
                                                    `${order.NbPackages} ${pack.name}`
                                                }}
                                            </span> -->
                                            <!-- </span> -->
                                            <br />
                                        </span>
                                        <span v-if="column.steps">
                                            <span
                                                v-for="(step, index) in item[
                                                    column.name
                                                ]"
                                                :key="index"
                                                class="my-2 stop-stretching"
                                            >
                                                <button
                                                    class="mb-2 hover:bg-transparent"
                                                    :title="
                                                        $t(
                                                            'step_needs_correction',
                                                        )
                                                    "
                                                    @click.prevent="
                                                        $emit('updateStep', {
                                                            id: step.Id,
                                                            order_ref:
                                                                item.reference,
                                                        })
                                                    "
                                                >
                                                    <span
                                                        class="items-center"
                                                        :class="
                                                            step.Latitude ===
                                                                null ||
                                                            step.Longitude ===
                                                                null
                                                                ? 'ko-link'
                                                                : 'ok-link'
                                                        "
                                                    >
                                                        <fa-icon
                                                            v-if="
                                                                step.Latitude ===
                                                                    null ||
                                                                step.Longitude ===
                                                                    null
                                                            "
                                                            icon="warning"
                                                            class="mr-1"
                                                        />
                                                        {{
                                                            step.StepNumber +
                                                            ' - ' +
                                                            step.AddressName +
                                                            ' - ' +
                                                            step.Type
                                                        }}
                                                    </span>
                                                </button>
                                                <br />
                                            </span>
                                        </span>
                                        <span
                                            v-else-if="
                                                column.drivers &&
                                                column.name === 'drivers'
                                            "
                                            class="stop-streching darky"
                                        >
                                            <span
                                                v-if="
                                                    item.currentStepsToDisplay &&
                                                    item.currentStepsToDisplay
                                                        .length &&
                                                    item.steps &&
                                                    item.steps.length &&
                                                    column.canUpdate === true &&
                                                    (item.steps[0].Status ===
                                                        'new' ||
                                                        item.steps[0].Status ===
                                                            'affected' ||
                                                        user.type ===
                                                            'superadmin') &&
                                                    goodGeoloc(
                                                        item.currentStepsToDisplay,
                                                    ) === true
                                                "
                                                class="darky text-xs selectMS items-center flex"
                                            >
                                                <span
                                                    v-if="
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .Driver &&
                                                        +item
                                                            .currentStepsToDisplay[0]
                                                            .Driver.Active === 0
                                                    "
                                                    class="mr-1"
                                                >
                                                    <fa-layer
                                                        class="fa-lg"
                                                        @mouseenter="
                                                            showTooltip(
                                                                'tooltip-right' +
                                                                    item.id,
                                                                true,
                                                            )
                                                        "
                                                        @mouseleave="
                                                            showTooltip(
                                                                'tooltip-right' +
                                                                    item.id,
                                                                false,
                                                            )
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="slash"
                                                            class="error-msg"
                                                        ></fa-icon>

                                                        <fa-icon
                                                            transform="shrink-6 "
                                                            icon="people-carry-box"
                                                        ></fa-icon>
                                                        <!-- transform="shrink-6" -->
                                                    </fa-layer>
                                                </span>
                                                <span
                                                    v-if="
                                                        item.currentStepsToDisplay &&
                                                        item
                                                            .currentStepsToDisplay
                                                            .length
                                                    "
                                                >
                                                    <Multiselect
                                                        class="rounded mt-1 w-full darky"
                                                        v-model="
                                                            selectedDriver(
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .Driver,
                                                            ).value
                                                        "
                                                        :options="
                                                            column.options
                                                        "
                                                        :loading="
                                                            column.options
                                                                .length === 0
                                                        "
                                                        :disabled="
                                                            disableOtherMs
                                                        "
                                                        label="name"
                                                        trackBy="name"
                                                        :key="refresh"
                                                        :searchable="true"
                                                        :placeholder="
                                                            column.options
                                                                .length
                                                                ? $t(
                                                                      'select_one',
                                                                  )
                                                                : $t(
                                                                      'no_available_for_date',
                                                                  )
                                                        "
                                                        :allow-empty="true"
                                                        :can-clear="true"
                                                        @select="
                                                            $emit(
                                                                'updateStepDriver',
                                                                {
                                                                    steps: item.currentStepsToDisplay,
                                                                    newDriver:
                                                                        $event,
                                                                    isRecurrent:
                                                                        !item.id,
                                                                },
                                                            )
                                                        "
                                                        @clear="
                                                            $emit(
                                                                'updateStepDriver',
                                                                {
                                                                    steps: item.currentStepsToDisplay,
                                                                    newDriver:
                                                                        null,
                                                                    isRecurrent:
                                                                        !item.id,
                                                                },
                                                            )
                                                        "
                                                    ></Multiselect>
                                                </span>
                                            </span>
                                            <span
                                                v-else-if="
                                                    (item.currentStepsToDisplay &&
                                                        item
                                                            .currentStepsToDisplay
                                                            .length &&
                                                        column.canUpdate ===
                                                            false &&
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .Driver) ||
                                                    (item.steps &&
                                                        item.steps.length &&
                                                        item.steps[0].Status !==
                                                            'new' &&
                                                        item.steps[0].Status !==
                                                            'affected' &&
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .Driver)
                                                "
                                                class="flex justify-center whitespace-nowrap items-center"
                                            >
                                                <span
                                                    v-if="
                                                        item.currentStepsToDisplay &&
                                                        item
                                                            .currentStepsToDisplay
                                                            .length &&
                                                        item
                                                            .currentStepsToDisplay[0]
                                                            .Driver &&
                                                        +item
                                                            .currentStepsToDisplay[0]
                                                            .Driver.Active === 0
                                                    "
                                                    class="mr-1"
                                                >
                                                    <fa-layer
                                                        class="fa-lg"
                                                        @mouseenter="
                                                            showTooltip(
                                                                'tooltip-right' +
                                                                    item.id,
                                                                true,
                                                            )
                                                        "
                                                        @mouseleave="
                                                            showTooltip(
                                                                'tooltip-right' +
                                                                    item.id,
                                                                false,
                                                            )
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="slash"
                                                            class="error-msg"
                                                        ></fa-icon>

                                                        <fa-icon
                                                            transform="shrink-6 "
                                                            icon="people-carry-box"
                                                        ></fa-icon>
                                                        <!-- transform="shrink-6" -->
                                                    </fa-layer>
                                                </span>
                                                {{
                                                    ' ' +
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .Driver.FirstName +
                                                    ' ' +
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .Driver.LastName
                                                }}
                                                <br />
                                            </span>
                                            <span
                                                v-if="
                                                    item.currentStepsToDisplay &&
                                                    item.currentStepsToDisplay
                                                        .length &&
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .Driver
                                                "
                                                :id="'tooltip-right' + item.id"
                                                role="tooltip"
                                                class="absolute invisible w-32 mb-3 z-10 inline-block px-2 py-2 text-sm font-medium text-white bg-gray-900 rounded shadow-sm ml-6 tooltip dark:bg-gray-700"
                                            >
                                                {{
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .Driver.FirstName +
                                                    ' ' +
                                                    item
                                                        .currentStepsToDisplay[0]
                                                        .Driver.LastName +
                                                    ' - ' +
                                                    $t('inactive_driver')
                                                }}
                                            </span>
                                        </span>

                                        <span v-else-if="column.isLink">
                                            <fa-icon
                                                v-if="
                                                    storeToSort === 'orders' &&
                                                    item.isInvoiced
                                                "
                                                icon="file-invoice-dollar"
                                                class="hover:font-semibold theme-color"
                                                @mouseenter="
                                                    showTooltip(
                                                        'tooltip-right-invoiced' +
                                                            item.id,
                                                        true,
                                                    )
                                                "
                                                @mouseleave="
                                                    showTooltip(
                                                        'tooltip-right-invoiced' +
                                                            item.id,
                                                        false,
                                                    )
                                                "
                                            ></fa-icon>
                                            <span
                                                :id="
                                                    'tooltip-right-invoiced' +
                                                    item.id
                                                "
                                                role="tooltip"
                                                class="absolute invisible w-32 mb-3 z-10 inline-block px-2 py-2 text-sm font-medium text-white bg-gray-900 rounded shadow-sm ml-6 tooltip dark:bg-gray-700"
                                            >
                                                {{ $t('invoiced') }}
                                            </span>
                                            <fa-icon
                                                v-if="storeToSort === 'drivers'"
                                                :title="$t('planning')"
                                                icon="calendar"
                                                class="hover:font-semibold fa-lg cursor-pointer"
                                                @click.prevent="
                                                    $emit('planning', item.id)
                                                "
                                            ></fa-icon>
                                            <fa-icon
                                                v-if="
                                                    storeToSort ===
                                                        'containers' &&
                                                    item.isDefault === true
                                                "
                                                :title="
                                                    $t('default_container_info')
                                                "
                                                icon="heart"
                                                class="fa-lg error-msg mr-1"
                                            ></fa-icon>
                                            <button
                                                :class="
                                                    item.id
                                                        ? 'hover:font-semibold ml-2 cursor-pointer'
                                                        : 'cursor-default  ml-2'
                                                "
                                                @click.prevent="
                                                    item.id
                                                        ? $emit(
                                                              'update',
                                                              item.id,
                                                          )
                                                        : ''
                                                "
                                                @click.right="
                                                    item.id
                                                        ? (e) => {
                                                              openMenu(
                                                                  e,
                                                                  column.routeName,
                                                                  item.id,
                                                              )
                                                          }
                                                        : ''
                                                "
                                            >
                                                <span
                                                    class="items-center custom-link"
                                                >
                                                    {{
                                                        column.name.includes(
                                                            'type',
                                                        ) ||
                                                        (column.translate &&
                                                            column.translate ===
                                                                true)
                                                            ? $t(
                                                                  item[
                                                                      column
                                                                          .name
                                                                  ],
                                                              )
                                                            : item[column.name]
                                                    }}
                                                </span>

                                                <!-- class="hidden" -->
                                            </button>
                                            <ul
                                                :id="`right-click-menu-${item.id}`"
                                                tabindex="-1"
                                                :el="right"
                                                v-if="
                                                    item.id &&
                                                    viewMenu ===
                                                        `right-click-menu-${item.id}`
                                                "
                                                class="right-click-menu"
                                                @pointerleave="closeMenu()"
                                            >
                                                <li
                                                    @click.prevent="
                                                        goToNewTab(
                                                            item.id,
                                                            column.routeName,
                                                        )
                                                    "
                                                    :class="`text-zinc-700 whitespace-nowrap text-sm`"
                                                >
                                                    <fa-icon
                                                        icon="up-right-from-square"
                                                        class="mr-2"
                                                    ></fa-icon>
                                                    {{ $t('open_in_new_tab') }}
                                                </li>
                                            </ul>
                                            <!-- <div
                                            id="dd-menu"
                                            v-if="
                                                viewMenu ===
                                                `right-click-menu-${item.id}`
                                            "

                                            @blur="closeMenu()"
                                            class="right-0 dd-menu z-50 origin-top-right  mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="menu-button"
                                            tabindex="-1"
                                        >
                                            <div
                                                class="py-1 whitespace-nowrap cursor-pointer"
                                                role="none"
                                            >
                                                <span
                                                    :class="`text-zinc-700 hover:bg-zinc-100 block px-4 py-2 text-sm dd-menu`"
                                                    role="menuitem"
                                                    tabindex="-1"
                                                    @click.prevent="
                                                        goToNewTab(
                                                            item.id,
                                                            column.routeName
                                                        )
                                                    "
                                                >
                                                    <fa-icon
                                                        v-if="item.icon"
                                                        icon="up-right-from-square"
                                                        class="mr-2"
                                                    ></fa-icon>
                                                    {{ $t('open_in_new_tab') }}
                                                </span>
                                            </div>
                                        </div> -->
                                        </span>
                                        <button
                                            v-else-if="column.isFileDownload"
                                            @click.prevent="
                                                $emit('download', item.file)
                                            "
                                            :title="$t('download')"
                                            class="hover:font-semibold"
                                        >
                                            <span
                                                class="items-center flex custom-link"
                                            >
                                                <fa-icon
                                                    icon="download"
                                                    class="fa-sm mr-2"
                                                ></fa-icon>
                                                {{
                                                    column.name.includes('type')
                                                        ? $t(item[column.name])
                                                        : item[column.name]
                                                }}
                                            </span>
                                        </button>
                                        <span
                                            v-else-if="
                                                column.name === 'actions'
                                            "
                                            class="flex items-center justify-center"
                                        >
                                            <span v-if="item.file">
                                                <span
                                                    v-if="isB2C === false"
                                                    @click.prevent="
                                                        $emit(
                                                            'sendReminder',
                                                            item.id,
                                                        )
                                                    "
                                                    :title="$t('send_reminder')"
                                                    class="hover:font-semibold custom-link cursor-pointer"
                                                >
                                                    <fa-icon
                                                        icon="envelope"
                                                        class="fa-lg mr-3"
                                                    ></fa-icon>
                                                </span>
                                                <span
                                                    @click.prevent="
                                                        $emit(
                                                            'setInvoiceAsPaid',
                                                            item.id,
                                                        )
                                                    "
                                                    :title="
                                                        $t(
                                                            'set_invoice_as_paid',
                                                        )
                                                    "
                                                    class="hover:font-semibold custom-link cursor-pointer"
                                                >
                                                    <fa-icon
                                                        icon="cash-register"
                                                        class="fa-lg"
                                                    ></fa-icon>
                                                </span>
                                            </span>
                                            <span
                                                v-else
                                                class="flex items-center justify-center"
                                            >
                                                <fa-icon
                                                    icon="spinner"
                                                    class="fa-lg theme-color"
                                                    spin-pulse
                                                ></fa-icon>
                                            </span>
                                            <!-- <span
                                            @click.prevent="
                                                $emit('print', item)
                                            "
                                            :title="$t('print')"
                                            class="hover:font-semibold custom-link cursor-pointer"
                                        >
                                            <fa-icon
                                                icon="print"
                                                class="fa-md"
                                            ></fa-icon>
                                        </span> -->
                                        </span>
                                        <span
                                            v-else-if="
                                                column.name === 'credits'
                                            "
                                        >
                                            <span
                                                v-if="
                                                    item['credits'].length === 0
                                                "
                                            >
                                                {{ `${$t('no_credits')}` }}
                                            </span>
                                            <span v-else>
                                                <div
                                                    v-for="credit in item[
                                                        'credits'
                                                    ]"
                                                    :key="credit.id"
                                                >
                                                    {{
                                                        `${credit.Amount} € ${$t('valid_until')} ${new Date(
                                                            credit.EndDate,
                                                        ).toLocaleDateString(
                                                            $i18n.locale,
                                                        )}`
                                                    }}
                                                </div>
                                            </span>
                                        </span>
                                        <span
                                            v-else-if="column.dwnldOrSend"
                                            class="flex items-center justify-center"
                                        >
                                            <!-- && item.payment_status !== 'paid' -->
                                            <span v-if="item.file">
                                                <span
                                                    v-if="isB2C === false"
                                                    @click.prevent="
                                                        $emit(
                                                            'sendByMail',
                                                            item.id,
                                                        )
                                                    "
                                                    :title="
                                                        $t(
                                                            'send_invoice_by_mail',
                                                        )
                                                    "
                                                    class="hover:font-semibold custom-link cursor-pointer"
                                                >
                                                    <fa-icon
                                                        icon="envelope"
                                                        class="fa-lg mr-3"
                                                    ></fa-icon>
                                                </span>
                                                <span
                                                    @click.prevent="
                                                        $emit('download', item)
                                                    "
                                                    :title="$t('download')"
                                                    class="hover:font-semibold custom-link cursor-pointer"
                                                >
                                                    <fa-icon
                                                        icon="download"
                                                        class="fa-lg"
                                                    ></fa-icon>
                                                </span>
                                            </span>
                                            <span
                                                v-else
                                                class="flex items-center justify-center"
                                            >
                                                <fa-icon
                                                    icon="spinner"
                                                    class="fa-lg theme-color"
                                                    spin-pulse
                                                ></fa-icon>
                                            </span>
                                            <!-- <span
                                            @click.prevent="
                                                $emit('print', item)
                                            "
                                            :title="$t('print')"
                                            class="hover:font-semibold custom-link cursor-pointer"
                                        >
                                            <fa-icon
                                                icon="print"
                                                class="fa-md"
                                            ></fa-icon>
                                        </span> -->
                                        </span>
                                        <span
                                            v-else-if="
                                                column.withIconNoText &&
                                                column.type === 'icon-select'
                                            "
                                            class="stop-stretching"
                                            :style="
                                                item.icons[column.name].style
                                            "
                                        >
                                            <span
                                                v-if="column.name === 'status'"
                                            >
                                                <span
                                                    v-if="
                                                        item
                                                            .currentStepsToDisplay
                                                            .length &&
                                                        !item
                                                            .currentStepsToDisplay[0]
                                                            .icon
                                                    "
                                                >
                                                    <span
                                                        v-if="
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon !==
                                                                '' &&
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon !== null
                                                        "
                                                        :title="
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                                'flag-checkered' ||
                                                            item.icons[
                                                                column.name
                                                            ].icon.icon ===
                                                                'hourglass-half'
                                                                ? $t(
                                                                      item
                                                                          .icons[
                                                                          column
                                                                              .name
                                                                      ].icon
                                                                          .title,
                                                                  )
                                                                : $t(
                                                                      item[
                                                                          column
                                                                              .name
                                                                      ],
                                                                  ) +
                                                                  ' - ' +
                                                                  $t(
                                                                      item
                                                                          .icons[
                                                                          column
                                                                              .name
                                                                      ].icon
                                                                          .title,
                                                                  )
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                    'car' ||
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                    'calendar' ||
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                    'ship' ||
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                    'truck' ||
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                    'person-biking' ||
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                    'flag-checkered' ||
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                    'hourglass-half' ||
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                    'file-export'
                                                            "
                                                        >
                                                            <fa-icon
                                                                :icon="
                                                                    item.icons[
                                                                        column
                                                                            .name
                                                                    ].icon.icon
                                                                "
                                                            ></fa-icon>
                                                        </span>
                                                        <img
                                                            v-else
                                                            :alt="
                                                                $t(
                                                                    item.icons[
                                                                        column
                                                                            .name
                                                                    ].icon
                                                                        .title,
                                                                )
                                                            "
                                                            svg-inline
                                                            :src="`biiimIcons/${
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon
                                                            }.svg`"
                                                            class="mx-auto darky icon-img"
                                                            :class="
                                                                item.icons[
                                                                    column.name
                                                                ].icon.icon ===
                                                                'van'
                                                                    ? 'w-4'
                                                                    : 'w-7 '
                                                            "
                                                        />
                                                    </span>
                                                    <span
                                                        v-else
                                                        :title="
                                                            $t(
                                                                item[
                                                                    column.name
                                                                ],
                                                            )
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="route"
                                                            class="fa-lg"
                                                        />
                                                    </span>
                                                </span>

                                                <!-- <span
                                                v-else
                                                :title="$t(item[column.name])"
                                            >
                                                <fa-icon
                                                    :icon="
                                                        item.currentStepsToDisplay[0].icon.icon
                                                    "
                                                    class="fa-lg"
                                                />
                                            </span> -->
                                                <span v-else>
                                                    <span
                                                        v-if="
                                                            item.currentStepsToDisplay &&
                                                            item
                                                                .currentStepsToDisplay
                                                                .length &&
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .icon.icon !==
                                                                '' &&
                                                            item
                                                                .currentStepsToDisplay[0]
                                                                .icon.icon !==
                                                                null
                                                        "
                                                        :title="
                                                            $t(
                                                                item[
                                                                    column.name
                                                                ],
                                                            ) +
                                                            ' - ' +
                                                            $t(
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon.title,
                                                            )
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon
                                                                    .icon ===
                                                                    'car' ||
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon
                                                                    .icon ===
                                                                    'calendar' ||
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon
                                                                    .icon ===
                                                                    'ship' ||
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon
                                                                    .icon ===
                                                                    'truck' ||
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon
                                                                    .icon ===
                                                                    'person-biking' ||
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon
                                                                    .icon ===
                                                                    'file-export'
                                                            "
                                                        >
                                                            <fa-icon
                                                                :icon="
                                                                    item
                                                                        .currentStepsToDisplay[0]
                                                                        .icon
                                                                        .icon
                                                                "
                                                            ></fa-icon>
                                                        </span>
                                                        <img
                                                            v-else
                                                            :alt="
                                                                $t(
                                                                    item
                                                                        .currentStepsToDisplay[0]
                                                                        .icon
                                                                        .title,
                                                                )
                                                            "
                                                            svg-inline
                                                            :src="`biiimIcons/${item.currentStepsToDisplay[0].icon.icon}.svg`"
                                                            class="mx-auto darky icon-img"
                                                            :class="
                                                                item
                                                                    .currentStepsToDisplay[0]
                                                                    .icon
                                                                    .icon ===
                                                                'van'
                                                                    ? 'w-4'
                                                                    : 'w-7 '
                                                            "
                                                        />
                                                    </span>
                                                    <span
                                                        v-else
                                                        :title="
                                                            $t(
                                                                item[
                                                                    column.name
                                                                ],
                                                            )
                                                        "
                                                    >
                                                        <fa-icon
                                                            icon="route"
                                                            class="fa-lg"
                                                        />
                                                    </span>
                                                </span>
                                            </span>
                                            <span
                                                v-else
                                                :title="$t(item[column.name])"
                                            >
                                                <fa-icon
                                                    :icon="
                                                        item.icons[column.name]
                                                            .icon
                                                    "
                                                    class="fa-lg"
                                                />
                                            </span>
                                        </span>

                                        <span
                                            v-else-if="column.enum"
                                            class="my-1"
                                            :class="
                                                column.enumColNumber
                                                    ? `grid gap-1 ${column.enumColNumber}`
                                                    : column.enumNoGrid
                                                      ? ''
                                                      : ' grid gap-1 grid-cols-3'
                                            "
                                        >
                                            <div
                                                v-for="(
                                                    enumItem, index
                                                ) in item[column.name]"
                                                :key="index"
                                                class="text-xs col-span-1 flex items-center overflow-auto"
                                            >
                                                <span
                                                    class="border-2 rounded p-2 w-full h-full"
                                                >
                                                    <div
                                                        v-if="
                                                            column.canRemoveEnum ===
                                                            true
                                                        "
                                                        class="flex justify-end"
                                                    >
                                                        <fa-icon
                                                            icon="trash-can"
                                                            :title="
                                                                $t('remove')
                                                            "
                                                            class="ml-2 cursor-pointer"
                                                            @click.prevent="
                                                                $emit(
                                                                    'remove' +
                                                                        column.name,
                                                                    enumItem,
                                                                )
                                                            "
                                                        ></fa-icon>
                                                    </div>
                                                    <div
                                                        class="font-semibold mb-1"
                                                        :title="$t('name')"
                                                    >
                                                        {{ `${enumItem.Name}` }}
                                                    </div>
                                                    <!-- <div
                                                    class="cursor-copy theme-color mb-1"
                                                    :title="`${$t(
                                                        'code'
                                                    )} - ${copyTitle}`"
                                                    @click.prevent="
                                                        copyToClipboard(
                                                            enumItem.Code
                                                        )
                                                    "
                                                >
                                                    {{ `${enumItem.Code}` }}
                                                </div> -->
                                                    <div
                                                        class="italic"
                                                        :title="
                                                            $t('description')
                                                        "
                                                    >
                                                        {{
                                                            `${enumItem.Description}`
                                                        }}
                                                    </div>
                                                </span>
                                            </div>
                                        </span>

                                        <span
                                            v-else-if="column.withIconNoText"
                                            class="stop-stretching"
                                        >
                                            <fa-icon
                                                v-if="item[column.name] === 1"
                                                icon="check"
                                                :title="$t('active')"
                                                class="success-msg fa-xl"
                                            />
                                            <fa-icon
                                                v-else
                                                icon="xmark"
                                                :title="$t('deactivated')"
                                                class="error-msg fa-xl"
                                            />
                                        </span>
                                        <!-- <span
                                        v-else-if="column.moment"
                                        class="stop-stretching"
                                    >
                                        <span
                                            v-for="(step, index) in item.steps"
                                            :key="index"
                                        >
                                            {{
                                                ` ${$t(step.Moment)} ${
                                                    step.Moment === 'between'
                                                        ? `${
                                                              step.FromDateTime
                                                          } ${$t('and')} ${
                                                              step.BeforeDateTime
                                                          }`
                                                        : step.Moment ===
                                                          'before'
                                                        ? step.BeforeDateTime
                                                        : step.FromDateTime
                                                }`
                                            }}</span
                                        >
                                    </span> -->
                                        <span
                                            v-else-if="
                                                column.name === 'report' &&
                                                item['status'] === 'errors'
                                            "
                                        >
                                            <button
                                                @click.prevent="
                                                    $emit(
                                                        'goToCorrection',
                                                        item.date_begin,
                                                    )
                                                "
                                            >
                                                <span class="ko-link">
                                                    <fa-icon
                                                        icon="warning"
                                                        class="mr-1"
                                                    />
                                                    {{ item[column.name] }}
                                                </span>
                                            </button>
                                        </span>

                                        <span
                                            v-else-if="
                                                column.name === 'orderGroup'
                                            "
                                        >
                                            <span
                                                v-if="item.orderGroup"
                                                :class="
                                                    column.hasAccess
                                                        ? 'custom-link cursor-pointer'
                                                        : ''
                                                "
                                                @click="
                                                    column.hasAccess
                                                        ? $emit(
                                                              'updateOrderGroup',
                                                              {
                                                                  group: item.orderGroup,
                                                                  client: item.idClient,
                                                              },
                                                          )
                                                        : ''
                                                "
                                            >
                                                {{ item.orderGroup.Name }}
                                            </span>
                                        </span>
                                        <span
                                            v-else-if="
                                                item[column.name] !== null &&
                                                item[column.name] !== '' &&
                                                !column.recurrent &&
                                                column.name !== 'ocs'
                                            "
                                        >
                                            <span
                                                v-if="
                                                    column.expandable &&
                                                    item.sales.length > 0 &&
                                                    column.expandable === true
                                                "
                                            >
                                                <fa-icon
                                                    :icon="
                                                        expandedRows.includes(
                                                            index,
                                                        )
                                                            ? 'caret-up'
                                                            : 'caret-down'
                                                    "
                                                    :title="
                                                        expandedRows.includes(
                                                            index,
                                                        )
                                                            ? 'hide_sales'
                                                            : 'see_sales'
                                                    "
                                                    class="mr-2 fa-xl theme-color"
                                                    @click.prevent="
                                                        toggleDetails(index)
                                                    "
                                                >
                                                </fa-icon>
                                            </span>
                                            <fa-icon
                                                v-if="
                                                    storeToSort ===
                                                        'containers' &&
                                                    item.isDefault === true &&
                                                    column.name === 'name'
                                                "
                                                :title="
                                                    $t('default_container_info')
                                                "
                                                icon="heart"
                                                class="fa-lg error-msg mr-1"
                                            ></fa-icon>
                                            <fa-icon
                                                v-if="
                                                    storeToSort ===
                                                        'imported_orders' &&
                                                    item.status === 'errors' &&
                                                    column.name === 'status'
                                                "
                                                icon="triangle-exclamation"
                                                class="fa-lg error-msg mr-1"
                                            ></fa-icon>
                                            {{
                                                column.name.includes('type') ||
                                                (column.translate &&
                                                    column.translate === true)
                                                    ? $t(item[column.name])
                                                    : column.name.includes(
                                                            'date',
                                                        )
                                                      ? formattedDate(
                                                            item[column.name],
                                                            $route.name ===
                                                                'new_vehicle' ||
                                                                $route.name ===
                                                                    'update_vehicle' ||
                                                                column.name ===
                                                                    'meeting_date',
                                                        )
                                                      : item[column.name]
                                            }}
                                        </span>

                                        <div
                                            v-if="
                                                item.delivery_order_files &&
                                                item.delivery_order_files
                                                    .length > 0 &&
                                                column.name === 'client'
                                            "
                                            class="text-xs"
                                        >
                                            <div
                                                v-for="(
                                                    deliveryOrderFile,
                                                    deliveryOrderIndex
                                                ) in item.delivery_order_files"
                                                :key="deliveryOrderIndex"
                                            >
                                                <button
                                                    @click.prevent="
                                                        $emit(
                                                            'download',
                                                            deliveryOrderFile.Name,
                                                        )
                                                    "
                                                    :title="$t('see_file')"
                                                    class="hover:font-semibold"
                                                >
                                                    <span
                                                        class="items-center flex custom-link"
                                                    >
                                                        <fa-icon
                                                            icon="eye"
                                                            class="fa-sm mr-2"
                                                        ></fa-icon>
                                                        {{ $t('imported_pdf') }}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <span
                                            v-if="
                                                column.subInfo &&
                                                column.subInfo === true &&
                                                item.subInfoForListDisplay
                                            "
                                            class="text-xs"
                                        >
                                            <div
                                                class="text-xs cursor-copy flex items-center justify-center"
                                                :title="
                                                    item.subInfoText
                                                        ? `${$t(
                                                              item.subInfoText,
                                                          )} - ${copyTitle}`
                                                        : ''
                                                "
                                                @click.stop="
                                                    item.subInfoText
                                                        ? copyToClipboard(
                                                              item.subInfoForListDisplay,
                                                          )
                                                        : ''
                                                "
                                            >
                                                {{ item.subInfoForListDisplay }}

                                                <fa-icon
                                                    icon="copy"
                                                    class="fa-xs ml-2"
                                                ></fa-icon>
                                            </div>
                                        </span>
                                    </span>
                                </td>
                                <td
                                    class="px-5 border-b border-zinc-200 text-xs"
                                    :class="
                                        isB2C === true ||
                                        $route.name !== 'orders'
                                            ? 'py-4'
                                            : ''
                                    "
                                >
                                    <span class="flex">
                                        <div
                                            v-if="hoverableItems === true"
                                            class="flex space-x-2 justify-center items-center"
                                        >
                                            <button
                                                @click.prevent="
                                                    showPopover(item.id)
                                                "
                                                class="inline-block cursor-help px-2 text-zinc font-medium text-xs leading-tight uppercase rounded hover:bg-zinc-300 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                                            >
                                                <div
                                                    class="inline-flex relative w-fit"
                                                >
                                                    <fa-icon
                                                        icon="eye"
                                                        class="fa-lg"
                                                    />
                                                </div>
                                            </button>
                                        </div>
                                        <dropdown-menu
                                            v-if="
                                                itemActionsUpdated(
                                                    item.active &&
                                                        item.active === 1,
                                                    item,
                                                ).length > 0
                                            "
                                            ref="listActionDD"
                                            class="inline-flex"
                                            :ddId="'dd-' + item.id"
                                            :ellipsisStyle="true"
                                            :items="
                                                itemActionsUpdated(
                                                    item.active &&
                                                        item.active === 1,
                                                    item,
                                                )
                                            "
                                            @picked="
                                                !item.multiParamItemAction
                                                    ? actionOnItem(
                                                          $event,
                                                          item.id,
                                                      )
                                                    : actionOnItem($event, item)
                                            "
                                        >
                                            <template #buttonContent>
                                                <fa-icon
                                                    :title="$t('action')"
                                                    icon="ellipsis-vertical"
                                                    class="fa-lg"
                                                />
                                            </template>
                                        </dropdown-menu>
                                    </span>
                                </td>
                                <!-- hoverableItems === true && -->

                                <popover
                                    v-if="showPop === item.id"
                                    :item="item"
                                    :headerText="item.reference"
                                    :id="`popover-${item.id}`"
                                    @closePop="showPopover(item.id, true)"
                                    @packageTracking="
                                        $emit('packageTracking', $event)
                                    "
                                    @download="$emit('download', $event)"
                                    @updateStep="
                                        $emit('updateStep', {
                                            id: $event,
                                            order_ref: item.reference,
                                        })
                                    "
                                ></popover>
                                <!-- class="hidden" -->
                            </tr>
                            <template v-if="expandedRows.includes(index)">
                                <!-- <tr
                                    class="darky flex flex-col flex-no wrap sm:table-row xs:table-row"
                                >
                                    <td> -->

                                <tr
                                    class="darky z-20 text-xs file:flex flex-col flex-no wrap sm:table-row xs:table-row"
                                    :class="
                                        isOdd(index)
                                            ? 'light-stripe'
                                            : 'dark-stripe'
                                    "
                                >
                                    <th
                                        class="pl-4 stop-stretching border-b-2 bg-zinc-100 border-zinc-200"
                                    >
                                        <!-- :class="
                                    isB2C === true ||
                                    $route.name !== 'orders'
                                        ? 'px-3'
                                        : ''
                                " -->
                                        <div class="flex items-center">
                                            <!-- <input
                                                class="themed-checkbox h-4 w-4 border border-gray-300 rounded-sm bg-white focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                type="checkbox"
                                                value=""
                                                v-model="allSubItems"
                                                @change="
                                                    checkAllSubElements(item)
                                                "
                                                :id="`check-all`"
                                            />
                                            <dropdown-menu
                                                ref="itemsActionDD"
                                                v-if="canSelectItems === false"
                                                class="inline-flex"
                                                :position="''"
                                                :ellipsisStyle="true"
                                                :items="multiActions"
                                                @picked="multiAction($event)"
                                            >
                                                <template #buttonContent>
                                                    <fa-icon
                                                        :title="$t('action')"
                                                        icon="ellipsis-vertical"
                                                        class="fa-lg"
                                                        :disabled="!allSubItems"
                                                    />
                                                </template>
                                            </dropdown-menu> -->
                                        </div>
                                    </th>
                                    <th
                                        v-for="(
                                            subColumn, subIndex
                                        ) in subTableColumns(
                                            item.sales[0].SaleDetails[0],
                                        )"
                                        :key="subIndex"
                                        :id="`${subColumn.name}-${subIndex}`"
                                        class="py-1 border-b-2 theme-color border-zinc-200 bg-zinc-100 font-semibold text-zinc-500 tracking-wider darky"
                                    >
                                        {{ $t(subColumn.name) }}
                                    </th>
                                    <th
                                        class="z-10 w-0 py-1 border-b-2 stop-stretching border-zinc-200 bg-zinc-100 font-semibold text-zinc-500 rounded-tr-lg"
                                    ></th>
                                </tr>

                                <tr
                                    v-for="(sale, saleIndex) in item.sales[0]
                                        .SaleDetails"
                                    :key="`details-${saleIndex}`"
                                    class="darky text-xs flex flex-col flex-no wrap sm:table-row xs:table-row"
                                    :class="
                                        !isOdd(index)
                                            ? 'light-stripe'
                                            : 'dark-stripe'
                                    "
                                >
                                    <td class="py-2 px-4 border-b">
                                        <!-- <input
                                            type="checkbox"
                                            class="themed-checkbox h-4 w-4 border border-gray-300 rounded-sm focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            :value="sale.Id"
                                            :id="`check-${sale.Id}`"
                                            v-model="checkedSubItems"
                                            @click="
                                                check(
                                                    sale.Id,
                                                    checkedSubItems,
                                                    true,
                                                )
                                            "
                                        /> -->
                                    </td>
                                    <td class="py-2 px-4 border-b">
                                        <fa-icon
                                            icon="basket-shopping"
                                            class="fa-sm mr-2"
                                        />
                                        <span>{{ sale.Designation }}</span>
                                    </td>
                                    <td class="py-2 px-4 border-b">
                                        <span>{{ sale.Quantity }}</span>
                                    </td>
                                    <td class="py-2 px-4 border-b">
                                        <span>{{
                                            sale.UnitPriceHT + ' €'
                                        }}</span>
                                    </td>
                                    <td class="py-2 px-4 border-b">
                                        <div>
                                            {{
                                                $t('vat') +
                                                ' ' +
                                                sale.VAT +
                                                ' %'
                                            }}
                                        </div>
                                        <div>
                                            {{
                                                $t('vat_amount') +
                                                ' ' +
                                                sale.TotalAmountVAT +
                                                ' €'
                                            }}
                                        </div>
                                    </td>
                                    <td class="py-2 px-4 border-b">
                                        <span>{{ sale.TotalHT + ' €' }}</span>
                                    </td>
                                    <td class="py-2 px-4 border-b">
                                        <span>{{ sale.TotalTTC + ' €' }}</span>
                                    </td>
                                    <td
                                        class="px-5 border-b border-zinc-200 text-xs"
                                        :class="'py-4'"
                                    >
                                        <!-- <dropdown-menu
                                            ref="listActionDD"
                                            class="inline-flex"
                                            :ddId="'dd-' + sale.Id"
                                            :ellipsisStyle="true"
                                            :items="
                                                itemActionsUpdated(
                                                    item.active &&
                                                        item.active === 1,
                                                    item,
                                                )
                                            "
                                            @picked="
                                                actionOnItem($event, sale.Id)
                                            "
                                        >
                                            <template #buttonContent>
                                                <fa-icon
                                                    :title="$t('action')"
                                                    icon="ellipsis-vertical"
                                                    class="fa-lg"
                                                />
                                            </template>
                                        </dropdown-menu> -->
                                    </td>
                                </tr>

                                <!-- </td>
                                </tr> -->
                            </template>
                        </template>
                    </tbody>
                </table>
                <div
                    v-if="count === 0"
                    class="flex items-center text-zinc-500 justify-center pb-6 mt-20"
                >
                    {{ $t('no_records_found') }}
                </div>
                <div
                    v-if="loading && count === null"
                    class="flex items-center text-zinc-500 justify-center pb-6 mt-20"
                >
                    <fa-icon class="fa-2xl" icon="spinner" spin-pulse></fa-icon>
                </div>
                <list-pagination
                    :class="
                        isB2C === true || $route.name !== 'orders'
                            ? 'w-full px-4'
                            : ' max-w-screen-2xl'
                    "
                    v-if="count > 0"
                    :perPage="perPage"
                    :page="page"
                    :totalPages="totalPages"
                    :count="count"
                    @back="back()"
                    @next="next()"
                    :openDirection="'top'"
                    @perPageChanged="$emit('perPageChanged', $event)"
                ></list-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import IconSelect from '@/components/elements/IconSelect.vue'
import IconSelectMultiple from '@/components/elements/IconSelectMultiple.vue'
import SearchInput from '@/components/elements/SearchInput.vue'
import SelectMany from '@/components/elements/SelectMany.vue'
import SelectOne from '@/components/elements/SelectOne.vue'
import Multiselect from '@vueform/multiselect'
import { mapState } from 'pinia'

import { useUserStore } from '@/stores/userStore'

import DropdownMenu from '@/components/atoms/DropdownMenu.vue'
import IconButton from '@/components/atoms/IconButton.vue'
import SortIcon from '@/components/atoms/SortIcon.vue'
import ListPagination from '@/components/elements/ListPagination.vue'
import copyToClipboard from '@/mixins/copyToClipboard.js'
import toNewTab from '@/mixins/openToNewTab.js'

import Popover from '@/components/elements/Popover.vue'
import converter from '@/mixins/converter'
import { useClientStore } from '@/stores/clientStore'

export default {
    name: 'FiltrableTable',
    components: {
        SearchInput,
        SelectOne,
        SortIcon,
        ListPagination,
        IconButton,
        DropdownMenu,
        Popover,
        IconSelect,
        IconSelectMultiple,
        Multiselect,
        SelectMany,
    },

    mixins: [copyToClipboard, converter, toNewTab],

    created() {
        window.scrollTo(0, 0)
        localStorage.removeItem('popoverOpen', false)
    },
    mounted() {
        const filtered = this.$cookies.get('filtered')
        const unpaidFiltered = this.$cookies.get('unpaidFiltered')

        if (unpaidFiltered) {
            if (unpaidFiltered.dateFilter) {
                // if (unpaidFiltered.dateFilter.length === 0) {
                //     if (
                //         unpaidFiltered.pickupTodayOnly === false &&
                //         unpaidFiltered.deliveryTodayOnly === false
                //     ) {
                //         this.resetDatePicker({
                //             value: true,
                //             name: 'DateBegin',
                //         })
                //         this.resetDatePicker({
                //             value: true,
                //             name: 'DateEnd',
                //         })
                //     } else if (unpaidFiltered.deliveryTodayOnly === false) {
                //         this.resetDatePicker({
                //             value: true,
                //             name: 'DateEnd',
                //         })
                //     } else {
                //         this.resetDatePicker({
                //             value: true,
                //             name: 'DateBegin',
                //         })
                //     }
                // } else if (unpaidFiltered.dateFilter.length === 1) {
                //     this.pickedDate[unpaidFiltered.dateFilter[0].dbField] =
                //         unpaidFiltered.dateFilter[0].value

                //     if (unpaidFiltered.dateFilter[0].dbField === 'DateBegin') {
                //         if (unpaidFiltered.deliveryTodayOnly === false) {
                //             this.resetDatePicker({
                //                 value: true,
                //                 name: 'DateEnd',
                //             })
                //         } else {
                //             this.pickedDate.DateEnd = new Date()
                //                 .toISOString()
                //                 .substr(0, 10)
                //         }
                //     } else if (
                //         unpaidFiltered.dateFilter[0].dbField === 'DateEnd'
                //     ) {
                //         if (unpaidFiltered.pickupTodayOnly === false) {
                //             this.resetDatePicker({
                //                 value: true,
                //                 name: 'DateBegin',
                //             })
                //         } else {
                //             this.pickedDate.DateBegin = new Date()
                //                 .toISOString()
                //                 .substr(0, 10)
                //         }
                //     }
                // } else {
                unpaidFiltered.dateFilter.forEach((dateFilter) => {
                    this.pickedDate[dateFilter.dbField] = dateFilter.value
                    this.$emit('dateFilterUpdate', {
                        dbField: dateFilter.dbField,
                        value: dateFilter.value,
                    })
                })
                // }
            }
        } else if (filtered) {
            if (filtered.dateFilter) {
                //    if (filtered.dateFilter.length === 1) {
                //         this.pickedDate[filtered.dateFilter[0].dbField] =
                //             filtered.dateFilter[0].value

                //         if (filtered.dateFilter[0].dbField === 'DateBegin') {
                //             if (filtered.deliveryTodayOnly === false) {
                //                 this.resetDatePicker({
                //                     value: true,
                //                     name: 'DateEnd',
                //                 })
                //             } else {
                //                 this.pickedDate.DateEnd = new Date()
                //                     .toISOString()
                //                     .substr(0, 10)
                //             }
                //         } else if (filtered.dateFilter[0].dbField === 'DateEnd') {
                //             if (filtered.pickupTodayOnly === false) {
                //                 this.resetDatePicker({
                //                     value: true,
                //                     name: 'DateBegin',
                //                 })
                //             } else {
                //                 this.pickedDate.DateBegin = new Date()
                //                     .toISOString()
                //                     .substr(0, 10)
                //             }
                //         }
                //     } else {
                filtered.dateFilter.forEach((dateFilter) => {
                    this.pickedDate[dateFilter.dbField] = dateFilter.value
                    this.$emit('dateFilterUpdate', {
                        dbField: dateFilter.dbField,
                        value: dateFilter.value,
                    })
                })

                // }
                // }
            }
        }
    },
    data() {
        return {
            expandedRows: [],
            disableOtherMs: false,
            pickedDate: {
                DateBegin: new Date().toISOString().substr(0, 10),
                DateEnd: new Date().toISOString().substr(0, 10),
                ModificationDate: null,
            },
            showPop: null,
            debounce: null,
            itemsPerPage: [5, 10, 25, 50, 100],
            dataToSearch: this.items,
            open: false,
            checkedItems: [],
            checkedSubItems: [],
            all: false,
            allSubItems: false,
        }
    },
    props: {
        filteredList: {
            type: Array,
            default: () => [],
        },
        tableWidth: {
            type: String,
            default: 'table-auto  w-full',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        smallMT: {
            type: Boolean,
            default: false,
        },
        hoverableItems: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: () => {},
        },
        columns: {
            type: Array,
            default: () => [],
        },
        count: {
            type: Number,
            default: 0,
        },
        storeToSort: {
            type: String,
            default: '',
        },
        sortableFields: {
            type: Array,
            default: () => [],
        },
        perPage: {
            type: Number,
            default: 10,
        },
        page: {
            type: Number,
            default: 1,
        },
        totalPages: {
            type: Number,
            default: 1,
        },
        sortDirection: {
            type: String,
            default: 'DESC',
        },
        searchQuery: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => [],
        },
        refresh: {
            type: Number,
            default: 0,
        },
        itemActions: {
            type: Array,
            default: () => [],
        },
        multiActions: {
            type: Array,
            default: () => [],
        },
        canAddNew: {
            type: Boolean,
            default: true,
        },
        canSelectItems: {
            type: Boolean,
            default: false,
        },
        resetCheckedItems: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        allSelected() {
            this.all = this.allSelected
        },

        $route(to, from) {
            const unpaidFiltered = this.$cookies.get('unpaidFiltered')
            if (to.name === 'unpaid_orders' && !unpaidFiltered) {
                this.resetDatePicker({
                    value: true,
                    name: 'DateBegin',
                })
                this.resetDatePicker({
                    value: true,
                    name: 'DateEnd',
                })
            }
        },
        items(old, newVal) {
            let checkedIt = 0
            if (this.items && old !== newVal) {
                this.items.forEach((item) => {
                    if (this.checkedItems.includes(item.id)) {
                        checkedIt += 1
                    }

                    if (this.storeToSort === 'containers') {
                        item.isDefault = false
                        if (this.isB2C === true) {
                            if (
                                this.currentClient &&
                                +this.currentClient.default_container ===
                                    item.id
                            ) {
                                item.isDefault = true
                            }
                        } else if (
                            this.isB2C === false &&
                            this.user.env.defaultContainer === +item.id
                        ) {
                            item.isDefault = true
                        }
                    }
                })

                this.all = checkedIt === this.perPage
            }
        },
        page() {
            window.scrollTo(0, 0)
            this.checkedSubItems = []
            this.allSubItems = false
        },
        perPage() {
            window.scrollTo(0, 0)
            this.checkedSubItems = []
            this.allSubItems = false
        },
        resetCheckedItems() {
            if (this.resetCheckedItems === true) {
                this.checkedItems = []
            }
        },
    },
    computed: {
        ...mapState(useUserStore, { user: 'current', isB2C: 'isB2C' }),
        ...mapState(useClientStore, { currentClient: 'current' }),

        allSelected() {
            let result = false
            if (this.count > 0 && this.items) {
                let checkedIt = 0
                this.items.forEach((item) => {
                    if (this.checkedItems.includes(item.id)) {
                        checkedIt += 1
                    }
                })

                if (checkedIt === this.perPage) {
                    result = true
                }
                // if (this.count === this.checkedItems.length) result = true
                // if (this.perPage === this.checkedItems.length) result = true
                if (this.items.length === this.checkedItems.length)
                    result = true
            }

            return result
        },

        multiActionsUpdated() {
            let actions = this.multiActions
            if (this.$route.name === 'invoicings') {
                // TODO A VERIFIER
                let showMultiActions = false
                if (this.items) {
                    this.items.forEach((item, index) => {
                        if (item.Orders) {
                            if (
                                (item.Orders[0].IdClient ===
                                    this.items[index + 1].Orders[0].IdClient &&
                                    this.items.length < index + 1) ||
                                this.items.length === 1
                            ) {
                                showMultiActions = true
                            } else {
                                showMultiActions = false
                            }
                        }
                    })
                    if (showMultiActions === true) {
                        actions = this.multiActions
                    }
                }
            }
            return actions
        },
    },
    methods: {
        subTableColumns(sale) {
            const columns = []
            if (sale) {
                columns.push(
                    {
                        name: 'name',
                        dbField: 'Designation',
                    },
                    {
                        name: 'quantity',
                        dbField: 'Quantity',
                    },
                    {
                        name: 'unit_ht',
                        dbField: 'UnitPriceHT',
                    },
                    {
                        name: 'vat',
                        dbField: 'VAT',
                    },

                    {
                        name: 'total_ht',
                        dbField: 'TotalHT',
                    },
                    {
                        name: 'total_ttc',
                        dbField: 'TotalTTC',
                    },
                )
            }
            return columns
        },
        toggleDetails(index) {
            if (this.checkedItems.length > 0) {
                this.checkedItems = []
                this.all = false
            }
            if (this.checkedSubItems.length > 0) {
                this.checkedSubItems = []
            }
            const rowIndex = this.expandedRows.indexOf(index)
            if (rowIndex > -1) {
                this.expandedRows.splice(rowIndex, 1)
            } else {
                this.expandedRows.push(index)
            }
        },
        goodGeoloc(steps) {
            let goodGeoloc = true
            steps.forEach((step) => {
                if (!step.Latitude && !step.Longitude) {
                    goodGeoloc = false
                }
            })

            return goodGeoloc
        },
        disableOthers(evt) {
            this.disableOtherMs = evt
        },
        showTooltip(elId, hovered) {
            const tooltip = document.getElementById(elId)
            if (hovered === false) {
                tooltip.classList.add('invisible')
            } else {
                tooltip.classList.remove('invisible')
            }
        },
        methodStyle(method) {
            return method === 'express'
                ? 'color: red;'
                : method === 'normal'
                  ? 'color: green;'
                  : 'color: orange;'
        },
        selectedDriver(currentDriver) {
            return { value: currentDriver ? +currentDriver.Id : 0 }
        },

        setPopoverData(order) {
            const popoverData = structuredClone(order.Orders[0])

            popoverData.packages = popoverData.OrderPackages

            if (this.isB2C === true) {
                popoverData.groupedPackages = order.groupedPackages
            }

            return popoverData
        },
        resetDatePicker(column) {
            if (column.value === true) {
                this.pickedDate[column.name] = ''
            } else if (column.value === false) {
                this.pickedDate[column.name] = new Date()
                    .toISOString()
                    .substr(0, 10)
            }
        },
        latOmetre(step) {
            const locale = 'en-En'
            let color = ''
            if (
                (step.ExecutedDateTime === '' ||
                    step.ExecutedDateTime === null) &&
                step.Status !== 'executed'
            ) {
                const currentTime = new Date().toLocaleTimeString(
                    locale,

                    {
                        hourCycle: 'h23',
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                )
                const currentDate = new Date().toLocaleDateString(
                    locale,

                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    },
                )
                const currentDateGetTime = new Date(currentDate).getTime()

                // si from
                let formattedTime = null
                let minusOne = null
                if (step.Moment === 'from') {
                    const fDate = new Date(
                        step.FromDateTime,
                    ).toLocaleDateString(
                        locale,

                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        },
                    )
                    const fDateGetTime = new Date(fDate).getTime()
                    if (
                        fDate < currentDate ||
                        fDateGetTime < currentDateGetTime
                    ) {
                        return 'red'
                    }
                    if (
                        fDate > currentDate ||
                        fDateGetTime > currentDateGetTime
                    ) {
                        return color
                    }

                    formattedTime = new Date(
                        step.FromDateTime,
                    ).toLocaleTimeString(
                        locale,

                        {
                            hourCycle: 'h23',
                            hour: '2-digit',
                            minute: '2-digit',
                        },
                    )

                    if (
                        currentDate ===
                            new Date(step.FromDateTime).toLocaleDateString(
                                locale,

                                {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                },
                            ) &&
                        (formattedTime === currentTime ||
                            formattedTime < currentTime)
                    ) {
                        color = 'green'
                    }
                }

                if (step.Moment === 'between' || step.Moment === 'before') {
                    const fDate = new Date(
                        step.BeforeDateTime,
                    ).toLocaleDateString(
                        locale,

                        {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        },
                    )
                    const fDateGetTime = new Date(fDate).getTime()

                    if (
                        fDate < currentDate ||
                        fDateGetTime < currentDateGetTime
                    ) {
                        return 'red'
                    }
                    if (
                        fDate > currentDate ||
                        fDateGetTime > currentDateGetTime
                    ) {
                        return ''
                    }

                    if (
                        (step.Moment === 'between' &&
                            new Date(step.FromDateTime).toLocaleTimeString(
                                locale,

                                {
                                    hourCycle: 'h23',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                },
                            ) === currentTime) ||
                        new Date(step.FromDateTime).toLocaleTimeString(
                            locale,

                            {
                                hourCycle: 'h23',
                                hour: '2-digit',
                                minute: '2-digit',
                            },
                        ) < currentTime
                    ) {
                        color = 'green'
                    }

                    formattedTime = new Date(
                        step.BeforeDateTime,
                    ).toLocaleTimeString(
                        locale,

                        {
                            hourCycle: 'h23',
                            hour: '2-digit',
                            minute: '2-digit',
                        },
                    )
                    minusOne = new Date(step.BeforeDateTime).setHours(
                        new Date(step.BeforeDateTime).getHours() - 1,
                    )
                    minusOne = new Date(minusOne).toLocaleTimeString(
                        locale,

                        {
                            hourCycle: 'h23',
                            hour: '2-digit',
                            minute: '2-digit',
                        },
                    )
                    if (
                        currentDate === fDate &&
                        (minusOne === currentTime || minusOne < currentTime)
                    ) {
                        color = 'orange'
                    }
                    if (
                        currentDate === fDate &&
                        (formattedTime === currentTime ||
                            formattedTime < currentTime)
                    ) {
                        color = 'red'
                    }
                }
            }

            return color
        },
        setMoment(step) {
            if (step.Moment === 'from') {
                return `${new Date(step.FromDateTime).toLocaleDateString(
                    this.$i18n.locale,

                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    },
                )} ${this.$t('from')} ${new Date(
                    step.FromDateTime,
                ).toLocaleTimeString(
                    this.$i18n.locale,

                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                )}`
            }
            if (step.Moment === 'between') {
                return `${new Date(step.FromDateTime).toLocaleDateString(
                    this.$i18n.locale,

                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    },
                )} ${this.$t('short_from')} ${new Date(
                    step.FromDateTime,
                ).toLocaleTimeString(
                    this.$i18n.locale,

                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                )} ${this.$t('short_before')} ${new Date(
                    step.BeforeDateTime,
                ).toLocaleTimeString(
                    this.$i18n.locale,

                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                )}`
            }
            if (step.Moment === 'before') {
                return `${new Date(step.BeforeDateTime).toLocaleDateString(
                    this.$i18n.locale,

                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    },
                )} ${this.$t('before')} ${new Date(
                    step.BeforeDateTime,
                ).toLocaleTimeString(
                    this.$i18n.locale,

                    {
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                )}`
            }
        },
        hasStepsErrors(steps) {
            let hasErrors = false
            steps.forEach((step) => {
                if (
                    step.Latitude === null ||
                    step.Longitude === null ||
                    step.Latitude === 0 ||
                    step.Longitude === 0 ||
                    step.Latitude === '' ||
                    step.Longitude === '' ||
                    step.Latitude === undefined ||
                    step.Longitude === undefined
                ) {
                    hasErrors = true
                }
            })

            return hasErrors
        },
        ocIsInvoiced(order) {
            let isInvoiced = false
            order.OrderSteps.forEach((step) => {
                if (step.IdInvoice !== null) {
                    isInvoiced = true
                }
            })
            return isInvoiced
        },
        orderStatus(order) {
            let status = {
                status: this.$t('in_progress'),
                color: 'theme-color',
                icon: 'person-biking',
                real: 'inprogress',
            }
            if (
                order.Orders[0].Status === 'finished' ||
                +order.Orders[0].ExecutedSteps ===
                    order.Orders[0].OrderSteps.length ||
                order.Orders[0].OrderSteps[
                    order.Orders[0].OrderSteps.length - 1
                ].Status === 'executed'
            ) {
                status = {
                    status: this.$t('last_step_finished'),
                    color: 'success-msg',
                    icon: 'flag-checkered',
                    real: 'executed',
                }
            }
            if (order.Orders[0].Status === 'new') {
                status = {
                    status: this.$t('delivery_new'),
                    color: 'theme-color',
                    icon: 'hourglass-half',
                    real: 'new',
                }
            }
            if (order.Orders[0].Status === 'waitingforpayment') {
                status = {
                    status: this.$t('waiting_for_payment'),
                    real: 'waitingforpayment',
                    title: this.$t('pay_pack_only'),
                    color: 'stripe-color cursor-pointer',
                    icon: ['fab', 'cc-stripe'],
                }
            }
            return status
        },
        setDeliveryPackages(order, packs) {
            const deliveryPackages = []
            const trackings = []
            const pictures = []
            const signatures = []

            const clone = [...order]
            const groupedPacks = []
            packs.forEach((pck) => {
                const temperatureInfos =
                    pck.TemperatureOption === 'warm'
                        ? {
                              icon: 'temperature-high',
                              color: 'color:firebrick;',
                              title: 'hot',
                          }
                        : pck.TemperatureOption === 'fresh'
                          ? {
                                icon: 'temperature-low',
                                color: 'color:dodgerblue;',

                                title: 'cool',
                            }
                          : pck.TemperatureOption === 'frozen'
                            ? {
                                  icon: 'temperature-arrow-down',
                                  color: 'color:cyan;',

                                  title: 'frozen',
                              }
                            : {
                                  icon: 'temperature-half',
                                  noTemp: true,
                              }
                const oPack = {
                    name: pck.Container
                        ? pck.Container.Name
                        : 'no_package_selected',
                    temperature: temperatureInfos,
                    quantity: pck.Quantity,
                }
                groupedPacks.push(oPack)
            })
            clone[0].OrderPackages.forEach((pack) => {
                if (pack.Trackings && pack.Trackings.length > 0) {
                    trackings.push(pack.Trackings)
                }
                if (pack.Signatures && pack.Signatures.length > 0) {
                    signatures.push(pack.Signatures)
                }
                if (pack.Pictures && pack.Pictures.length > 0) {
                    pictures.push(pack.Pictures)
                }
            })
            if (this.isB2C === true) {
                trackings.forEach((tracking, index) => {
                    trackings[index] = tracking.filter(
                        (track) => track.TypeTracking === 'client',
                    )
                    if (trackings[index].length === 0) {
                        trackings.splice(index, 1)
                    }
                })
            }
            deliveryPackages.push({
                groupedPacks,
                // name: clone[0].OrderPackages[0].Container
                //     ? clone[0].OrderPackages[0].Container.Name
                //     : 'no_package_selected',
                // temperature: temperatureInfos,
                trackings,
                signatures,
                pictures,
                distance: this.formatDistance(clone[0].OrderClient.Distance),
                duration: this.formatDuration(clone[0].OrderClient.Duration),
                method: clone[0].Method,
                client_comments: clone[0].CommentClient,
            })
            return deliveryPackages
        },

        isOdd(num) {
            return num % 2
        },
        showPopover(elId, close = false) {
            if (close === false) {
                this.showPop = elId
                localStorage.setItem('popoverOpen', true)
            } else {
                this.showPop = null
                localStorage.removeItem('popoverOpen')
            }
        },
        formattedDate(date, withTime = false) {
            let formatted = new Date(date).toLocaleDateString(this.$i18n.locale)

            if (withTime) {
                formatted = new Date(date).toLocaleTimeString(
                    this.$i18n.locale,
                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                )
            }

            return `${formatted}`
        },
        formattedPickUpStepDate(date) {
            const formatted = date.substring(0, date.indexOf(' '))
            return new Date(formatted).toLocaleDateString(this.$i18n.locale)
        },
        isNotFirstColumn(index) {
            if (index === 0) {
                return false
            }
            return document.getElementById(
                `${this.columns[index - 1].name}-${index - 1}`,
            )
        },

        itemActionsUpdated(itemIsActive, item) {
            const items = []
            // if (item.id) {
            this.itemActions.forEach((action) => {
                if (action.sub) {
                    if (
                        action.action === 'printAllLabels' &&
                        item.packages.length > 0
                    ) {
                        // item.packages.forEach((pack, index) => {
                        items.push({
                            name: `${this.$t('print_all_labels')}`,
                            sub: true,
                            packageId: item.Id,
                            reference: item.Reference,
                            action: 'printAllLabels',
                            icon: 'download',
                            hide: false,
                        })
                        // })
                    }
                    if (
                        action.action === 'orderTracking' &&
                        item.steps &&
                        item.steps.length > 0
                    ) {
                        items.push({
                            name: `${this.$t('tracking_all')}`,
                            action: 'orderTracking',
                            reference: item.packages[0].Reference,
                            sub: true,
                            icon: 'route',
                            hide: false,
                        })
                    }
                    // } else if (action.notAffectedOnly && item.steps) {
                    //     let isAffected = false
                    //     item.steps.forEach((step) => {
                    //         if (step.Status === 'affected') {
                    //             isAffected = true
                    //         }
                    //         if (isAffected === false && !items.includes(action)) {
                    //             items.push(action)
                    //         }
                    //     })
                } else if (action.action === 'deactivate') {
                    if (action.containers && action.containers === true) {
                        if (
                            (item.client === +this.user.idClient ||
                                this.isB2C === false) &&
                            !itemIsActive
                        ) {
                            items.push({
                                name: 'activate',
                                action: 'activate',
                                icon: 'check',
                            })
                        } else if (
                            item.client !== +this.user.idClient &&
                            this.isB2C === true
                        ) {
                            items.push({
                                name: 'container_not_editable',
                            })
                        } else {
                            items.push(action)
                        }
                    } else if (!itemIsActive) {
                        items.push({
                            name: 'activate',
                            action: 'activate',
                            icon: 'check',
                        })
                    } else {
                        items.push(action)
                    }
                } else if (action.archive === true) {
                    if (!itemIsActive) {
                        items.push({
                            name: 'unarchive',
                            action: 'unarchive',
                            icon: 'box-open',
                        })
                    } else {
                        items.push(action)
                    }
                } else if (action.action === 'generateSlips') {
                    if (item.slip !== null) {
                        items.push({
                            name: 'print_transport_slip',
                            action: 'printSlips',
                            icon: 'print',
                        })
                    } else {
                        items.push(action)
                    }
                } else if (
                    action.action === 'update' &&
                    action.containers &&
                    action.containers === true
                ) {
                    if (
                        item.client === +this.user.idClient ||
                        (this.isB2C === false && item.id)
                    ) {
                        items.push(action)
                    }
                } else if (
                    action.action === 'setAsDefault' &&
                    action.containers &&
                    action.containers === true
                ) {
                    if (itemIsActive) {
                        // item.client === +this.user.idClient &&
                        if (
                            this.isB2C === true &&
                            this.currentClient &&
                            item.id !== this.currentClient.default_container
                        ) {
                            items.push(action)
                        } else if (
                            this.isB2C === false &&
                            item.id !== +this.user.env.defaultContainer &&
                            item.client !== 0 &&
                            item.client !== null
                        ) {
                            items.push(action)
                        }
                    }
                } else if (action.action === 'setAsDone') {
                    if (item.status !== 'done') {
                        items.push(action)
                    }
                } else if (action.action === 'pay') {
                    let hasUnpaidOrders = false
                    if (
                        item.orderClient.PaymentType === 'web' &&
                        item.orderClient.PaymentStatus === 'waiting'
                    ) {
                        item.orderClient.forEach((orderClient) => {
                            orderClient.Orders.forEach((realOrder) => {
                                if (realOrder.Status === 'waitingforpayment') {
                                    hasUnpaidOrders = true
                                }
                            })
                        })
                        if (hasUnpaidOrders) {
                            items.push(action)
                        }
                    }
                } else if (action.action === 'printOrderFiles') {
                    if (item.delivery_order_files.length > 0) {
                        items.push(action)
                    }
                } else if (action.action === 'sendMail') {
                    if (item.contact) {
                        items.push(action)
                    } else {
                        items.push({
                            name: 'no_contact_for_order',
                            action: '',
                            icon: 'user-slash',
                        })
                    }
                } else if (
                    action.action === 'updatePlanned' ||
                    action.action === 'deletePlanned'
                ) {
                    if (!item.id) {
                        items.push(action)
                    }
                } else if (
                    this.$route.name === 'orders' &&
                    action.action === 'delete' &&
                    this.isB2C === false &&
                    (!action.hide || (action.hide && action.hide === false)) &&
                    item.id
                ) {
                    if (
                        item.currentStepsToDisplay &&
                        item.currentStepsToDisplay.length &&
                        item.currentStepsToDisplay[0].Status === 'new' &&
                        !item.isInvoiced
                    ) {
                        items.push(action)
                    }
                } else if (
                    (this.$route.name === 'orders' ||
                        this.$route.name === 'my_cart') &&
                    action.action === 'delete' &&
                    this.isB2C === true &&
                    (!action.hide || (action.hide && action.hide === false)) &&
                    item.id
                ) {
                    if (item.status === 'waiting') {
                        items.push(action)
                    }
                } else if (
                    this.$route.name === 'refunds' &&
                    (!action.hide || (action.hide && action.hide === false)) &&
                    item.id
                ) {
                    if (
                        +item.refundable_amount !== 0 &&
                        action.action === 'refund' &&
                        (item.status === 'paid' ||
                            item.status === 'partial_refund')
                    ) {
                        items.push(action)
                    }
                } else if (
                    (!action.hide || (action.hide && action.hide === false)) &&
                    item.id
                ) {
                    if (action.editNote) {
                        if (+item.active === 1) {
                            items.push(action)
                        } else {
                            items.push({
                                name: 'see_det',
                                action: 'update',
                                icon: 'magnifying-glass',
                                hide: false,
                            })
                        }
                    } else if (!item.isInvoiced) {
                        items.push(action)
                    } else {
                        items.push({
                            name: 'see_details',
                            action: 'update',
                            icon: 'magnifying-glass',
                            hide: false,
                        })
                    }
                }
            })
            // }
            return items
        },
        check(itemId, checkedItems = this.checkedItems, sub = false) {
            if (checkedItems.includes(itemId)) {
                const index = checkedItems.indexOf(itemId)
                checkedItems.splice(index, 1)
            } else {
                checkedItems.push(itemId)
            }
            if (this.canSelectItems === true) {
                this.$emit('selectedChanged', checkedItems)
            }
            if (sub === true) {
                this.$emit('selectedSubChanged', checkedItems)
            }
        },
        checkAllSubElements(item) {
            // crée la fonction qui check tous les elements d'un sous tableau sans les check si ils sont déjà check
            const subItems = item.sales[0].SaleDetails
            subItems.forEach((sale) => {
                if (!this.checkedSubItems.includes(sale.Id)) {
                    this.check(sale.Id, this.checkedSubItems, true)
                }
            })
        },
        checkAll() {
            if (this.all) {
                this.items.forEach((item) => {
                    if (
                        !this.checkedItems.includes(item.id) &&
                        this.$route.name === 'orders' &&
                        this.isB2C === true &&
                        (item.status === 'waitingforpayment' ||
                            item.status === 'waiting') &&
                        !item.isInvoiced
                    ) {
                        this.check(item.id)
                    }
                    if (
                        !this.checkedItems.includes(item.id) &&
                        (this.$route.name !== 'orders' || this.isB2C === true)
                    ) {
                        this.check(item.id)
                    }
                    if (
                        !this.checkedItems.includes(item.id) &&
                        this.$route.name === 'orders' &&
                        item.currentStepsToDisplay &&
                        item.currentStepsToDisplay.length &&
                        item.currentStepsToDisplay[0].Status === 'new' &&
                        !item.isInvoiced
                    ) {
                        this.check(item.id)
                    }
                })
            } else {
                this.items.forEach((item, index) => {
                    this.checkedItems.forEach((chk, i) => {
                        if (item.id === chk) {
                            this.checkedItems.splice(i, 1)
                        }
                    })
                })
            }
            if (this.canSelectItems === true) {
                this.$emit('selectedChanged', this.checkedItems)
            }
        },
        multiAction(action) {
            this.$emit(action.action, this.checkedItems)
            if (this.$route.name !== 'orders') {
                this.checkedItems = []
            }
        },
        back() {
            this.$emit('pageChanged', false)
        },
        next() {
            this.$emit('pageChanged', true)
        },
        isHtml(item) {
            if (item && item instanceof String) {
                return item.includes('<div>')
            }
            return false
        },
        actionOnItem(itemAction, itemId) {
            if (!itemAction.no_action || itemAction.no_action === false) {
                if (!itemAction.sub) {
                    this.$emit(itemAction.action, itemId)
                } else {
                    this.$emit(itemAction.action, itemAction)
                }
            }
        },
    },
}
</script>
<style lang="scss" scoped>
thead {
    position: sticky;
    z-index: 10;
    top: 3.6px; /* Don't forget this, required for the stickiness */
}
.fa-layers {
    height: 0.78em !important;
}
.stop-stretching {
    width: 1%;
    white-space: nowrap;
}
.stop-stretching-json {
    width: 1%;
    white-space: break-spaces;
    // word-break: break-all;
}
.order-details {
    padding: 10px;
    border: 1px solid #ddd;
}
.selectMS::v-deep(.multiselect) {
    font-size: x-small !important;

    min-height: unset !important;
    width: 6rem;
    .multiselect-placeholder {
        font-size: x-small !important;
        white-space: nowrap;
        overflow: hidden;
    }
    .multiselect-single-label-text {
        white-space: nowrap;
        text-overflow: unset;
        font-size: x-small !important;
        white-space: nowrap;
        padding-right: calc(2.3rem + var(--ms-px, 0.875rem));
    }
    .multiselect-dropdown .multiselect-options .multiselect-option {
        font-size: x-small !important;
    }
    .multiselect-tag {
        font-size: x-small !important;
    }
    .multiselect-wrapper .multiselect-caret {
        z-index: unset !important;
    }
}
</style>
